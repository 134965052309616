const BUGS_AND_ISSUES = 'https://airtable.com/shrWYhCnZj1u6FWOM';
const HELP_CENTER = 'https://sourcery.tawk.help/';
const INFO_EMAIL = 'info@gosourcery.com';
const NEW_FEATURES_AND_FEEDBACK = 'https://airtable.com/shrJihjdc5LK8od1d';
const REVIT_INSTALL = 'https://sourcery.tawk.help/article/sourcery-revit-sync';
const TRAINING_VIDEOS = 'https://www.youtube.com/channel/UCTxPyNyUMzqaOVrPs2iCz1g/videos';

export {
  BUGS_AND_ISSUES,
  HELP_CENTER,
  INFO_EMAIL,
  NEW_FEATURES_AND_FEEDBACK,
  REVIT_INSTALL,
  TRAINING_VIDEOS,
};
