<template>
  <div>
    <app-dialog
      v-model="dialogForShare"
      :value.sync="dialogForShare"
      width="637"
      persistent
      content-class="v-dialog__form"
      @keydown.esc="dialogForShare = false ">
      <v-card>
        <v-card-title>
          <div class="d-flex align-center">
            <div
              class="circle-icon-wrapper mr-5"
              :class="{ 'pointer': showEditOfSharing }"
              @click="showEditOfSharing ? showEditOfSharing = !showEditOfSharing : null">
              <v-icon
                v-if="!showEditOfSharing"
                size="20"
                color="#fff">
                mdi-account-plus-outline
              </v-icon>
              <v-icon
                v-else
                size="20"
                color="#fff">
                mdi-arrow-left
              </v-icon>
            </div>
            Share and add {{ showUpdatesToWorkspaceGuests ? "guests ": "members" }}
          </div>
          <span
            class="sourcery__icon-wrapper black"
            @click="closeButton">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>

        <div class="pa-4 pb-0">
          <AppAssistantInvite
            v-if="showInviteAssistantMessage"
            :project-id="id"
            small
            @assistantInvited="assistantInvitedCB" />
        </div>

        <v-form
          ref="shareForm"
          style="width: 100%"
          @submit.prevent="">
          <v-row
            class="mx-sm-6 mx-4 mt-8 mb-0">
            <v-col
              class="pa-0"
              :sm="showEditOfSharing ? '9' : '12'"
              cols="12">
              <ShareAutocomplete
                :initial-selected-user="initialSelectedUser"
                higlight-users
                shared-autocomplete
                :select-errors="selectErrors"
                check-email-errors
                :invite-to-text-type="PROJECT"
                :un-registered-users="unRegisteredUsers"
                @selected="selectedUsers" />
            </v-col>
            <v-col
              v-if="showEditOfSharing"
              class="pa-0"
              sm="3"
              cols="12">
              <v-autocomplete
                v-model="permission_type"
                autocomplete="off"
                :items="filteredPermissionTypes"
                solo
                flat
                filled
                dense
                hide-details
                hide-no-data
                class="v-autocomplete__share-groups v-autocomplete__share-groups--type">
                <template #item="{ item: { text, message }}">
                  <v-tooltip
                    right
                    max-width="200">
                    <template #activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        class="grow"
                        v-on="on">{{ text }}</span>
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <div>
              <p
                v-if="selectErrors"
                class="mt-2 mb-2 error--text">
                {{ selectErrors }}
                <b
                  @click="pushToPayment">
                  subscribe.
                </b>
              </p>
              <p
                v-if="showValidationEmailErrors && validationEmailErrors.length"
                class="mt-2 mb-2 error--text">
                {{ ErrorsText.WRONG_EMAILS_FOR_SHARING(validationEmailErrors) }}
              </p>
            </div>
            <div
              v-if="showEditOfSharing && !selectErrors"
              class="textarea-wrapper">
              <v-textarea
                v-model="shareMessage"
                :disabled="!canWorkWithModifyShare"
                class="c-textarea"
                placeholder="Message" />
            </div>
          </v-row>
        </v-form>
        <div
          v-if="!showEditOfSharing || selectErrors"
          style="min-height: 250px; max-height: 38vh; overflow: auto;"
          class="pt-1">
          <v-row
            v-for="(permission, i) in filteredPermissions"
            :key="i"
            class="ml-6 mr-10 mt-8">
            <AvatarImage
              :avatar="avatars[permission.userInfo.picture]"
              :avatar-key="permission.userInfo.picture"
              :color="permission.userInfo.id"
              :size="40" />
            <v-col
              class="pa-0 d-flex"
              sm="11"
              cols="12"
              :class="{'pl-3': $vuetify.breakpoint.mdAndUp }">
              <v-row class="ma-0 d-flex justify-space-between align-center">
                <UserInfo :user-info="permission.userInfo" />
                <v-menu
                  offset-y
                  nudge-bottom="10"
                  :disabled="disabledRoleShared(permission,canWorkWithModifyShare)"
                  content-class="edit-menu">
                  <template #activator="{ on: onMenu }">
                    <v-tooltip
                      content-class="btn-tooltip"
                      :disabled="canWorkWithModifyShare || isCurrentUser(permission.userInfo.id)"
                      bottom>
                      <template #activator="{ on: onTooltip }">
                        <div
                          class="d-flex"
                          v-on="permission.userInfo.name ? { ...onMenu, ...onTooltip } : null">
                          <p class="small-p title-in-modal mb-0 pointer text-capitalize">
                            {{ permission.type || 'Editor' }}
                          </p>
                          <span style="width: 20px; height: 20px">
                            <v-icon
                              v-if="permission.userInfo.name && !disabledRoleShared(permission,canWorkWithModifyShare)"
                              class="mb-1"
                              size="20px"
                              color="black">
                              mdi-menu-down
                            </v-icon>
                          </span>
                        </div>
                      </template>
                      <span>{{ NOT_PERMITTED_ACCESS }}</span>
                    </v-tooltip>
                  </template>
                  <v-card class="nav-card">
                    <template v-for="type in filteredPermissionTypes">
                      <v-tooltip
                        :key="type.value"
                        right
                        max-width="200">
                        <template v-slot:activator="{ on, attrs }">
                          <p
                            :class="{'highlighted' : type.value === permission.type }"
                            v-bind="attrs"
                            v-on="on"
                            @click="updateType(permission.userInfo.id, type.value)">
                            {{ type.text }}
                          </p>
                        </template>
                        <span>{{ type.message }}</span>
                      </v-tooltip>
                    </template>
                    <v-divider class="mb-1" />
                    <p
                      @click="deletePermission(permission.userInfo.id)">
                      Remove
                    </p>
                  </v-card>
                </v-menu>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-col
          v-else
          class="px-6 pt-2 pb-5 d-flex align-end"
          style="min-height: 100px">
          <v-chip
            :input-value="message"
            close-icon="mdi-close"
            color="#fff"
            outlined>
            <v-row class="ma-0">
              <ProjectIcon
                color="#2479ff"
                width="15"
                height="15"
                class="mr-2" />
              <p class="small-p mb-0">
                {{ detailProjectData ? detailProjectData.projectName : projectName }}
              </p>
            </v-row>
          </v-chip>
        </v-col>
        <v-card-actions
          v-if="!showEditOfSharing"
          class="sticky-bottom pa-8">
          <v-spacer />
          <p
            v-if="isChanged"
            class="mb-0 small-p title-in-modal mr-2 font-italic">
            Pending changes
          </p>
          <v-btn
            v-if="isChanged"
            color="blue"
            class="white--text"
            elevation="0"
            @click="checkInvitesAndSaveChanges">
            Save
          </v-btn>
          <v-btn
            v-else
            color="blue"
            class="white--text"
            elevation="0"
            @click="dialogForShare = false">
            Done
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else
          class="px-6 pb-5">
          <v-spacer />
          <v-btn
            outlined
            @click="showEditOfSharing = !showEditOfSharing">
            Cancel
          </v-btn>
          <v-btn
            color="blue"
            class="white--text"
            elevation="0"
            @click="checkInvitesAndSaveChanges">
            {{ hasCreatorAccess ? 'Send' : 'Request an invite' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </app-dialog>
    <WorkspaceCustomMembersDialog
      v-if="showWorkspaceAddMemberModal"
      :custom-members-dialog.sync="customMembersDialog"
      :invited-users.sync="invitedUsersForShareWorkspace" />
  </div>
</template>
<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import {
  GET_PERMISSION_TYPES,
  TYPE_MEMBER,
  TYPE_GUEST,
  NOT_PERMITTED_ACCESS, TYPE_VIEWER,
} from '@/constants/userPermissions';
import { required } from 'vuelidate/lib/validators';
import ProjectPermissionsApi from '@/services/graphql/projectPermissions';
import ShareAutocomplete from '@/components/App/AppShareAutocomplete';
import WorkspaceCustomMembersDialog from '@/components/Workspace/WorkspaceCustomMembersDialog';
import AppAssistantInvite from '@/components/App/AppAssistantInvite';
import { PROJECT } from '@/constants/cores';
import {
  isValidEmail,
  isOwner, hasCreatorAccess,
} from '@/utils';
import ErrorsText from '@/constants/errors';
import { CONFIRM_TO_PROCEED_IF_FREE_USERS } from '@/constants';
export default {
  name: 'AppShareModal',
  components: {
    AppAssistantInvite,
    ShareAutocomplete,
    WorkspaceCustomMembersDialog,
    UserInfo: () => import('./AppShareModalUserInfo'),
  },
  data: () => ({
    notify: false,
    message: '',
    permission_type: 'viewer',
    showEditOfSharing: false,
    dialogForShare: false,
    customMembersDialog: false,
    invitedUsersForShareWorkspace: [],
    isChanged: false,
    shareMessage: '',
    changedUsers: {
      updatePermission: [],
      deletePermission: [],
      createPermission: [],
      inviteUserToProject: [],
    },
    showValidationEmailErrors: false,
    initialSelectedUser: null,
    intersections: [],
    createComment: {
      message: '',
    },
    permissionTypes: [
      ...GET_PERMISSION_TYPES(['viewer', 'editor', 'creator']),
      {
        value: 'collaborator', text: 'Collaborator',
        message: 'Like Viewers, Collaborators can view and comment, but they also have the ability to export \
        documents. This role is designed for project design team members who need more access but not editing permissions.',
      },
    ],
    permissions: [],
    clonedPermissions: [],
    NOT_PERMITTED_ACCESS,
    PROJECT,
    ErrorsText,
  }),
  validations: {
    createComment: {
      message: {
        required,
      },
    },
  },
  computed: {
    ...mapState([
      'showShareModal',
      'avatars',
      'projectId',
      'role',
      'projectName',
      'userInfo',
    ]),
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapState('Projects', ['assistantStatus', 'isFetching']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('FeatureFlags', ['showSourceryAssistantInvite']),
    ...mapGetters({
      sortedProjectPermissions: 'ProjectDetailsTableSchedule/sortedProjectPermissions',
      userId: 'userId',
      isPersonalWorkspace: 'Workspace/isPersonalWorkspace',
      disabledRoleShared: 'UserRoles/disabledRoleShared',
      showUpdatesToWorkspaceGuests: 'FeatureFlags/showUpdatesToWorkspaceGuests',
      showWorkspaceAddMemberModal: 'FeatureFlags/showWorkspaceAddMemberModal',
      canShareProjectPermissionWithLimit: 'UserRoles/canShareProjectPermissionWithLimit',
      canShareProjectPermissionWithNoLimit: 'UserRoles/canShareProjectPermissionWithNoLimit',
    }),
    hasAnyFreeUsersInAutocomplete() {
      const { createPermission, inviteUserToProject } = this.changedUsers;
      return [createPermission, inviteUserToProject].flat().some(user => !user?.paymentStatus) && this.permission_type != TYPE_VIEWER;
    },
    validationEmailErrors() {
      const changedUsers = [this.changedUsers.inviteUserToProject];
      if (!changedUsers.length) {
        return [];
      }
      return changedUsers.flatMap(users => users.filter(user => !isValidEmail(user)));
    },
    hasCreatorAccess() {
      return hasCreatorAccess(this.role);
    },
    unRegisteredUsers() {
      return this.permissions.filter(permission => !permission.userInfo?.name);
    },
    filteredPermissions() {
      return this.permissions.filter(({ userInfo }) => userInfo.id && userInfo.email);
    },
    createCommentMessageErrors() {
      const errors = [];
      if (!this.$v.createComment.message.$dirty) return errors;
      if (!this.$v.createComment.message.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    filteredPermissionTypes() {
      return this.permissionTypes.filter(type => !(type.value === 'editor' && this.role === 'collaborator'));
    },
    id() {
      return this.$route.params.id || this.projectId;
    },
    canWorkWithShareLimit() {
      return this.canShareProjectPermissionWithLimit(this.role);
    },
    canWorkWithModifyShare() {
      return this.canShareProjectPermissionWithNoLimit(this.role) || this.canWorkWithShareLimit;
    },
    selectErrors() {
      const limitOfSharedUsers = 6;
      const limit = limitOfSharedUsers - (this.filteredPermissions.length + this.changedUsers.createPermission.length + this.changedUsers.inviteUserToProject.length);
      if (this.canWorkWithShareLimit && limit < 0) {
        return ErrorsText.USERS_LIMIT_ERROR(limit, limitOfSharedUsers);
      }
      return '';
    },
    showInviteAssistantMessage() {
      if (!this.showSourceryAssistantInvite) return false;
      if (this.isFetching.assistantStatus) return false;
      return this.assistantStatus && !this.assistantStatus.invited;
    },
  },
  watch: {
    permissions: {
      handler() {
        this.intersections = this.lodash.differenceWith(this.clonedPermissions,
          this.permissions, this.lodash.isEqual);
        if (this.intersections.length) {
          this.isChanged = true;
        } else {
          this.isChanged = false;
        }
      },
      deep: true,
    },
    showEditOfSharing() {
      if (!this.showEditOfSharing) {
        this.clearShareForm();
      }
    },
    showShareModal: {
      handler(val) {
        this.getProjectPermissionsAction();
        if (val) {
          this.fetchAssistantStatus();
        }
      },
    },
    dialogForShare() {
      if (!this.dialogForShare) {
        this.clearChangedUsers();
        this.clearShareForm();
        const { query } = this.$route;
        if (Object.keys(query).length) {
          this.$router.replace({
            query: {
              ...query,
              action: undefined,
              email: undefined,
              type: undefined,
            },
          }).catch(() => {
          });
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchAssistantAction: 'Projects/fetchAssistantStatus',
      getProjectPermissions: 'ProjectDetailsTableSchedule/getProjectPermissions',
      getAvatar: 'getAvatar',
      handleError: 'handleError',
      listUsersInWorkspace: 'Workspace/listUsersInWorkspace',
    }),
    ...mapMutations(['spinner', 'changeRole']),
    isCurrentUser(userId) {
      return this.userId === userId;
    },
    isOwner,
    pushToPayment() {
      this.$router.push({
        name: 'payment',
      });
    },
    async checkInvitesAndSaveChanges() {
      if (this.validationEmailErrors.length) {
        this.showValidationEmailErrors = true;
        return;
      }
      this.showValidationEmailErrors = false;
      const isPremissionTypeAllowsEdit = ['editor', 'creator'].includes(this.permission_type);
      if (this.hasAnyFreeUsersInAutocomplete && isPremissionTypeAllowsEdit) {
        let confirmToProceed = await this.$openConfirm({
          text: CONFIRM_TO_PROCEED_IF_FREE_USERS,
        });
        if (!confirmToProceed) {
          return;
        }
      }
      this.saveChanges();
      const promises = [];
      this.spinner(true);
      if (this.hasCreatorAccess) {
        promises.push(this.managePermission());
      } else {
        const {
          createPermission,
          inviteUserToProject,
          deletePermission,
          updatePermission,
        } = this.changedUsers;
        if (createPermission.length || inviteUserToProject.length) {
          promises.push(this.requestOfInvites());
        }
        if (deletePermission.length || updatePermission.length) {
          promises.push(this.managePermission());
        }
      }
      try {
        await Promise.all(promises);
      } finally {
        this.spinner(false);
      }
    },
    updateType(id, type) {
      this.permissions = this.permissions.map(permission => ({
        ...permission,
        type: permission.userInfo.id === id ? type : permission.type,
      }));
    },
    clearChangedUsers() {
      this.changedUsers.updatePermission = [];
      this.changedUsers.deletePermission = [];
    },
    saveChanges() {
      this.clearChangedUsers();
      this.intersections.forEach((item) => {
        const deletedIndex = this.lodash.findIndex(this.permissions,
          user => user.userInfo.id === item.userInfo.id);
        if (deletedIndex !== -1) {
          if (item.update) {
            this.changedUsers.updatePermission.push({
              userInfo: this.permissions[deletedIndex].userInfo,
              type: this.permission_type,
            });
          } else {
            this.changedUsers.updatePermission.push(this.permissions[deletedIndex]);
          }
        } else {
          this.changedUsers.deletePermission.push(item);
        }
      });
    },
    closeButton() {
      if (this.showEditOfSharing) {
        this.showEditOfSharing = !this.showEditOfSharing;
      } else {
        this.dialogForShare = false;
      }
    },
    selectedUsers(selected) {
      const createPermission = [];
      const inviteUserToProject = [];
      this.intersections = this.intersections.filter(item => !item.update);
      selected.forEach(((item) => {
        if (typeof item === 'object') {
          const findUserIndex = this.lodash.findIndex(this.permissions,
            user => user.userInfo.id === item.id);
          if (findUserIndex >= 0) {
            const findUser = this.lodash.findIndex(this.intersections,
              user => user.userInfo.id === item.id);
            if (findUser >= 0) {
              this.intersections[findUser] = {
                userInfo: item,
                update: true,
              };
            } else {
              this.intersections[this.intersections.length] = {
                userInfo: item,
                update: true,
              };
            }
          } else {
            createPermission.push(item);
          }
        } else {
          inviteUserToProject.push(item.trim());
        }
      }));
      this.changedUsers = {
        ...this.changedUsers,
        createPermission,
        inviteUserToProject,
        updatePermission: [],
      };
      this.showEditOfSharing = Boolean(selected.length);
    },
    handleRequestAnInvite() {
      const { action, email, type } = this.$route.query;
      if (action === 'invite' && email && type) {
        this.showEditOfSharing = true;
        if (this.permissionTypes.map(item => item.value).includes(type)) {
          this.permission_type = type;
        }
        this.initialSelectedUser = email;
      }
    },
    async getProjectPermissionsAction() {
      this.spinner(true);
      await this.getProjectPermissions();
      this.spinner(false);
      this.permissions = this.sortedProjectPermissions;
      this.clonedPermissions = this.lodash.cloneDeep(this.permissions);
      this.dialogForShare = true;
      this.permissions.forEach(async (item) => {
        if (item.userInfo.picture && !this.avatars[item.userInfo.picture]) {
          this.getAvatar({
            key: item.userInfo.picture,
            getAvatars: true,
            context: this,
          });
        }
      });
      this.$nextTick(() => {
        this.handleRequestAnInvite();
      });
    },
    async deletePermission(id) {
      this.permissions = this.permissions.filter(permission => permission.userInfo.id !== id);
    },
    async managePermission() {
      const jsonToSend = {
      };
      let changedUsers = {
      };
      if (this.hasCreatorAccess) {
        changedUsers = this.changedUsers;
      } else {
        const { updatePermission, deletePermission } = this.changedUsers;
        changedUsers = {
          updatePermission,
          deletePermission,
        };
      }
      Object.keys(changedUsers).forEach((item) => {
        changedUsers[item].forEach((user, index) => {
          if (item === 'inviteUserToProject') {
            jsonToSend[`${item}${index}`] = user;
          } else {
            jsonToSend[`${item}${index}`] = user.userInfo ? user.userInfo.id : user.id;
          }
          if (item !== 'deletePermission') {
            jsonToSend[`${item}Type${index}`] = user.type ? user.type : this.permission_type;
          }
          if (item == 'createPermission' || item == 'inviteUserToProject') {
            jsonToSend.emailMessage = this.shareMessage;
          }
          jsonToSend.workspaceId = this.activeWorkspaceId;
          jsonToSend.projectId = this.id;
        });
      });
      try {
        if (this.showWorkspaceAddMemberModal) {
          await this.listUsersInWorkspace({
            type: TYPE_MEMBER,
            useSpinner: false,
          });
        }
        const { data } = await ProjectPermissionsApi.managePermission(jsonToSend, changedUsers);
        if (this.showWorkspaceAddMemberModal) {
          this.setNewMembersForWorkspace(data);
        }
        this.dialogForShare = false;
      } catch (err) {
        this.handleError(err);
      }
    },
    async setNewMembersForWorkspace(data) {
      if (this.isPersonalWorkspace) {
        return;
      }
      await this.listUsersInWorkspace({
        type: TYPE_GUEST,
        useSpinner: false,
      });
      this.invitedUsersForShareWorkspace = Object.keys(data).reduce((result, option) => {
        if (option.includes('inviteUserToProject')) {
          return [...result, data[option].userInfo];
        }
        const isUserGuestInWorkspace = this.$store.getters['Workspace/isUserGuestInWorkspace'](data[option].userInfo.id);
        if (option.includes('createPermission') || option.includes('updatePermission') && isUserGuestInWorkspace) {
          return [...result, data[option].userInfo];
        }
        return result;
      }, []);
      if (this.invitedUsersForShareWorkspace.length) {
        this.customMembersDialog = true;
      }
    },
    async requestOfInvites() {
      const jsonToSend = {
      };
      const { createPermission, inviteUserToProject } = this.changedUsers;
      const usersToInvite = [...createPermission, ...inviteUserToProject];
      usersToInvite.forEach((user, index) => {
        jsonToSend[`usersToInvite${index}`] = user.id ? user.email : user;
        jsonToSend.type = this.permission_type;
        jsonToSend.projectId = this.id;
        jsonToSend.workspaceId = this.activeWorkspaceId;
      });
      try {
        await ProjectPermissionsApi.suggestColloborator(jsonToSend, {
          usersToInvite,
        });
        this.dialogForShare = false;
      } catch (err) {
        this.handleError(err);
      }
    },
    clearShareForm() {
      this.$refs.shareForm.reset();
      this.permission_type = 'viewer';
      this.shareMessage = '';
    },
    async assistantInvitedCB() {
      this.getProjectPermissionsAction();
      this.fetchAssistantStatus();
    },
    async fetchAssistantStatus() {
      return await this.fetchAssistantAction(this.id);
    },
  },
};
</script>
<style scoped lang="scss">
.circle-icon-wrapper {
  background: var(--v-blue-base);
}
.creator-dot-container {
  display: flex;
  align-items: center;
  &::after {
    content: '';
    height: 5px;
    width: 5px;
    border-radius: 50%;
    display: flex;
    margin-left: 10px;
    background: var(--v-blue-base);
  }
}
.sticky-bottom {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 8px !important;
}
::v-deep .v-label {
  color: var(--v-lightBlack-base) !important;
}
.v-dialog__form {
  overflow: hidden;
}
.textarea-wrapper {
  width: 100%;
}
.error--text {
  font-size: 12px;
  b {
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
