import Vue from 'vue';
import VueMoment from 'vue-moment';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueSafeHTML from 'vue-safe-html';
import MobileDetect from 'mobile-detect';
import {
  formatCurrency,
  formatProjectDates,
  openLinkInNewWindow,
} from '@/utils';
import Vuelidate from 'vuelidate';
import Clipboard from 'v-clipboard';
import VueLazyload from 'vue-lazyload';
import { VueMasonryPlugin } from 'vue-masonry';
import AppActionBtn from '@/components/App/AppBtn/AppActionBtn';
import AppBtn from '@/components/App/AppBtn';
import ProxyDialog from '@/components/App/AppProxyDialog/index';
import ConfirmDialog from '@/components/App/AppConfirmDialog';
import { createPromiseDialog } from 'vue-promise-dialogs';
import { getSentry } from '@/services/sentry';
import { BrowserTracing } from '@sentry/tracing';
import { Replay } from '@sentry/replay';
import { Fragment } from 'vue-frag';
import { datadogRum } from '@datadog/browser-rum';
import AWS from '@/services/awsSdk';
datadogRum.init({
  applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
  site: process.env.VUE_APP_DATADOG_SITE,
  service: process.env.VUE_APP_DATADOG_SERVICE,
  env: process.env.VUE_APP_DATADOG_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: +process.env.VUE_APP_DATADOG_SAMPLE_RATE,
  premiumSampleRate: +process.env.VUE_APP_DATADOG_PREMIUM_SAMPLE_RATE,
  trackInteractions: process.env.VUE_APP_DATADOG_TRACK_INTERACTIONS,
  defaultPrivacyLevel: process.env.VUE_APP_DATADOG_DEFAULT_PRIVACY_LEVEL,
});
datadogRum.startSessionReplayRecording();
if (process.env.NODE_ENV === 'production') {
  getSentry().then((Sentry) => {
    Sentry.init({
      Vue,
      environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      ignoreErrors: [
        /Non-Error exception captured/,
        /Non-Error promise rejection captured/,
        /CurrencyController/,
      ],
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        ...(process.env.VUE_APP_ADDING_SENTRY_SESSION_REPLAY === 'true' ? [new Replay()] : []),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  });
}
Vue.component('Fragment', Fragment);
Vue.use(VueSafeHTML);
Vue.use(VueLazyload, {
  lazyComponent: true,
  observer: true,
  attempt: 4,
  dispatchEvent: true,
  listenEvents: ['error'],
});
// name is optional
Vue.use(VueLodash, {
  name: 'custom', lodash,
});
Vue.use(Clipboard);
Vue.use(VueMoment);
Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.component('ProjectIcon', () => import('@/components/App/AppProjectIcon'));
Vue.component('AvatarImage', () => import('@/components/App/AppAvatarImage'));
Vue.component('AppBtn', AppBtn);
Vue.component('AppActionBtn', AppActionBtn);
Vue.component('AppIconBtn', () => import('@/components/App/AppBtn/AppIconBtn'));
Vue.component('AppDialog', ProxyDialog);
Vue.prototype.$formatCurrency = formatCurrency;
Vue.prototype.$formatProjectDates = formatProjectDates;
Vue.prototype.$openLinkInNewWindow = openLinkInNewWindow;
Vue.prototype.$openConfirm = createPromiseDialog(ConfirmDialog);
Vue.prototype.$mobileDetect = new MobileDetect(window.navigator.userAgent);
Vue.use(VueMasonryPlugin);
AWS.init();
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
