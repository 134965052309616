const USER_INFO = `
  {
    name
    email
    id
    picture
    paymentStatus
    projectTeamRole
  }
`;
const COMMENTS_USER_DATA = `
  name
  email
  picture
  location
  projectTeamRole
  id
`;
const USER_INFO_NO_BRACKETS = `
  name
  email
  id
  picture
  paymentStatus
  projectTeamRole
`;
const PROJECT_MEMBER_SUMMARY = `{
  users {
    name
    email
    id
    isVerified
    picture
    company
    job_title
    logo
  }
  count
}`;
const ICON_DETAIL = `
  {
    uuid
    code
  }
`;
const COLOR_DETAIL = `
  {
    uuid
    name
    hex
    rgb
    hsl
    cmyk
  }
`;
const THUMBNAIL = `
  full {
    height
    url
    width
    key
  }
  large {
    height
    url
    width
    key
  }
  small {
    height
    url
    width
    key
  }
  medium {
    height
    url
    width
    key
  }
`;
const FILE_STRUCTURE = `
  filename
  size
  id
  type
  url
  key
  thumbnails {
    ${THUMBNAIL}
}
`;
const REFERENCE = `
  {
    title
    manufacturer
    model
    id
    collectionID
    fields
  }
`;
const UNIVERSAL_ROW_QUICK_INDEX = `
fields {
  name
  string
  array {
    airtableImage {
      ${FILE_STRUCTURE}
    }
    attachment {
      ${FILE_STRUCTURE}
    }
  }
}
`;
const UNIVERSAL_ROWS_QUICK_INDEX = `
  data {
    ${UNIVERSAL_ROW_QUICK_INDEX}
  }
  nextToken
`;
const ROW_DATA = `
  fields {
    name
    string
    float
    number
    date
    formula
    url
    currency
    checkbox
    boolean
    integer
    unit_price
    reference ${REFERENCE}
    attachment {
      ${FILE_STRUCTURE}
    }
    array {
      url
      airtableImage {
        ${FILE_STRUCTURE}
      }
      boolean
      float
      integer
      string
      reference ${REFERENCE}
      attachment {
        ${FILE_STRUCTURE}
      }
    }
  }
`;
const ROW_COMMENT = `
  {
    id
    message
    commentType
    projectId
    documentId
    rowId
    PK
    thumbnailId
    privacy
    commentType
    mentioned ${USER_INFO}
    creator ${USER_INFO}
    createdDate
    dateModified
    read
    cellPointer
    cellCommentResolved
  }
`;
const ROW_COMMENT_NO_BRACKETS = `
  id
  message
  commentType
  projectId
  documentId
  rowId
  PK
  thumbnailId
  privacy
  commentType
  mentioned ${USER_INFO}
  creator ${USER_INFO}
  createdDate
  dateModified
  read
  cellPointer
  cellCommentResolved
`;
const ROWS_DATA = `
  data {
    ${ROW_DATA}
  }
  nextToken
`;
const SLANT = `
  {
    id
    PK
    SK
    unreadMessageIDs
    createdDate
    modifiedDate
    lastReadCommentDate
    projectId
    rowId
    commentsUnread
    commentsAmount
    offersUnread
  }
`;
const DROPDOWN_VALUES = `
  {
    column
    custom
    customValues
    fixedValues
    multipleSelects
  }
`;
const COMMENT_CELL_POINTER = `
  {
    tableId
    tableType
    cellPointers {
      name
      amount
      unresolved
    }
    username
  }
`;
const GRANT = `
  type
  name
  id
`;
const GRANTS = `
  direct {${GRANT}}
  libraries {${GRANT}}
`;
const MEMBER = `
  {
    type
    name
    email
    id
    isVerified
    picture
    company
    job_title
    logo
    projectTeamRole
    directGrant {${GRANT}}
    libraryGrants {${GRANT}}
  }
`;
const MEMBER_NO_BRACKET = `
  type
  name
  email
  id
  isVerified
  picture
  company
  job_title
  logo
  projectTeamRole
  directGrant {${GRANT}}
  libraryGrants {${GRANT}}
`;
const MEMBER_NO_GRANTS = `
  type
  name
  email
  id
  isVerified
  picture
  company
  job_title
  logo
  projectTeamRole
`;
const COLLECTION_MEMBER_PAGINATION = `
members {
  type
  name
  email
  id
  isVerified
  picture
  company
  job_title
  logo
  projectTeamRole
  directGrant {${GRANT}}
  libraryGrants {${GRANT}}
}
nextToken
`;
const COLLECTION_MEMBER_SUMMARY = `{
  members {
    type
    name
    email
    id
    isVerified
    picture
    company
    job_title
    logo
    projectTeamRole
  }
  count
}`;
const COLLECTION_PERMISSION = `
  {
    id
    lastModified
    createdDate
    name
    libraryId
    creator ${USER_INFO}
    members ${MEMBER}
    productsCount
    type
    logo
    publish
    workspaceId
    follow
    publishExpirationDate
    publishExpirationMessage
    createdProUser
    suggested
    isVerified
    verifiedPageId
    verifiedPageName
    pictures {
      attachment {
        ${FILE_STRUCTURE}
      }
    }
  }
`;
// Skip grants and use summary instead of fetching all members.
// Useful when fetching large numbers of collections (avoid scaling/performance issues).
const COLLECTION_PERMISSION_LEAN = `
  {
    id
    lastModified
    createdDate
    name
    libraryId
    creator ${USER_INFO}
    memberSummary ${COLLECTION_MEMBER_SUMMARY}
    productsCount
    type
    logo
    publish
    workspaceId
    follow
    publishExpirationDate
    publishExpirationMessage
    createdProUser
    suggested
    isVerified
    verifiedPageId
    verifiedPageName
    pictures {
      attachment {
        ${FILE_STRUCTURE}
      }
    }
  }
`;
const COLLECTION_PERMISSION_WITH_COMMENTS = `
  {
    id
    lastModified
    createdDate
    name
    libraryId
    creator ${USER_INFO}
    members ${MEMBER}
    productsCount
    type
    logo
    publish
    workspaceId
    follow
    publishExpirationDate
    publishExpirationMessage
    createdProUser
    suggested
    commentsUnread
    pictures {
      attachment {
        ${FILE_STRUCTURE}
      }
    }
  }
`;
const COLLECTION_PERMISSION_WITH_COMMENTS_LEAN = `
  {
    id
    lastModified
    createdDate
    name
    libraryId
    creator ${USER_INFO}
    memberSummary ${COLLECTION_MEMBER_SUMMARY}
    productsCount
    type
    logo
    publish
    workspaceId
    follow
    publishExpirationDate
    publishExpirationMessage
    createdProUser
    suggested
    commentsUnread
    pictures {
      attachment {
        ${FILE_STRUCTURE}
      }
    }
  }
`;
const COMMUNITY_COLLECTION = `
  {
    id
    lastModified
    createdDate
    name
    libraryId
    creator ${USER_INFO}
    productsCount
    type
    logo
    publish
    workspaceId
    follow
    publishExpirationDate
    publishExpirationMessage
    createdProUser
    suggested
    isVerified
    verifiedPageId
    verifiedPageName
    pictures {
      attachment {
        ${FILE_STRUCTURE}
      }
    }
  }
`;
const COLLECTION_PERMISSION_V2 = `
  {
    id
    lastModified
    name
    libraryId
    workspaceId
    creator ${USER_INFO}
    productsCount
    type
    logo
    publish
    follow
    publishExpirationDate
    publishExpirationMessage
    createdProUser
    suggested
    isVerified
    verifiedPageId
    verifiedPageName
    pictures {
      attachment {
        ${FILE_STRUCTURE}
      }
    }
  }
`;
const COLLECTION_PERMISSION_WITH_COMMENTS_NO_BRACKETS = `
  id
  lastModified
  name
  libraryId
  creator ${USER_INFO}
  members ${MEMBER}
  productsCount
  type
  logo
  publish
  follow
  publishExpirationDate
  publishExpirationMessage
  createdProUser
  suggested
  pictures {
    attachment {
      ${FILE_STRUCTURE}
    }
  }
`;
const COLLECTION_PERMISSION_NO_BRACKETS = `
  id
  lastModified
  name
  libraryId
  creator ${USER_INFO}
  members ${MEMBER}
  productsCount
  type
  logo
  publish
  follow
  publishExpirationDate
  publishExpirationMessage
  createdProUser
  suggested
  isVerified
  verifiedPageId
  verifiedPageName
  pictures {
    attachment {
      ${FILE_STRUCTURE}
    }
  }
`;
const COLLECTION_GROUPING = `
  {
    private ${COLLECTION_PERMISSION_WITH_COMMENTS}
    published ${COLLECTION_PERMISSION}
    shared ${COLLECTION_PERMISSION_WITH_COMMENTS}
    followed ${COLLECTION_PERMISSION}
    other ${COLLECTION_PERMISSION_WITH_COMMENTS}
  }
`;
const COLLECTION = `
  {
    id
    name
    description
    logo
    libraryId
    lastModified
    creator ${USER_INFO}
    status
    members ${MEMBER}
    productsCount
    createdDate
  }
`;
const COLLECTION_NO_BRACKETS = `
  id
  name
  description
  logo
  libraryId
  lastModified
  creator ${USER_INFO}
  status
  members ${MEMBER}
  productsCount
`;
const LIBRARY = `
 {
  creator {
    name
    email
    id
    picture
  }
  status
  createdAt
  collectionCount
  id
  name
  logo
  docType
  workspaceId
  description
  lastModified
  kind
  collections ${COLLECTION_GROUPING}
  workspaceId
  members ${MEMBER}
 }
`;
const LIBRARY_V1 = `
 {
  creator {
    name
    email
    id
    picture
  }
  status
  createdAt
  collectionCount
  id
  name
  logo
  docType
  workspaceId
  description
  lastModified
  kind
  collections ${COLLECTION}
  workspaceId
  members ${MEMBER}
 }
`;
const PROJECT_PERMISSION = `
  type
  status
  projectName
  projectId
  createdDate
  projectLastModified
  creator: projectCreator ${USER_INFO}
  isShared
  tags
  phase
  number
  commentsUnread
  sourceLibraryId
  sourceLibrary ${LIBRARY}
  members {
    name
    email
    id
    isVerified
    picture
    company
    job_title
    logo
  }
  projectColor ${COLOR_DETAIL}
  projectIcon ${ICON_DETAIL}
  hideResolvedComments
`;
const PROJECT_PERMISSION_USE_SUMMARY = `
  type
  status
  projectName
  projectId
  createdDate
  projectLastModified
  creator: projectCreator ${USER_INFO}
  isShared
  tags
  phase
  number
  commentsUnread
  sourceLibraryId
  sourceLibrary ${LIBRARY}
  memberSummary ${PROJECT_MEMBER_SUMMARY}
  projectColor ${COLOR_DETAIL}
  projectIcon ${ICON_DETAIL}
  hideResolvedComments
`;
const PROJECT_MEMBERS = `
  name
  email
  id
  isVerified
  picture
  company
  job_title
  logo
`;
const PROJECT_MEMBERS_PAGINATION = `
users {
  name
  email
  id
  isVerified
  picture
  company
  job_title
  logo
}
nextToken
`;
const PROJECT_DETAIL = `
  {
    phase
    number
    status
    projectName
    id
    PK
    SK
    creator ${USER_INFO}
    createdDate
    area
    areaUnit
    baseBudget
    location
    address {
      formatted_address
    }
    projectLastModified
    powerDensity
    sharedLink
    sourceLibraryId
    sourceLibrary ${LIBRARY}
    projectColor ${COLOR_DETAIL}
    projectIcon ${ICON_DETAIL}
    rowsCount
    workspace {
      logo
    }
  }
`;
const PROJECT_DETAIL_NO_BRACKETS = `
    phase
    number
    status
    projectName
    id
    creator ${USER_INFO}
    area
    tags
    areaUnit
    baseBudget
    location
    powerDensity
    sharedLink
    sourceLibraryId
    sourceLibrary ${LIBRARY}
    projectColor ${COLOR_DETAIL}
    projectIcon ${ICON_DETAIL}
`;
const PROJECT_PERMISSIONS = `
  projects {
    ${PROJECT_PERMISSION}
  }
  nextToken
`;
const PROJECT_PERMISSIONS_USE_SUMMARY = `
  projects {
    ${PROJECT_PERMISSION_USE_SUMMARY}
  }
  nextToken
`;
const PROJECT_PERMISSIONS_FOR_ADD_PRODUCT = `
    projects {
      projectName
      projectId
      type
      phase
      number
      sourceLibraryId
      sourceLibrary ${LIBRARY}
    }
  nextToken
`;
const TEMPORARY_CREDENTIALS = `
  email
  password
`;
const WORKSPACE_CREATOR = `creator {
  email
  company
  id
  isVerified
  job_title
  logo
  name
  picture
}`;
const VIEW_PARAMS = `params {
  name
  value
}`;
const WORKSPACE_TYPE = `
  creator {
    ${COMMENTS_USER_DATA}
  }
  status
  createdAt
  lastModified
  id
  name
  type
  premiumLicenses
  usedLicenses
  freeLicenses
  description
  logo
  published
  allowPages
`;
const WORKSPACE_FIELDS = `
  createdAt
  description
  id
  lastModified
  premiumLicenses
  usedLicenses
  freeLicenses
  logo
  name
  status
  type
  isVerified
  verified
  ${WORKSPACE_CREATOR}
`;
const WORKSPACE_PAGE = `
  id
  workspaceId
  logo
  name
  description
  status
  searchText
  isVerified
`;
const WORKSPACE_PAGE_CONNECTION = `
  data {
    ${WORKSPACE_PAGE}
  }
  amount
  nextToken
`;
const WORKSPACE_PERMISSION = `
  ${WORKSPACE_FIELDS}
  workspaceMembership
  workspaceType
  projectCount
  allowPages
  published
`;
//maybe we will need nextToken
const WORKSPACE_PERMISSION_CONNECTION = `
  data {
    ${WORKSPACE_PERMISSION}
  }
  nextToken
`;
const RESPONSE = `
  requestId
  message
  error
`;
const SEARCH_LIBRARY_MATCH = `
  {
    id
    collection
    docType
    collectionName
    collectionDescription
    manufacturer
    model
    productType
    shortDescription
    environment
    mounting
    published
    editable
    attachment {
      ${FILE_STRUCTURE}
    }
    createdProUser
    logo
  }
`;
const SEARCH_FILTERS = `
  manufacturer
  model
  productType
  environment
  location
  collectionName
`;
const SEARCH_LIBRARY_MATCH2 = `
  {
    id
    collection
    docType
    collectionName
    collectionDescription
    manufacturer
    model
    productInfo {
      status
      mode
      productCreator
    }
    productType
    shortDescription
    environment
    mounting
    published
    editable
    attachment {
      attachment {
      ${FILE_STRUCTURE}
      }
    }
    createdProUser
    logo
    isVerified
    childVerified
    verifiedPageId
    verifiedPageName
  }
`;
const SEARCH_CONNECTION_3 = `
  data ${SEARCH_LIBRARY_MATCH2}
  scrollID
  queryName
  username
  query
  requestId
  filters {
    ${SEARCH_FILTERS}
  }
`;
const SEARCH_LIBRARY_MATCH2_TO_VERIFIED_AREA = `
  {
    id
    collection
    docType
    collectionName
    collectionDescription
    manufacturer
    model
    productInfo {
      status
      mode
      productCreator
    }
    collectionInfo {
      creator {
        name
        picture
        id
      }
      follow
      publishExpirationDate
      productsCount
    }
    productType
    shortDescription
    environment
    mounting
    published
    editable
    pictures: attachment {
      attachment {
      ${FILE_STRUCTURE}
      }
    }
    createdProUser
    logo
  }
`;
const SEARCH_CONNECTION_TO_VERIFIED_AREA = `
  data ${SEARCH_LIBRARY_MATCH2_TO_VERIFIED_AREA}
  scrollID
  queryName
  username
  query
  requestId
  filters {
    ${SEARCH_FILTERS}
  }
`;
const PERMISSION_NOTIFICATION = `
  resource
  resourceId
  username
  userData {
    name
    email
    id
    isVerified
    picture
    company
    job_title
    logo
    paymentStatus
  }
  type
`;
const STRIPE_SUBSCRIPTION = `
  PK
  SK
  priceAmount
  priceCurrency
  priceId
  priceInterval
  priceNickname
  status
  subEnd
  subStart
  sub_id
`;
const DISABLED_WORKSPACES_IDS = `
  data {
    type
    workspaceId
  }
  amount
  nextToken
`;
const COLLECTION_SHARING_OPTIONS = `
  id
  type
  url
  accessToken
  enabled
`;
const AGGREGATION = `
  {
    id
    totalCost
    totalPower
    typeSumValues {
      name
      values
    }
    powerSumValues {
      name
      values
    }
    notes
  }
`;
const TOTAL_TAG = `
  PK
  SK
  TotalCost
  TotalPower
`;
const USER_DATA = `
  {
    name
    email
    id
    isVerified
    picture
    company
    job_title
    logo
    paymentStatus
    projectSortingMode
    projectSortingModeAscending
    collectionSortingMode
    collectionSortingModeAscending
  }
`;
const USER_DATA_NO_BRACKETS = `
    name
    email
    id
    isVerified
    picture
    company
    job_title
    logo
    paymentStatus
    projectSortingMode
    projectSortingModeAscending
    collectionSortingMode
    collectionSortingModeAscending
    location
    projectTeamRole
`;
const SECTION_INFO = `
  {
    expanded
    name
    id
    libraryId
  }
`;
const WORKSPACE_SETTINGS = `
    resourceType
    workspaceId
    userId
    libraryId
    collectionsSectionsOrder ${SECTION_INFO}
`;
const COLLECTION_FOLLOWED = `
    id
    productsIds
`;
const LIST_COLLECTIONS_FOLLOWERS_PRODUCTS = `
    data {
    ${COLLECTION_FOLLOWED}
  }
  amount
  nextToken
`;
const USER_PROFILE_FOR_PROJECT_DOCUMENT = `
    name
    logo
    id
    email
    color
    picture
`;
const PROJECT_DOCUMENT = `
    associatedTypes {
    id
    name
    status
    }
    description
    filename
    id
    key
    lastModified
    lastModifiedBy {
    ${USER_PROFILE_FOR_PROJECT_DOCUMENT}
    }
    libraryId
    size
    tag
    type
    thumbnails {
    ${THUMBNAIL}
    }
    uploadedAt
    uploadedBy {
    ${USER_PROFILE_FOR_PROJECT_DOCUMENT}
    }
`;
const PROJECT_DOCUMENTS_CONNECTION = `
    data {
    ${PROJECT_DOCUMENT}
  }
  nextToken
`;
const ASYNC_SEARCH_RESULT = `
  executionArn
`;
const COMMENT = `
    commentKind
    commentType
    commentId
    status
    resourceType
    resourceId
    message
    privacy
    creator {
      ${COMMENTS_USER_DATA}
    }
    mentioned {
      ${COMMENTS_USER_DATA}
    }
    createdDate
    dateModified
    rowId
    read
    cellPointer
    commentResolved
    privateRestrictedTypes
    mentionedIds
    attachments {
      filename
      size
      id
      type
      url
      thumbnails { ${THUMBNAIL} }
      key
      declaredAt
    }
`;
const COMMENTS_LIST = `
  comments {${COMMENT}}
  nextToken
`;
const SLANT_V2 =
  `
    id
    resourceType
    resourceId
    workspaceId
    user {
      ${COMMENTS_USER_DATA}
    }
    createdDate
    dateModified
    lastReadCommentDate
    rowId
    offersUnread
    commentsUnread
    commentsAmount
    unreadMessageIDs
  `;
const RESOURCE_PERMISSION = `
    resourceType
    resourceId
    workspaceId
    type
    status
    name
    id
    creator {
      ${COMMENTS_USER_DATA}
    }
    lastModified
    members {
      ${COMMENTS_USER_DATA}
    }
    hideResolvedComments
    username
    commentsUnread
    libraryId
`;
const PROJECT_SCHEDULE = `
    id
    default_fields
    read_only_fields
    schema {
      id
      shortPref
      longPref
      name
      maxLen
      type
      arrayType
      multiline
      extra
      formulaType
      hint
      hintWithSourceLibrary
      permissions {
       type
       edit
      }
    }`;
const RECENTLY_COLLECTION_PERMISSION = `
  data {
    private ${COLLECTION_PERMISSION_WITH_COMMENTS_LEAN}
    workspace ${COLLECTION_PERMISSION_WITH_COMMENTS_LEAN}
    published ${COLLECTION_PERMISSION_LEAN}
    shared ${COLLECTION_PERMISSION_WITH_COMMENTS_LEAN}
    followed ${COLLECTION_PERMISSION_LEAN}
    other ${COLLECTION_PERMISSION_WITH_COMMENTS_LEAN}
  }
  amount
  nextToken
`;
const ASSISTANT_INVITE_STATUS = `
  id
  invited
  timestamp
`;
const USER_PREFERENCE = `
{
  key
  value
}
`;
const USER_PREFERENCES = `
  preferences ${USER_PREFERENCE}
`;

export {
  STRIPE_SUBSCRIPTION,
  VIEW_PARAMS,
  COLLECTION_SHARING_OPTIONS,
  WORKSPACE_CREATOR,
  WORKSPACE_FIELDS,
  WORKSPACE_PERMISSION,
  WORKSPACE_PERMISSION_CONNECTION,
  USER_INFO,
  USER_INFO_NO_BRACKETS,
  ICON_DETAIL,
  COLOR_DETAIL,
  ROW_DATA,
  THUMBNAIL,
  FILE_STRUCTURE,
  ROW_COMMENT,
  ROW_COMMENT_NO_BRACKETS,
  ROWS_DATA,
  UNIVERSAL_ROWS_QUICK_INDEX,
  SLANT,
  DROPDOWN_VALUES,
  COMMENT_CELL_POINTER,
  COLLECTION_PERMISSION,
  COLLECTION_PERMISSION_NO_BRACKETS,
  COLLECTION_PERMISSION_LEAN,
  COLLECTION_PERMISSION_WITH_COMMENTS,
  COLLECTION_PERMISSION_WITH_COMMENTS_LEAN,
  COLLECTION_PERMISSION_WITH_COMMENTS_NO_BRACKETS,
  MEMBER,
  MEMBER_NO_BRACKET,
  MEMBER_NO_GRANTS,
  COLLECTION_MEMBER_PAGINATION,
  COLLECTION_MEMBER_SUMMARY,
  COLLECTION,
  LIBRARY,
  LIBRARY_V1,
  PROJECT_PERMISSIONS,
  PROJECT_PERMISSION,
  PROJECT_PERMISSION_USE_SUMMARY,
  PROJECT_PERMISSIONS_USE_SUMMARY,
  PROJECT_MEMBERS,
  PROJECT_MEMBERS_PAGINATION,
  PROJECT_DETAIL,
  PROJECT_DETAIL_NO_BRACKETS,
  TEMPORARY_CREDENTIALS,
  RESPONSE,
  SEARCH_LIBRARY_MATCH,
  PERMISSION_NOTIFICATION,
  COLLECTION_PERMISSION_V2,
  DISABLED_WORKSPACES_IDS,
  AGGREGATION,
  TOTAL_TAG,
  USER_DATA,
  USER_DATA_NO_BRACKETS,
  LIST_COLLECTIONS_FOLLOWERS_PRODUCTS,
  WORKSPACE_SETTINGS,
  COMMUNITY_COLLECTION,
  COMMENTS_LIST,
  COMMENT,
  ASYNC_SEARCH_RESULT,
  PROJECT_DOCUMENTS_CONNECTION,
  PROJECT_DOCUMENT,
  COMMENTS_USER_DATA,
  SLANT_V2,
  RESOURCE_PERMISSION,
  GRANTS,
  GRANT,
  COLLECTION_NO_BRACKETS,
  PROJECT_PERMISSIONS_FOR_ADD_PRODUCT,
  PROJECT_SCHEDULE,
  WORKSPACE_TYPE,
  WORKSPACE_PAGE,
  WORKSPACE_PAGE_CONNECTION,
  RECENTLY_COLLECTION_PERMISSION,
  SEARCH_LIBRARY_MATCH2,
  SEARCH_CONNECTION_TO_VERIFIED_AREA,
  SEARCH_FILTERS,
  SEARCH_CONNECTION_3,
  ASSISTANT_INVITE_STATUS,
  USER_PREFERENCE,
  USER_PREFERENCES,
};
