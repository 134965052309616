import { getAreaUnitTitleByValue } from '@/utils';
export const getters = {
  statusOfProject(state) {
    return state.detailProjectData?.status;
  },
  areaUnitTitle(state) {
    const { areaUnit } = state.detailProjectData || {
    };
    return getAreaUnitTitleByValue(areaUnit);
  },
  projectColor(state) {
    return state.projectColor?.hex;
  },
  projectIconId(state) {
    return state.projectIcon?.code;
  },
  projectDetailsCreatorId (state) {
    return state.detailProjectData?.creator?.id;
  },
  isSharedLink(state) {
    return state.detailProjectData?.sharedLink;
  },
  rowsCount(state) {
    return state.detailProjectData?.rowsCount;
  },
  workspaceLogoKey(state) {
    return state.detailProjectData?.workspace?.logo;
  },
  workspaceLogoToProject(state) {
    return state.workspaceLogoToProject;
  },
  getProjectTitle: () => data => {
    const { projectName = '', number = '', phase = '' } = data ?? {
    };
    if (phase && number) {
      return `${number} - ${projectName} - ${phase}`;
    }
    if (phase && !number) {
      return `${projectName} - ${phase}`;
    }
    if (!phase && number) {
      return `${number} - ${projectName}`;
    }
    return projectName;
  },
  getDetailProjectTitle(state, getters) {
    return getters.getProjectTitle(state.detailProjectData);
  },
};
