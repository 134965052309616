import Subscriptions from '@/services/graphql/subscriptions';
import {
  PROJECT_PERMISSION, COLLECTION_PERMISSION, WORKSPACE_PERMISSION, NOTIFY_PERMISSION,
} from '@/constants/userPermissions';
import { IS_STORE_ROOT } from '@/constants';
import API from '@/services/graphql/index';
import { COMMENTS_RESOURCES } from '@/constants/comments';
export const actions = {
  async subscribePermissionProjectChange({ dispatch, rootGetters }) {
    try {
      const { userId: username } = rootGetters;
      const subscription = await Subscriptions.subscribePermissionProjectChange({
        username,
      });
      subscription.subscribe({
        next: ({ value }) => {
          console.log(PROJECT_PERMISSION, value);
          // window.location.reload();
        },
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: PROJECT_PERMISSION,
      }, IS_STORE_ROOT);
    } catch (err) {
      console.log(PROJECT_PERMISSION, err);
    }
  },
  async subscribeCollectionPermissionChange({ dispatch, rootGetters }) {
    try {
      const { userId: username } = rootGetters;
      const subscription = await Subscriptions.subscribeCollectionPermissionChange({
        username,
      }).subscribe({
        next: ({ value }) => {
          console.log(COLLECTION_PERMISSION, value);
          // window.location.reload();
          dispatch('ManageSubscriptions/setSubscription', {
            subscription,
            title: COLLECTION_PERMISSION,
          }, IS_STORE_ROOT);
        },
      });
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async subscribeWorkspacePermissionChange({ dispatch, rootGetters, commit, rootState }) {
    try {
      const { userId: username } = rootGetters;
      const subscription = await Subscriptions.subscribePermissionWorkspaceChange({
        username,
      }).subscribe({
        next: ({ value }) => {
          const updatedWorkspace = value.data.response;
          const updatedWorkspacesList = rootState.Workspace.workspacesList.map(w => w.id == updatedWorkspace.id ? updatedWorkspace : w);
          commit('Workspace/setWorkspacesList', updatedWorkspacesList, IS_STORE_ROOT);
        },
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: WORKSPACE_PERMISSION,
      }, IS_STORE_ROOT);
    } catch (err) {
      console.log(WORKSPACE_PERMISSION, err);
    }
  },
  setCollectionPermission({ dispatch, commit, rootState }, data) {
    const {
      value = {
      }, title = '', resourceId = '', subscription,
    } = data || {
    };
    const collectionsList = rootState.Collections.privateCollectionsList.map(col => {
      const { id = '' } = col || {
      };
      if (id === resourceId) {
        col.members?.push({
          ...value.data.response.userData,
          type: value.data.response.type,
        });
      }
      return col;
    });
    commit('Collections/setPrivateCollectionsList', collectionsList, IS_STORE_ROOT);
    dispatch('ManageSubscriptions/setSubscription', {
      subscription,
      title,
    }, IS_STORE_ROOT);
  },
  async subscribeNotifyCreatePermission({ dispatch }, { resource, resourceId }) {
    const title = `${NOTIFY_PERMISSION}_${resource}_${resourceId}`;
    try {
      const subscription = await Subscriptions.subscribeNotifyCreatePermission({
        resource,
        resourceId,
      }).subscribe({
        next({ value }) {
          dispatch('setCollectionPermission', {
            value, title, resourceId, subscription,
          });
        },
      });
      setTimeout(() => {
        dispatch('ManageSubscriptions/removeSubscriptions', [title], IS_STORE_ROOT);
      }, 25000);
    } catch (err) {
      console.log(title, err);
    }
  },
  async getResourcePermission({ commit, rootState, dispatch }, { resourceType, resourceId }) {
    try {
      const workspaceId = rootState.Workspace.activeWorkspaceId;
      const { data } = await API.getResourcePermission({
        workspaceId,
        resourceType,
        resourceId,
      });
      const currentResource = COMMENTS_RESOURCES[resourceType];
      commit('Comments/'.concat(currentResource.setResolvedToggleMutation), data.response.hideResolvedComments, IS_STORE_ROOT);
      //@todo add state for resource permission
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
};
