import { API } from 'aws-amplify';
export default {
  getProjectTags() {
    const query = `
      query getProjectTags {
        response: getProjectTags
      }
    `;
    return API.graphql({
      query,
    });
  },
  manageTags(variables, criteria) {
    const query = `
      mutation ${criteria} (
        $tagName: String!
        $projectId: String!
        $workspaceId: String 
      ) {
        response: ${criteria}(
          tagName: $tagName
          projectId: $projectId
          workspaceId: $workspaceId
        ) {
            tags
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
};