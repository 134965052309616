import { API } from 'aws-amplify';
import {
  ROW_COMMENT,
  SLANT,
  COMMENT_CELL_POINTER,
  ROW_COMMENT_NO_BRACKETS,
  PROJECT_PERMISSION, COMMENTS_LIST, COMMENT, SLANT_V2, RESOURCE_PERMISSION,
} from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
const ROW_COMMENTS = `
  comments
    ${ROW_COMMENT}
  nextToken
`;
export default {
  getRowComments(data) {
    const variables = {
      ...data,
      limit: data.limit ? data.limit : 3,
    };
    const query = `
      query getRowComments (
        $projectId: String!
        $rowId: String!
        $tableId: String!
        $tableType: TableType!
        $nextToken: String
        $limit: Int
        $scanIndexForward: Boolean
        $versionId: String
        $createdDate: String
        $workspaceId: String
        ) {
        response: getRowComments(
          projectId: $projectId
          rowId: $rowId
          tableId: $tableId
          tableType: $tableType
          nextToken: $nextToken
          limit: $limit
          versionId: $versionId
          scanIndexForward: $scanIndexForward
          createdDate: $createdDate
          workspaceId: $workspaceId
          ){
             ${ROW_COMMENTS}
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  createRowComment(variables) {
    return new requestBuilder({
      requestName: 'createRowComment',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        projectId: 'String!',
        message: 'String!',
        rowId: 'String!',
        tableId: 'String!',
        tableType: 'TableType!',
        cellPointer: 'String',
        commentType: 'CommentType!',
        privacy: 'Privacy!',
        mentioned: '[String]',
        workspaceId: 'String',
      },
      response: ROW_COMMENT_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  deleteRowComment(variables) {
    const query = `
      mutation deleteRowComment (
        $projectId: String!
        $rowId: String!
        $commentId: String!
        $tableId: String!
        $tableType: TableType!
        $privacy: Privacy!
        $workspaceId: String
      ) {
        response: deleteRowComment(
            projectId: $projectId
            rowId: $rowId
            commentId: $commentId
            tableId: $tableId
            tableType: $tableType
            privacy: $privacy
            workspaceId: $workspaceId
          ) {
            id
            projectId
            rowId
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  createRowCommentAttachment(variables) {
    const query = `
      mutation createRowCommentAttachment (
        $projectId: String!
        $documentId: String!
        $rowId: String!
        $tableId: String!
        $tableType: TableType!
        $commentId: String!
        $privacy: Privacy!
        $cellPointer: String
        $workspaceId: String
      ) {
        response: createRowCommentAttachment(
            projectId: $projectId
            documentId: $documentId
            rowId: $rowId
            tableId: $tableId
            tableType: $tableType
            commentId: $commentId
            privacy: $privacy
            cellPointer: $cellPointer
            workspaceId: $workspaceId
          ) ${ROW_COMMENT}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  editRowComment(variables) {
    const query = `
      mutation editRowComment (
        $projectId: String!
        $rowId: String!
        $commentId: String!
        $tableId: String!
        $tableType: TableType!
        $attributes: RowCommentInput
        $cellCommentResolved: Boolean
        $workspaceId: String
      ) {
        response: editRowComment(
            projectId: $projectId
            rowId: $rowId
            tableId: $tableId
            commentId: $commentId
            tableType: $tableType
            attributes: $attributes
            cellCommentResolved: $cellCommentResolved
            workspaceId: $workspaceId
          ) ${ROW_COMMENT}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getProjectComments(data) {
    const variables = {
      ...data,
      limit: data.limit ? data.limit : 3,
    };
    const query = `
      query getProjectComments (
        $projectId: String!
        $tableId: String!
        $tableType: TableType!
        $limit: Int
        $scanIndexForward: Boolean
        $nextToken: String
        $workspaceId: String
        ) {
        response: getProjectComments(
          projectId: $projectId
          tableId: $tableId
          tableType: $tableType
          nextToken: $nextToken
          limit: $limit
          scanIndexForward: $scanIndexForward
          workspaceId: $workspaceId
          ) {
            ${ROW_COMMENTS}
            commentsAmount
         }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  createProjectComment(variables) {
    const query = `
      mutation createProjectComment (
        $projectId: String!
        $message: String!
        $tableId: String!
        $tableType: TableType!
        $commentType: CommentType!
        $privacy: Privacy!
        $mentioned: [String]
        $workspaceId: String
      ) {
        response: createProjectComment(
            projectId: $projectId
            message: $message
            tableId: $tableId
            tableType: $tableType
            mentioned: $mentioned
            commentType: $commentType
            privacy: $privacy
            workspaceId: $workspaceId
          ) ${ROW_COMMENT}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  createProjectCommentAttachment(variables) {
    const query = `
      mutation createProjectCommentAttachment (
        $projectId: String!
        $documentId: String!
        $tableId: String!
        $tableType: TableType!
        $commentId: String!
        $privacy: Privacy!
        $workspaceId: String
      ) {
        response: createProjectCommentAttachment(
            projectId: $projectId
            documentId: $documentId
            tableId: $tableId
            tableType: $tableType
            commentId: $commentId
            privacy: $privacy
            workspaceId: $workspaceId
          ) ${ROW_COMMENT}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  deleteProjectComment(variables) {
    const query = `
      mutation deleteProjectComment (
        $projectId: String!
        $commentId: String!
        $tableId: String!
        $tableType: TableType!
        $workspaceId: String
      ) {
        response: deleteProjectComment(
            projectId: $projectId
            commentId: $commentId
            tableId: $tableId
            tableType: $tableType
            workspaceId: $workspaceId
          ) ${ROW_COMMENT}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  editProjectComment(variables) {
    const query = `
      mutation editProjectComment (
        $projectId: String!
        $commentId: String!
        $tableId: String!
        $tableType: TableType!
        $attributes: RowCommentInput
        $workspaceId: String
      ) {
        response: editProjectComment(
            projectId: $projectId
            tableId: $tableId
            commentId: $commentId
            tableType: $tableType
            attributes: $attributes
            workspaceId: $workspaceId
          ) ${ROW_COMMENT}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getSlants(variables) {
    const query = `
      query getSlants (
        $projectId: String!
        $tableId: String!
        $versionId: String
        $tableType: TableType!
        $rowsIds: [String!]
        $workspaceId: String
        ) {
        response: getSlants(
          versionId: $versionId
          projectId: $projectId
          tableId: $tableId
          tableType: $tableType
          rowsIds: $rowsIds
          workspaceId: $workspaceId
        ) ${SLANT}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  setSlant(variables) {
    const query = `
      mutation setSlant (
        $projectId: String!
        $tableId: String!
        $tableType: TableType!
        $messageIDs: [String!]!
        $rowId: String
        $offersIDs: [String!]
        $workspaceId: String
        ) {
        response: setSlant(
          workspaceId: $workspaceId
          projectId: $projectId
          tableId: $tableId
          tableType: $tableType
          messageIDs: $messageIDs
          rowId: $rowId
          offersIDs: $offersIDs
        ) ${SLANT}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getCommentCellPointers(variables) {
    const query = `
      query getCommentCellPointers (
        $rowId: String!
        $tableId: String!
        $tableType: TableType!
        $projectId: String!
        $workspaceId: String!
        ) {
        response: getCommentCellPointers(
          rowId: $rowId
          tableId: $tableId
          tableType: $tableType
          projectId: $projectId
          workspaceId: $workspaceId
          ) ${COMMENT_CELL_POINTER}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  listCellCommentByPointer(variables) {
    const query = `
      query listCellCommentByPointer (
        $rowId: String!
        $tableId: String!
        $tableType: TableType!
        $projectId: String!
        $cellPointer: String!
        $limit: Int
        $nextToken: String
        $scanIndexForward: Boolean
        $workspaceId: String
        ) {
        response: listCellCommentByPointer(
          rowId: $rowId
          tableId: $tableId
          tableType: $tableType
          cellPointer: $cellPointer
          limit: $limit
          nextToken: $nextToken
          projectId: $projectId
          scanIndexForward: $scanIndexForward
          workspaceId: $workspaceId
          ) {
            ${ROW_COMMENTS}
         }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  readAllComments(variables) {
    const query = new requestBuilder({
      requestName: 'readAllComments',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        projectId: 'String!',
        workspaceId: 'String!',
      },
      response: PROJECT_PERMISSION,
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  toggleResolvedComments(variables) {
    return new requestBuilder({
      requestName: 'toggleResolvedComments',
      requestType: 'mutation',
      requestVariables: {
        hideResolved: 'Boolean!',
        projectId: 'String!',
        workspaceId: 'String!',
      },
      response: PROJECT_PERMISSION,
      variables,
    }).processRequest();
  },
  listComments(variables) {
    return new requestBuilder({
      requestName: 'listComments',
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
        commentKind: 'CommentKind!',
        limit: 'Int',
        scanIndexForward: 'Boolean',
        nextToken: 'String',
      },
      variables,
      response: COMMENTS_LIST,
    }).processRequest();
  },
  createComment(variables) {
    return new requestBuilder({
      requestName: 'createComment',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
        commentKind: 'CommentKind!',
        commentType: 'CommentType!',
        privacy: 'CommentPrivacy!',
        message: 'String',
        mentioned: '[String]',
        attachments: '[String]',
      },
      response: COMMENT,
      variables,
    }).processRequest();
  },
  editComment(variables) {
    return new requestBuilder({
      requestName: 'editComment',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
        commentKind: 'CommentKind!',
        commentId: 'String!',
        attributes: 'EditCommentInput!',
      },
      response: COMMENT,
      variables,
    }).processRequest();
  },
  deleteComment(variables) {
    return new requestBuilder({
      requestName: 'deleteComment',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
        commentKind: 'CommentKind!',
        commentId: 'String!',
      },
      response: COMMENT,
      variables,
    }).processRequest();
  },
  toggleResolvedResourceComments(variables) {
    return new requestBuilder(
      {
        requestName: 'toggleResolvedResourceComments',
        requestVariables: {
          workspaceId: 'String!',
          resourceType: 'CommentResourceType!',
          resourceId: 'String!',
          hideResolved: 'Boolean!',
        },
        requestType: GRAPHQL_TYPES.MUTATION,
        response: RESOURCE_PERMISSION,
        variables,
      }).processRequest();
  },
  listSlants(variables) {
    return new requestBuilder({
      requestName: 'listSlants',
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
        rowsIds: '[String!]!',
      },
      response: SLANT_V2,
      variables,
    }).processRequest();
  },
  setUserSlant(variables) {
    return new requestBuilder({
      requestName: 'setUserSlant',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
        readMessageIDs: '[String!]',
      },
      response: SLANT_V2,
      variables,
    }).processRequest();
  },
  readAllResourceComments(variables) {
    return new requestBuilder({
      requestName: 'readAllResourceComments',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
      },
      variables,
      response: RESOURCE_PERMISSION,
    }).processRequest();
  },
};
