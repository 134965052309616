import {
  PROJECT_BUDGET_VIEW_TYPE,
  BUDGET_VIEW_KEYWORDS,
  SPECIFIER_VIEW,
  SPECIFIER_VIEW_TYPE,
  SUSTAINABILITY_VIEW,
  POWER_DENSITY_VIEW,
  SUSTAINABILITY_VIEW_TYPE,
} from '@/constants/scheduleViews';

/**
 * Section Required for - https://app.asana.com/0/1205146388025681/1208067130691041/f
 *
 * The idea is that because view types were always 'custom' or null (if it's a default view),
 * we now use the type to also store the type of view for:
 * - Specifier View
 * - Budget View
 *
 * So we can now identify a view even after rename.
 * Migration will be done on the backend to existing views, but for now the view updates
 * called from the frontend will also tack-on a type update accordingly.
 *
 * We still need to check for the 'default' name for unmigrated views.
 */
function isViewDeletable(view) {
  return !isViewBudgetView(view)
    && !isViewSpecifierView(view)
    && !isViewSustainabilityView(view);
}

function isViewBudgetView(view) {
  if (!view) return false;

  if (view.type === PROJECT_BUDGET_VIEW_TYPE) return true;

  const viewName = view.name?.toLowerCase();
  if (!viewName) return false;

  const viewWords = viewName.split(/\s+/);
  for (let keyword of BUDGET_VIEW_KEYWORDS) {
    if (viewWords.includes(keyword)) {
      return true;
    }
  }
  return false;
}

function isViewSpecifierView(view) {
  if (!view) return false;
  if (view.type === SPECIFIER_VIEW_TYPE) return true;
  if (view.name === SPECIFIER_VIEW) return true;

  return false;
}

function isViewSustainabilityView(view) {
  if (!view) return false;
  if (view.type === SUSTAINABILITY_VIEW_TYPE) return true;
  if (view.name === SUSTAINABILITY_VIEW || view.name == POWER_DENSITY_VIEW) return true;

  return false;
}

export {
  isViewDeletable,
  isViewBudgetView,
  isViewSpecifierView,
  isViewSustainabilityView,
};