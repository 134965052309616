import {
  SCHEDULE_DELETE_ROW,
  SCHEDULE_RESTORE_ROWS,
} from '@/constants/undoActions';
export const getters = {
  undoActionsLength(state) {
    return state.undoActions.length;
  },
  lastUndoAction(state, getters) {
    const { undoActions } = state;
    const { undoActionsLength } = getters;
    return undoActions[undoActionsLength - 1];
  },
  getClassForLastUndoAction: (state) => ({ rowId: id } = {
  }) => {
    const { lastUndoAction } = state;
    const { action } = lastUndoAction || {
    };
    const rowId = lastUndoAction?.id || lastUndoAction?.rowId;
    if ([SCHEDULE_RESTORE_ROWS, SCHEDULE_DELETE_ROW].includes(action)
      && rowId === id) {
      return 'last-undo-action-block';
    } return '';
  },
};
