import { API } from 'aws-amplify';
export default {
  getProcoreAccountsList() {
    const query = `query listProcoreAccounts {
        listProcoreAccounts {
          id
          login
          name
        }
      }`;
    return API.graphql({
      query,
    });
  },
  getProcoreAuthLink() {
    const query = `
    query procoreAuth {
      response:getProcoreAuthUrl
    }
    `;
    return API.graphql({
      query,
    });
  },
  disconnectProcorAccount(variables) {
    const query = `
      mutation disconnectProcoreAccount (
        $accountId: String!
      ) {
        response: disconnectProcoreAccount (
          accountId: $accountId
        ){
          id
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  listLastUsedFolders(variables) {
    const query = `
    query lastUsedFolders{
      listProcoreLastUsedFolders{
        project_id
        name_with_path
        name
        login
        id
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getListProcoreCompanies(variables) {
    const query = `
    query listProcoreCompanies($accountId : String!){
      response:listProcoreCompanies(
        accountId:$accountId
      ){
          id
          name
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getListProcoreProjects(variables) {
    const query = `
    query listProcoreProjects($accountId : String! , $company_id : Int!){
      response:listProcoreProjects(
        accountId:$accountId
        company_id:$company_id
      ){
          id
          name
          display_name
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getListProcoreFolders(variables) {
    const query = `
    query listProcoreFolders($accountId : String! , $project_id:Int! ,$id:Int ){
      response:listProcoreFolders(
        accountId:$accountId,
        project_id:$project_id,
        id:$id
      ){
          id
          name
          name_with_path
          has_children_folders
          private
          read_only
          folders{
            id
            name
            name_with_path
            has_children_folders
            private
            read_only
          }
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  exportToProcore(variables) {
    const query = `
    mutation exportScheduleToProcore(
      $accountId : String! , $procoreFolderId:Int!,
      $procoreProjectId:Int!,
      $projectId:String!,
      $tableId:String!,
      $tableType:TableType!,
      $templateName:String!,
      $procoreFilename:String!,
      $fileFormat: fileFormat
      $primaryVersionId: String,
      $secondaryVersionId: String,
      $versionId: String,
      $workspaceId: String,
      $columns: [String]
      ){
      response:exportScheduleToProcore(
        accountId:$accountId,
        procoreFolderId:$procoreFolderId,
        procoreProjectId:$procoreProjectId
        projectId:$projectId
        procoreFilename:$procoreFilename
        fileFormat:$fileFormat
        versionId:$versionId
        tableId:$tableId
        tableType:$tableType
        templateName:$templateName
        primaryVersionId:$primaryVersionId
        secondaryVersionId:$secondaryVersionId
        workspaceId: $workspaceId
        columns:$columns
      ){
        pdfName
        downloadPdfName
        bucketName
        stepFuncName
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
};
