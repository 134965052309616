import {
  Amplify,
  Auth,
} from 'aws-amplify';
// eslint-disable-next-line import/no-unresolved
import AwsOptions from '../../aws_config';
const { origin } = window.location;
import { redirectAfterSuccessfulLogin } from '@/utils';
const redirectionGoogle = {
  redirectSignIn: `${origin}/projects`,
  redirectSignOut: `${origin}/login`,
  scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
  responseType: 'token',
};
// config for AWS Amplify
const config = {
  Auth: AwsOptions.Auth,
  API: {
    ...AwsOptions.API,
    graphql_headers: async () => {
      try {
        const session = await Auth.currentSession();
        return {
          Authorization: session?.getIdToken()?.getJwtToken(),
        };
      } catch (err) {
        console.log('currentSession', err);
      }
    },
  },
  oauth: {
    ...AwsOptions.oauth,
    ...redirectionGoogle,
  },
  Storage: AwsOptions.Storage,
};
export default {
  init() {
    Amplify.configure(config);
  },
  // updating the redirect url for events/exhibitions
  useEventConfig() {
    const updatedAwsConfig = {
      ...config,
      oauth: {
        ...config.oauth,
        redirectSignIn: `${origin}/event-page`,
      },
    };
    Amplify.configure(updatedAwsConfig);
  },
  async login(data, self) {
    const context = self;
    const authenticationData = {
      Username: data.email,
      Password: data.password,
    };
    Auth.signIn(authenticationData.Username, authenticationData.Password)
      .then(async (user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await context.$router.push({
            name: 'complete-new-password', query: self.$route.query, params: {
              user,
            },
          });
          context.$store.commit('spinner', false);
        } else {
          const { query } = context.$route;
          const newRegistrationFlow = true;
          if (query && query.hasOwnProperty('forcepay')) {
            context.$router.push({
              name: 'payment',
              query,
              params: {
                newRegistrationFlow,
              },
            });
          } else {
            context.$store.commit('spinner', false);
            await redirectAfterSuccessfulLogin(context);
          }
        }
      })
      .catch((err) => {
        context.$store.commit('spinner', false);
        if (err.message === 'User is not confirmed.') {
          context.$router.push({
            name: 'confirm-sign-up',
            params: {
              email: data.email, signUpBefore: true,
            },
          });
        } else {
          context.error = err.message;
        }
      });
  },
};
