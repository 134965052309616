import {
  ROW_DATA,
  ROWS_DATA,
} from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
export default {
  getCollectionProduct({
    collectionId,
    productId,
    workspaceId,
  }) {
    return new requestBuilder({
      requestName: 'getCollectionProduct',
      requestVariables: {
        tableId: 'String!',
        rowId: 'String!',
        workspaceId: 'String!',
      },
      response: ROW_DATA,
      variables: {
        tableId: collectionId,
        rowId: productId,
        workspaceId,
      },
    }).processRequest();
  },
  scrapeProductData({ url, identityId, attachmentId, workspaceId, abortController }) {
    const req = new requestBuilder({
      requestName: 'scrapeProductData',
      requestVariables: {
        attachmentId: 'String!',
        identityId: 'String!',
        url: 'String!',
        workspaceId: 'String!',
      },
      response: ROWS_DATA,
      variables: {
        attachmentId: attachmentId,
        url: url,
        identityId: identityId,
        workspaceId: workspaceId,
      },
    }).processRequest();

    abortController?.addRequest(req);
    return req;
  },
};
