import { subscribeOnProductUpdate } from '@/services/graphql/productsSubscriptions';
import { parseGetRows } from '@/utils/manageGetRows';
import { IS_STORE_ROOT } from '@/constants';
const LIMIT_LAST_PRODUCTS = 10;
export const actions = {
  // for last products
  manageLastProducts({ commit, state, dispatch }, payload) {
    const {
      parse = false,
      collectionName = '',
      libraryId = '',
      collectionId = '',
      parsedProduct = {
      },
      fields = [],
    } = payload || {
    };
    const resultProduct = parse ? parseGetRows(fields) : parsedProduct;
    const product = {
      ...resultProduct,
      collectionId,
      libraryId,
      collectionName,
    };
    const { id: productId = '' } = product || {
    };
    const { lastProducts } = state;
    if (lastProducts.some(e => e.id === productId) || !productId) return false;
    const newLastProducts = [product, ...lastProducts].splice(0, LIMIT_LAST_PRODUCTS);
    commit('setLastProducts', newLastProducts);
    dispatch('subscribeOnProduct', {
      collectionId, productId,
    });
  },
  async subscribeOnProduct({ dispatch }, { collectionId = '', productId = '' }) {
    if (!collectionId && !productId) return false;
    const nextSub = ({ data }) => {
      const { collectionId = '', fields = [] } = data.response || {
      };
      if (!collectionId) return false;
      const updatedProduct = parseGetRows(fields);
      dispatch('updateLastProduct', {
        ...updatedProduct, collectionId,
      });
    };
    const subscription = await subscribeOnProductUpdate({
      collectionId,
      productId,
    }, nextSub);
    dispatch('manageSubscriptions', {
      subscription, title: productId,
    });
  },
  manageSubscriptions({ dispatch, getters }, { subscription = null, title }) {
    const { productsRemovingSubscription } = getters;
    subscription && dispatch('ManageSubscriptions/setSubscription', {
      subscription,
      title,
    }, IS_STORE_ROOT);
    productsRemovingSubscription.length && dispatch('ManageSubscriptions/removeSubscriptions', productsRemovingSubscription, IS_STORE_ROOT);
  },
  updateLastProduct({ state, commit }, product) {
    const { lastProducts } = state;
    const { id: idFromSubscription = '' } = product;
    if (!idFromSubscription) return false;
    const updatedProducts = lastProducts.reduce((result, current) => {
      const { id = '', collectionName = '', libraryId = '' } = current || {
      };
      const filterProduct = id === idFromSubscription ?
        {
          ...product,
          collectionName,
          libraryId,
        } : current;
      const { status = '' } = filterProduct;
      return status !== 'deleted' ? [...result, filterProduct] : result;
    }, []);
    commit('setLastProducts', updatedProducts);
  },
  //for last recently Accessed Collection Products
  manageRecentlyAccessedCollectionProducts({ commit, dispatch, state }, payload) {
    const { collectionIdLastAccessedCollection } = state;
    const { products = [], libraryId = '', collectionId = '', collectionName = '' } = payload || {
    };
    if (products.length && (collectionIdLastAccessedCollection === collectionId)) return false;
    //unsubscribe previous collection
    dispatch('ManageSubscriptions/removeSubscriptions', [collectionIdLastAccessedCollection], IS_STORE_ROOT);
    commit('setRecentlyAccessedCollection', {
      products,
      libraryId,
      collectionId,
      collectionName,
    });
    //subscribe current collection
    dispatch('subscribeOnRecentlyCollection', {
      collectionId,
    });
  },
  async subscribeOnRecentlyCollection({ dispatch }, { collectionId = '' }) {
    if (!collectionId) return false;
    const nextSub = ({ data }) => {
      const { collectionId = '', fields = [] } = data.response || {
      };
      if (!collectionId) return false;
      const updatedProduct = parseGetRows(fields);
      dispatch('updateCollectionProduct', {
        ...updatedProduct,
      });
    };
    const subscription = await subscribeOnProductUpdate({
      collectionId,
    }, nextSub);
    subscription && dispatch('ManageSubscriptions/setSubscription', {
      subscription,
      title: collectionId,
    }, IS_STORE_ROOT);
  },
  updateCollectionProduct({ state, commit }, product) {
    const { recentlyAccessedCollection } = state;
    const { products = [] } = recentlyAccessedCollection || {
    };
    const { id: idFromSubscription = '' } = product;
    if (!idFromSubscription) return false;
    const updatedProducts = products.reduce((result, current) => {
      const { id: idCurrentProduct = '' } = current || {
      };
      const filterProduct = idCurrentProduct === idFromSubscription ? product : current;
      const { status = '' } = filterProduct;
      return status !== 'deleted' ? [...result, filterProduct] : result;
    }, []);
    commit('setRecentlyAccessedCollection', {
      ...recentlyAccessedCollection,
      products: updatedProducts,
    });
  },
};
