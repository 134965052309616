import { API } from 'aws-amplify';
import {
  PROJECT_DETAIL,
  PROJECT_DETAIL_NO_BRACKETS,
  PROJECT_PERMISSION,
  PROJECT_PERMISSIONS_USE_SUMMARY,
  PROJECT_MEMBERS_PAGINATION,
  LIBRARY,
  ASSISTANT_INVITE_STATUS,
  USER_PREFERENCES,
} from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
export default {
  getSortedUserProjectsV2(variables, response = PROJECT_PERMISSIONS_USE_SUMMARY, abortController) {
    const query = new requestBuilder({
      requestName: 'getSortedUserProjectsV2',
      requestVariables: {
        status: 'ProjectStatus!',
        scanIndexForward: 'Boolean',
        orderBy: ' SortingMode!',
        tagName: 'String',
        nextToken: 'String',
        limit: 'Int',
        workspaceId: 'String!',
      },
      response,
      variables,
    }).buildQuery();

    const req = API.graphql({
      query,
      variables,
    });

    abortController?.addRequest(req);
    return req;
  },
  getProjectById(variables) {
    const query = `
      query getProjectById ($id: String!, $workspaceId: String, $sharedLink: Boolean) {
        getProjectById(id: $id workspaceId: $workspaceId sharedLink: $sharedLink) ${PROJECT_DETAIL}
      }
    `;
    return API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    });
  },
  getProjectMembers(variables, response = PROJECT_MEMBERS_PAGINATION) {
    return new requestBuilder({
      requestName: 'getProjectMembers',
      requestVariables: {
        workspaceId: 'String!',
        projectId: 'String!',
        nextToken: 'String',
      },
      response,
      variables,
    }).processRequest();
  },
  deprecatedCreateProject(variables) {
    const query = `
      mutation deprecatedCreateProject (
        $projectName: String!,
        $baseBudget: Int
        $location: String,
        $powerDensity: Float,
        $area: Float,
        $areaUnit: AreaUnits,
        $phase: String,
        $number: String,
        $workspaceId: String
        $sourceLibraryId: String
      ) {
        response: deprecatedCreateProject(
            projectName: $projectName,
            baseBudget: $baseBudget
            location: $location
            powerDensity: $powerDensity
            area: $area,
            areaUnit: $areaUnit,
            phase: $phase,
            number: $number
            workspaceId: $workspaceId
            sourceLibraryId: $sourceLibraryId
          ) ${PROJECT_DETAIL}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  deprecatedCreateProjectV2(variables) {
    const query = `
      mutation deprecatedCreateProjectV2 (
        $projectName: String!,
        $baseBudget: Int
        $location: String,
        $powerDensity: Float,
        $area: Float,
        $areaUnit: AreaUnits,
        $address: AddressInput
        $phase: String,
        $number: String,
        $workspaceId: String
        $sourceLibraryId: String
      ) {
        response: deprecatedCreateProjectV2(
            projectName: $projectName,
            baseBudget: $baseBudget
            location: $location
            powerDensity: $powerDensity
            area: $area,
            areaUnit: $areaUnit,
            phase: $phase,
            number: $number
            workspaceId: $workspaceId
            sourceLibraryId: $sourceLibraryId
            address: $address
          ) ${PROJECT_DETAIL}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  updateProject(variables) {
    return new requestBuilder({
      requestName: 'updateProject',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        id: 'String!',
        projectName: 'String!',
        status: 'ProjectStatus!',
        sourceLibraryId: 'String',
        workspaceId: 'String',
      },
      response: `
        status
        projectName
        id
        creator {
          name
          email
          id
        }
        projectLastModified
        sourceLibraryId
        sourceLibrary ${LIBRARY}
      `,
      variables,
    }).processRequest();
  },
  deleteProject(variables) {
    const query = `
      mutation deleteProject ($id: String!, $workspaceId: String!) {
        deleteProject(id: $id, workspaceId: $workspaceId) {
          id,
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  copyProject(variables) {
    return new requestBuilder({
      requestName: 'copyProject',
      requestType: 'mutation',
      requestVariables: {
        projectId: 'String!',
        workspaceId: 'String',
      },
      response: PROJECT_DETAIL_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  projectSharedLink(variables) {
    const query = `
      mutation projectSharedLink (
        $isShared: Boolean!
        $projectId: String!
        $workspaceId: String!
      ) {
        projectSharedLink(
          isShared: $isShared
          projectId: $projectId
          workspaceId: $workspaceId
        ) ${PROJECT_DETAIL}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  inviteSourceryAssistant(variables) {
    const { projectId, workspaceId } = variables;
    const payload = {
      projectId,
      workspaceId,
      email: 'service@gosourcery.com',
      emailMessage: 'Invite to Project by User',
      type: 'collaborator',
    };
    return new requestBuilder({
      requestName: 'inviteUserToProject',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        projectId: 'String!',
        workspaceId: 'String',
        email: 'String!',
        emailMessage: 'String',
        type: 'PermissionType!',
      },
      response: PROJECT_PERMISSION,
      variables: payload,
    }).processRequest();
  },
  getAssistantInviteStatus(variables) {
    const query = new requestBuilder({
      requestName: 'getAssistantInviteStatus',
      requestVariables: {
        resource: 'String!',
        resourceId: 'String!',
      },
      response: ASSISTANT_INVITE_STATUS,
      variables,
    }).buildQuery();

    return API.graphql({
      query,
      variables,
    });
  },
  getUserProjectPreferences(variables) {
    return new requestBuilder({
      requestName: 'getUserProjectPreferences',
      requestVariables: {
        userId: 'String!',
        projectId: 'String!',
      },
      response: USER_PREFERENCES,
      variables,
    }).processRequest();
  },
  updateUserProjectPreference(variables) {
    return new requestBuilder({
      requestName: 'updateUserProjectPreference',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        userId: 'String!',
        projectId: 'String!',
        key: 'String!',
        value: 'String',
      },
      response: USER_PREFERENCES,
      variables,
    }).processRequest();
  },
};
