import { baseSubscription } from '@/utils/subscriptionHandler';
import Subscriptions from '@/services/graphql/subscriptions';
import CollectionsAPI from '@/services/graphql/collections';
const subscribeForCollectionsAccess = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: Subscriptions.subscribeCollectionsAccess,
  unsubscribe: false,
});
const subscribeToCollectionFollow = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: CollectionsAPI.subscribeCollectionFollow,
  unsubscribe: false,
});
const subscribeToCollectionUnfollow = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: CollectionsAPI.subscribeCollectionUnfollow,
  unsubscribe: false,
});
export {
  subscribeForCollectionsAccess,
  subscribeToCollectionFollow,
  subscribeToCollectionUnfollow,
};
