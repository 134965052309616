import router from '@/router';
import ProjectViewsApi from '@/services/graphql/projectViews';
import {
  setViewInStorage,
  getViewFromStorage,
  sortHelper,
  convertStringToArray,
} from '@/utils';
import { IS_STORE_ROOT } from '@/constants';
import {
  ORDERING_OF_VIEWS, SPECIFIER_VIEW,
} from '@/constants/scheduleViews';
export const actions = {
  async getViews({ commit, dispatch, rootGetters, rootState }, { sharedLink = null, projectId } = {
  }) {
    const { userId } = rootGetters;
    try {
      const { scheduleId } = rootState['ProjectDetailsTableSchedule'];
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const views = await ProjectViewsApi.getScheduleViews({
        scheduleId,
        projectId,
        sharedLink,
        workspaceId,
      });

      const customViews = views.filter(view => !ORDERING_OF_VIEWS.includes(view?.name));
      const sortedViews = ORDERING_OF_VIEWS
        .map(item => (views.find(i => i.name === item)));
      const newViews = [...sortedViews.filter(item => item), ...customViews];
      let mappedViews = [];
      if (newViews.length) {
        const { query } = router.currentRoute;
        mappedViews = newViews.map(view => {
          return {
            ...view,
            sortingData: sortHelper(view.id, view.sortingField),
          };
        });
        dispatch('setViews', {
          arrViews: mappedViews,
        });
        let selectedView = mappedViews.find(view => view.name === SPECIFIER_VIEW) || mappedViews[0];
        const activeWorkspaceId = rootState.Workspace.activeWorkspaceId;
        const lastViewId = query.viewId || getViewFromStorage(userId, projectId, activeWorkspaceId);
        if (lastViewId) {
          selectedView = mappedViews.find(view => view.id === lastViewId) || selectedView;
        }
        dispatch('setSelectedViewId', selectedView);
      }
    } catch (err) {
      console.log('getScheduleView err', err);
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  setViewsSorting({ state, commit }, { sortingData }) {
    const mappedViews = state.views.map(view => {
      if (view.id == sortingData.viewId) {
        const baseView = {
          ...view,
          sortingData,
        };
        return baseView;
      }
      return view;
    });
    commit('setViews', mappedViews);
  },
  setViews({ dispatch, commit, state }, { arrViews, allowSetViewMode = true }) {
    const updatedArrViews = arrViews.map(option => {
      // HACK - Why not migrate Sustainability View data to Power Density + change schema????
      if (option.name !== 'Sustainability View') return option;

      return {
        ...option,
        name: 'Power Density View',
      };
    });

    const toggleViewConditionsForViews = updatedArrViews.reduce((result, option) => {
      return {
        ...result,
        [option.id]: option.viewMode === 'gallery',
      };
    }, {
    });

    allowSetViewMode && dispatch('setViewMode', toggleViewConditionsForViews, IS_STORE_ROOT);
    commit('setViews', updatedArrViews);

    // If view default setting changes, active tags should update to match.
    for (const view of updatedArrViews) {
      if (view.id === state.selectedViewId) {
        commit('setActiveTags', view.filterTag ?? []);
      }
    }
  },
  async setSelectedViewId({ commit, dispatch, rootGetters, rootState, getters }, payload) {
    if (!payload) {
      commit('setSelectedViewId', null);
      return;
    }
    commit('SearchProjectCells/clearSearchString', null, IS_STORE_ROOT);
    if (rootState.ProjectAggregation.tagTotal) {
      commit('ProjectAggregation/setTagTotal', null, IS_STORE_ROOT);
    }
    const { id: projectId } = router.currentRoute.params;
    const activeWorkspaceId = rootState.Workspace.activeWorkspaceId;
    const { userId } = rootGetters;
    commit('setSelectedViewId', payload.id);
    setViewInStorage(userId, projectId, activeWorkspaceId, payload.id);

    const selectedView = getters.selectedView;
    if (isFilteredTagView(selectedView)) {
      await updateTagTotal({
        rootState: rootState,
        dispatch: dispatch,
        selectedView: selectedView,
      });
    }
    if (Array.isArray(selectedView?.filterTag)) {
      commit('setActiveTags', selectedView.filterTag);
    } else {
      commit('setActiveTags', []);
    }

    const { query } = router.currentRoute;
    if (query.viewId !== payload.id) {
      router
        .replace({
          query: {
            ...query,
            viewId: payload.id,
          },
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  async updateViewSettings({ commit, dispatch, rootState }, variables) {
    try {
      commit('spinner', true, IS_STORE_ROOT);
      const { scheduleId: tableId } = rootState['ProjectDetailsTableSchedule'];
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { id: projectId } = router.currentRoute.params;
      const { data } = await ProjectViewsApi.updateViewSettings({
        ...variables,
        projectId,
        tableId,
        tableType: 'schedule',
        workspaceId,
      });
      dispatch('updateViewOrder', data.response);
    } catch (err) {
      console.log('updateViewSettings', err);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async updateViewColumnOrder({ dispatch }, { variables, updateViewData = true }) {
    try {
      const { data } = await ProjectViewsApi.updateViewColumnOrder(variables);
      if (updateViewData) {
        dispatch('updateViewOrder', data.response);
      }
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async updateViewColumnsSize({ dispatch, getters, commit }, { variables, updateBefore = false }) {
    try {
      if (!updateBefore) {
        const { data } = await ProjectViewsApi.updateViewColumnsSize(variables);
        dispatch('updateViewOrder', data.response);
        return;
      }
      const newColumnsSize = variables?.columnsSize ?? [];
      const {
        selectedView = {
        },
      } = getters ?? {
      };
      const { columnsSize = [] } = selectedView ?? {
      };
      const updatedColumnsSize = columnsSize.map(col => {
        const changedCol = newColumnsSize.find(newCol => newCol.id == col.id);
        if (changedCol) {
          return {
            ...col,
            size: changedCol.size,
          };
        }
        return col;
      });
      commit('ProjectDetailsTableSchedule/setHoveredColumn', {
        clicked: false,
        index: -1,
        active: false,
      }, IS_STORE_ROOT);
      dispatch('updateViewOrder', {
        id: variables.viewId,
        columnsSize: updatedColumnsSize,
      });
      ProjectViewsApi.updateViewColumnsSize(variables);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  updateViewOrder({ dispatch, state }, viewData) {
    const { id, filterTag, sortingField } = viewData;
    const { views } = state;

    const newViews = views.map(view => {
      if (id !== view.id) return view;
      const newFilterTag = (!filterTag || typeof filterTag !== 'string') ? view.filterTag : convertStringToArray(filterTag);
      return {
        ...view,
        ...viewData,
        filterTag: newFilterTag,
        sortingData: sortHelper(view.id, sortingField || view.sortingField),
      };
    });
    dispatch('setViews', {
      arrViews: newViews,
      allowSetViewMode: false,
    });
  },
  async resetView({ commit, dispatch, state }, payload) {
    try {
      commit('spinner', true, IS_STORE_ROOT);
      const { data } = await ProjectViewsApi.resetView(payload);
      const newView = data.response;
      const { views } = state;
      const newViews = views.map(view => {
        return newView.id === view.id ? {
          ...newView,
          sortingData: view?.sortingData,
        } : view;
      });
      dispatch('setViews', {
        arrViews: newViews,
        allowSetViewMode: false,
      });
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  setGalleryToAllScheduleViews({ getters, dispatch }) {
    const obj = getters.viewsIds.reduce((result, option) => {
      return {
        ...result,
        [option]: true,
      };
    }, {
    });
    dispatch('setViewMode', obj, IS_STORE_ROOT);
  },
  updateActiveTags({ commit }, tags) {
    commit('setActiveTags', tags);
  },
};

function isFilteredTagView(selectedView) {
  return selectedView?.filterTag?.length === 1;
}
async function updateTagTotal({ rootState, dispatch, selectedView }) {
  const sid = rootState.ProjectDetailsTableSchedule.scheduleId;
  const tagName = selectedView.filterTag[0];
  dispatch('ProjectAggregation/getTotalOfTag', {
    tableId: sid,
    tableType: 'schedule',
    tagName: tagName,
  }, IS_STORE_ROOT);
}