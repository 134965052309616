export const mutations = {
  setUndoActions(state, payload) {
    state.undoActions = payload;
  },
  clearUndoActions(state) {
    state.undoActions = [];
  },
  setLastUndoAction(state, payload) {
    state.lastUndoAction = payload;
  },
  clearLastUndoAction(state) {
    state.lastUndoAction = null;
  },
};
