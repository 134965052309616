<template>
  <v-snackbar
    v-model="isSnackBarShown"
    :multi-line="true"
    :timeout="snackbar.timeout"
    :data-test="snackbar.dataTest"
    :color="snackbar.color">
    <p
      v-safe-html="titleToRendering"
      class="medium-p white--text font-weight-medium mr-auto" />
    <p
      v-safe-html="messageToRendering"
      class="small-p white--text" />
    <v-btn
      v-if="snackbar.title === 'Something went wrong with your payment'"
      color="blue"
      to="/payment"
      class="mt-3"
      @click="closeSnackBar">
      Return to payment
    </v-btn>
    <v-icon
      color="#fff"
      style="position: absolute; right: -5px; top: 5px"
      @click="closeSnackBar">
      mdi-close
    </v-icon>
  </v-snackbar>
</template>
<script>
import {
  mapState, mapMutations,
} from 'vuex';
export default {
  name: 'AppSnackBar',
  computed: {
    ...mapState(['snackbar']),
    messageToRendering() {
      const { text = '' } = this.snackbar;
      if (Array.isArray(text)) {
        return text.join(', ');
      }
      return text;
    },
    titleToRendering() {
      const { title } = this.snackbar;
      if (!title) return '';
      return title;
    },
    isSnackBarShown: {
      get() {
        return this.snackbar.isSnackBarShown;
      },
      set() {
        this.closeSnackBar();
      },
    },
  },
  methods: {
    ...mapMutations(['closeSnackBar']),
  },
};
</script>
