export const mutations = {
  setListOfVersions(state, payload) {
    state.listOfVersions = payload;
  },
  setCurrentVersion(state, payload) {
    state.currentVersion = payload;
  },
  setIssuanceMenu(state, payload) {
    state.issuanceMenu = payload;
  },
};
