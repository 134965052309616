import {
  SORTABLE_AS_ARRAY, SORTABLE_BY_DEFAULT, SORTABLE_BY_COEFFICIENT, SORT_TOP,
} from '@/constants/scheduleViews';
import SortRows from '@/utils/sorting/SortRows';
import SortTextRows from '@/utils/sorting/SortTextRows';
import SortArrayRows from '@/utils/sorting/SortArrayRows';
import ProductHeaders from '@/constants/productHeaders';
export default class SortRowsFactory {
  constructor({ sortOrder = SORT_TOP, sortBy, items }) {
    if (SORTABLE_AS_ARRAY.includes(sortBy)) {
      return new SortArrayRows({
        sortOrder,
        sortBy,
        items,
      });
    }
    if (SORTABLE_BY_DEFAULT.includes(sortBy)) {
      return new SortTextRows({
        sortOrder,
        sortBy,
        items,
      });
    }
    if (SORTABLE_BY_COEFFICIENT.includes(sortBy)) {
      return new SortTextRows({
        sortOrder,
        sortBy: ProductHeaders.SORTING_COEFFICIENT,
        items,
      });
    }
    return new SortRows({
      sortOrder,
      sortBy,
      items,
    });
  }
}
