<template>
  <div class="loading-route">
    <div
      v-for="(item, index) in ['blue', 'yellow', 'green', 'red']"
      :key="item"
      :class="`loading-route__circle item-${index} ${item}`" />
  </div>
</template>
<script>
export default {
  name: 'AppLoadingRoute',
};
</script>
<style lang="scss" scoped>
.loading-route {
  background: #fff;
  position: absolute;
  inset: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;

  &__circle {
    width: 36px;
    aspect-ratio: 1/1;
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(9999, jump-both);
    border-radius: 100%;
    filter: blur(0);
  }
  .item-0 {
    animation-delay: 0;
  }
  .item-1 {
    animation-delay: 90ms;
  }
  .item-2 {
    animation-delay: 180ms;
  }
  .item-3 {
    animation-delay: 270ms;
  }
  @keyframes bounce {
    24% {
      scale: 0.5;
      filter: blur(6px);
    }
    36%, 60%, 100%{
      filter: blur(0);
      scale: 1;
    }
    48% {
      scale: 1.3;
      filter: blur(6px);
    }
  }
}
</style>