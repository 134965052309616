const COMMUNITY_WS_PAGES_SEARCH_TITLE = 'wspagesCommunity';
const COMMUNITY_COLLECTIONS_SEARCH_TITLE = 'collectionsCommunitySearch';
const FOLLOW_COLL_SUB_TITLE = 'followCollectionsSub';
const UNFOLLOW_COLL_SUB_TITLE = 'followCollectionsSub';

export {
  COMMUNITY_WS_PAGES_SEARCH_TITLE,
  COMMUNITY_COLLECTIONS_SEARCH_TITLE,
  FOLLOW_COLL_SUB_TITLE,
  UNFOLLOW_COLL_SUB_TITLE,
};