<template>
  <div class="chart">
    <div>
      <apexchart
        :width="width"
        :height="height"
        :options="{...chartOptions, labels: chartMapping('name')}"
        :series="chartMapping('values')" />
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'AppPieChart',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    typePercentage: {
      type: Array,
      required: true,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '300',
    },
  },
  data: () => ({
    chartOptions: {
      chart: {
        type: 'pie',
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
          fontWeight: '0',
          fontColor: '#000',
        },
        formatter: (val, opts) => {
          return val = `${opts.w.config.labels[opts.seriesIndex]} - ${val.toFixed(2)}%`;
        },
        background: {
          enabled: true,
          foreColor: '#000',
        },
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        width: 1,
      },
      colors: [({ value, w }) => {
        const color = [
          'rgba(122, 184, 0, 1)',
          'rgba(223, 223, 0, 1)',
          'rgba(233, 153, 74, 1)',
          'rgba(113, 214, 224, 1)',
          'rgba(207, 0, 114, 1)',
          'rgba(22, 126, 5, 1)',
          'rgba(186, 189, 17, 1)',
          'rgba(235, 80, 14, 1)',
          'rgba(64, 164, 186, 1)',
          'rgba(120, 46, 138, 1)',
        ];
        let arrayOfNumbers = w.config.series.sort((a, b) => {
          return b - a;
        });
        const colorNumber = arrayOfNumbers.indexOf(value);
        return color[colorNumber];
      }],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 40,
            minAngleToShowLabel: 0,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      states: {
        normal: {
          filter: {
            type: 'darken',
            value: 0.75,
          },
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.40,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'darken',
            value: 0.25,
          },
        },
      },
    },
    others: 0,
  }),
  computed: {
    sortedPercentageInfo() {
      const allCounts = this.typePercentage.reduce((sum, { values }) => sum + values, 0);
      const others = {
        name: 'Others',
        values: 0,
      };
      this.typePercentage.forEach(el => {
        el.values = (el.values * 100) / allCounts;
        if (el.values < 5) {
          others.values += el.values;
        }
      });
      const info = this.typePercentage.filter(el => el.values > 5);
      info.push(others);

      const sortedInfo = info.toSorted((a, b) => b.values - a.values);
      return sortedInfo;
    },
  },
  methods: {
    chartMapping(key) {
      let activeItems = [];
      this.sortedPercentageInfo.map(element => activeItems.push(element[key]));

      return activeItems;
    },
  },
};
</script>
<style lang="scss">
  svg {
    overflow: visible!important;
  }
  .chart {
    display: grid;
    justify-content: center;
  }
</style>
