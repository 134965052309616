<template>
  <v-radio-group
    v-model="radioModelCondition"
    class="mt-3 ml-6 mb-0 pt-0"
    row
    mandatory>
    <v-radio
      v-for="value in AREA_UNITS_MAPPING"
      :key="value.value"
      :label="value.title"
      :value="value.value" />
  </v-radio-group>
</template>
<script>
import { AREA_UNITS_MAPPING } from '@/constants/projectArea';
export default {
  props: {
    radioModel: {
      type: String,
      default: '',
      require: true,
    },
  },
  data: () => ({
    AREA_UNITS_MAPPING,
  }),
  computed: {
    radioModelCondition: {
      get() { return this.radioModel; },
      set (val) { this.$emit('updateRadioModel', val); },
    },
  },
};
</script>
