<template>
  <v-tooltip
    v-if="showAssistantInvite"
    bottom
    :disabled="isPremiumWorkspace">
    <template #activator="{ on, attrs }">
      <div
        class="assistant-invite-wrapper d-flex align-center"
        :class="[
          small ? 'pa-3' : 'pa-4',
          isPremiumWorkspace ? '' : 'disabled']"
        v-bind="attrs"
        v-on="on">
        <v-btn
          v-if="useDismissal"
          class="mt-1 mr-1"
          :disabled="!isPremiumWorkspace"
          icon
          @click="dismissInvite">
          <v-icon
            color="black"
            size="18">
            mdi-close
          </v-icon>
        </v-btn>
        <div class="flex-grow-1 d-flex justify-center">
          <p>
            {{ inviteMessage }}
            <a
              href="#"
              class="assistant-invite"
              :class="{ 'inviting': invitingAssistant }"
              @click.prevent="inviteAssistantCB">
              clicking here.
            </a>
          </p>
        </div>
      </div>
    </template>
    <span>To invite a Sourcery Assistant to your project, please upgrade your workspace.</span>
  </v-tooltip>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import ProjectsApi from '@/services/graphql/projects';

export default {
  props: {
    projectId: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    // Add ability to dismiss with 'X'
    // ALSO will make the whole component just not display if has been dismissed!
    useDismissal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    initializing: true,
    invitingAssistant: false,
  }),
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Workspace', ['isPremiumWorkspace']),
    ...mapGetters('Projects', ['userDismissedAssistant']),

    showAssistantInvite() {
      if (!this.useDismissal) return true;
      return !this.initializing && !this.userDismissedAssistant;
    },
    inviteMessage() {
      if (this.small) {
        return 'Have a question? Invite a Sourcery Assistant by';
      } else {
        return 'Have a question on this product? Invite a Sourcery Assistant by';
      }
    },
  },
  async created() {
    this.initializing = true;
    try {
      await this.fetchUserProjectPreferences(this.projectId);
    } finally {
      this.initializing = false;
    }
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions('Projects', ['fetchUserProjectPreferences', 'dismissAssistant']),

    async inviteAssistantCB() {
      if (!this.isPremiumWorkspace || this.invitingAssistant) return;

      try {
        this.spinner(true);
        this.invitingAssistant = true;

        const inviteRes = await ProjectsApi.inviteSourceryAssistant({
          projectId: this.projectId,
          workspaceId: this.activeWorkspaceId,
        });
        const { data } = inviteRes;
        const status = data?.response?.status;
        if (status !== 'active') {
          throw new Error('Invitation failed!', inviteRes);
        }
        this.$emit('assistantInvited');

      } catch (err) {
        console.error('Failed inviting assistant', err);

      } finally {
        this.invitingAssistant = false;
        this.spinner(false);
      }
    },
    async dismissInvite() {
      const confirmDismissal = await this.$openConfirm({
        text: 'Are you sure you want to remove this reminder from this project?',
      });
      if (!confirmDismissal) return;

      await this.dismissAssistant(this.projectId);
      this.fetchUserProjectPreferences(this.projectId);
    },
  },
};
</script>

<style scoped lang="scss">
.assistant-invite-wrapper {
  background: var(--v-lightGrey-base);
  border-radius: 10px;
  p {
    font-weight: bold !important;
    letter-spacing: initial;
    margin: 0;
  }
}
.assistant-invite-wrapper.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  .assistant-invite {
    cursor: not-allowed;
  }
}
.assistant-invite {
  cursor: pointer;
  text-decoration: underline !important;
}
.assistant-invite.inviting {
  cursor: wait;
}
</style>