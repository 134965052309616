import {
  hasEditorAccess,
  hasCollaboratorAccess,
  hasCreatorAccess,
  isFollower,
  hasCreatorAdminAccess,
  accessHelper,
  hasAccess, isOwner,
} from '@/utils';
import {
  GRADE_REASON,
  ACCESS_REASON,
  TYPE_OWNER,
  LOCKED_TO_WORKSPACE_FOLLOW_REASON,
  ACCESS_COPY_CRATED_PERSONAL_WS,
  TYPE_VIEWER,
  NOT_PERMITTED_ACCESS,
  NOT_PERMITTED_SOURCE_LIBRARY,
  LOCKED_TO_WORKSPACE_EMBED,
  ALLOW_PAGES_REASON,
  FOLLOWED_PRODUCT_REASON,
  FOLLOWED_COLLECTION_PRODUCT_REASON,
  NO_PERMISSION_FOR_PRODUCT_IN_COLLECTION_REASON,
} from '@/constants/userPermissions';
import {
  PRIVATE, TYPE_WORKSPACE_SHARE,
} from '@/constants';
import { LIBRARY } from '@/constants/cores';
import {
  getUserRolesListById, hasAccessV2,
} from '@/utils/accessLayer';
import { FOLLOWER_AND_EDITOR_AND_MORE } from '@/constants/accessRolesList';
export const getters = {
  canCreateCopyProject: (state, getters, rootState, rootGetters) => role => {
    const isPersonalWorkspace = rootGetters['Workspace/isPersonalWorkspace'];
    const isFreeWorkspace = rootGetters['Workspace/isFreeWorkspace'];
    const isDisabledInPersonalWsCreateCopy = rootGetters['Workspace/disabledPersonalWs'];
    const isActiveGuestInWorkspace = rootGetters['Workspace/isActiveUserGuestInWorkspace'];
    if (isDisabledInPersonalWsCreateCopy) {
      return accessHelper(false, ACCESS_COPY_CRATED_PERSONAL_WS);
    }
    if (!hasCreatorAccess(role) || !rootGetters.userId) {
      // Allow guests to create projects.
      // - DON'T give them 'creator' role in workspace because that will mess permissions up everywhere.
      // - But they will be 'creator' within the project.
      // https://app.asana.com/0/home/1205872899101248/1206554216093410
      if (!isActiveGuestInWorkspace) {
        return accessHelper(false, ACCESS_REASON);
      }
    }
    if (rootGetters.premiumGroup && isPersonalWorkspace) {
      return accessHelper(true);
    }
    if ((isFreeWorkspace || (isPersonalWorkspace && !rootGetters.premiumGroup))) {
      const { active } = rootState.Projects;
      const isEnoughProjects = active.length < 5;
      return accessHelper(isEnoughProjects, isEnoughProjects ? null : GRADE_REASON);
    }
    return accessHelper(true, ACCESS_REASON);
  },
  canCreateProject: (state, getters, rootState, rootGetters) => {
    return getters.canCreateCopyProject(rootGetters['Workspace/getActiveWorkspaceRole']);
  },
  /**
   * archive, delete, copy,
   * unarchive, update, view info with
   * dashboard,
   */
  canModifyProject: (state, getters, rootState, rootGetters) => role => {
    const canModifyProject = !!rootGetters.userId && hasCreatorAccess(role);
    return accessHelper(canModifyProject, !canModifyProject && ACCESS_REASON);
  },
  canCreateProjectWithSourceLibrary: (state, getters, rootState, rootGetters) => role => {
    const accessToModifyProject = getters.canModifyProject(role);
    if (!accessToModifyProject.allowed) {
      return accessToModifyProject;
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  /**
   * Update sharing permissions
   * Owner transfer
   */
  canShareProjectPermissionWithNoLimit: (state, getters, rootState, rootGetters) => role => {
    return rootGetters.premiumGroup && hasCreatorAccess(role);
  },
  canShareProjectPermissionWithLimit: (state, getters, rootState, rootGetters) => role => {
    return !rootGetters.premiumGroup && hasCreatorAccess(role);
  },
  // eslint-disable-next-line no-unused-vars
  canViewSummaryProjectBudgetView: (state, getters, rootState, rootGetters) => role => {
    // return hasEditorAccess(role) && (rootGetters.premiumGroup || rootGetters.collaboratorGroup);
    return accessHelper(true);
  },
  // eslint-disable-next-line no-unused-vars
  canViewSummarySustainabilityView: (state, getters, rootState, rootGetters) => role => {
    // return hasEditorAccess(role) && (rootGetters.premiumGroup || rootGetters.collaboratorGroup);
    return accessHelper(true);
  },
  canRemoveYourself(state, getters, rootState, rootGetters) {
    //todo check how can work owner
    return !!rootGetters.userId;
  },
  /**
   * Create, Delete
   */
  canModifyCustomViewBasedOnTag: (state, getters, rootState, rootGetters) => role => {
    if (!hasCreatorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup || rootGetters['Workspace/isFreeWorkspace']) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canUseSharedLink: (state, getters, rootState, rootGetters) => role => {
    if (!hasCreatorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  /**
   * Create, Edit, Archive, Delete
   */
  canModifyIssuance: (state, getters, rootState, rootGetters) => role => {
    if (!hasCreatorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup || rootGetters['Workspace/isFreeWorkspace']) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  /**
   * Can see list of views, edit,
   * see schedule view settings
   */
  canModifyViews: (state, getters, rootState, rootGetters) => role => {
    const allowedAccess = rootGetters.userId && hasCreatorAccess(role);
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canCompareWithCollection: (state, getters, rootState, rootGetters) => role => {
    if (!hasCreatorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup || rootGetters['Workspace/isFreeWorkspace']) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canModifyProjectDocuments(state, getters, rootState, rootGetters) {
    const { role } = rootState;
    const allowedAccess = !!rootGetters.userId && hasAccess(TYPE_VIEWER, role);
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canModifyNotOwnProjectDocuments(state, getters, rootState, rootGetters) {
    const { role } = rootState;
    const canModify = !!rootGetters.userId && hasCreatorAccess(role);
    return accessHelper(canModify, !canModify && ACCESS_REASON);
  },
  canShareProjectLinkWithNonMembers: (state, getters, rootState, rootGetters) => role => {
    const canUseCollaborator = rootGetters['FeatureFlags/collaboratorCanUseExportFunctionality'];
    const conditionalAccess = canUseCollaborator ? hasCollaboratorAccess(role) : hasEditorAccess(role);
    if (!conditionalAccess) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canUseExportToForCollaboratorRole: (state, getters, rootState, rootGetters) => role => {
    const canUseCollaborator = rootGetters['FeatureFlags/collaboratorCanUseExportFunctionality'];
    const conditionalAccess = canUseCollaborator ? hasCollaboratorAccess(role) : hasEditorAccess(role);
    return conditionalAccess;
  },
  canUseExportTo: () => role => {
    return hasEditorAccess(role);
  },
  /**
   * Add new rows, delete rows,
   * duplicate row, hide row,
   * see trash list, restore row
   */
  canModifyTableRows: (state, getters, rootState, rootGetters) => role => {
    const allowedAccess = !!rootGetters.userId && hasEditorAccess(role);
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canSaveAsNewProduct: (state, getters, rootState, rootGetters) => role => {
    if (!hasEditorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canUpdateReferencedProduct: (state, getters, rootState, rootGetters) => mode => {
    const { roleInResource: role } = rootState;
    const useUpdateReferencedProductWithProjectDataV2 = rootGetters['FeatureFlags/useUpdateReferencedProductWithProjectDataV2'];
    if (useUpdateReferencedProductWithProjectDataV2) return getters.canUpdateReferencedProductV2(role);
    if ((isFollower(role) || mode == 'follow')) {
      return accessHelper(false, FOLLOWED_PRODUCT_REASON);
    }
    if (!hasEditorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canUpdateReferencedProductV2: () => role => {
    if (isFollower(role)) {
      return accessHelper(false, FOLLOWED_COLLECTION_PRODUCT_REASON);
    }
    if (!hasEditorAccess(role)) {
      return accessHelper(false, NO_PERMISSION_FOR_PRODUCT_IN_COLLECTION_REASON);
    }
    return accessHelper(true);
  },
  canUpdateInfoInCustomLibrary: (state, getters, rootState, rootGetters) => role => {
    const isPersonalWorkspace = rootGetters['Workspace/isPersonalWorkspace'];
    const isActiveUserGuestInWorkspace = rootGetters['Workspace/isActiveUserGuestInWorkspace'];
    const isCreator = hasCreatorAccess(role);
    if ((isActiveUserGuestInWorkspace && !isPersonalWorkspace && !isCreator) || !isCreator) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  /**
   * create, delete
   */
  canModifyCustomLibrary: (state, getters, rootState, rootGetters) => role => {
    if (!hasCreatorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canCreateCustomLibrary(state, getters, rootState, rootGetters) {
    const isDisabledInPersonalWsCreateCopy = rootGetters['Workspace/disabledPersonalWs'];
    if (isDisabledInPersonalWsCreateCopy) {
      return accessHelper(false, ACCESS_COPY_CRATED_PERSONAL_WS);
    }
    return accessHelper(true);
  },
  canShareCustomLibrary: (state, getters, rootState, rootGetters) => role => {
    return rootGetters.premiumGroup && hasCreatorAccess(role);
  },
  canShareLibraryOrCollection: (state, getters) => (role, docType, dialogType) => {
    if (dialogType === TYPE_WORKSPACE_SHARE) {
      return true;
    }
    if (docType === LIBRARY) {
      return getters.canShareCustomLibrary(role);
    }
    return getters.canShareCollectionWithNoLimit(role) || getters.canShareCollectionWithLimit(role);
  },
  canCreateCollectionInDefaultLibrary: (state, getters, rootState, rootGetters) => role => {
    const isDisabledInPersonalWsCreateCopy = rootGetters['Workspace/disabledPersonalWs'];
    if (isDisabledInPersonalWsCreateCopy) {
      return accessHelper(false, ACCESS_COPY_CRATED_PERSONAL_WS);
    }
    if (!hasCreatorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    const hasNoLimitToCreate = rootGetters['Collections/getPrivateCollectionsListLength'] < 5;
    if ((rootGetters['Workspace/isFreeWorkspace'] || !rootGetters.premiumGroup) && !hasNoLimitToCreate) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canCreateCollectionInCustomLibrary: (state, getters, rootState, rootGetters) => role => {
    const isDisabledInPersonalWsCreateCopy = rootGetters['Workspace/disabledPersonalWs'];
    if (isDisabledInPersonalWsCreateCopy) {
      return accessHelper(false, ACCESS_COPY_CRATED_PERSONAL_WS);
    }
    const isGuestInWorkspace = rootGetters['Workspace/isActiveUserGuestInWorkspace'];
    if (!hasCreatorAccess(role) || isGuestInWorkspace) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canModifyCollection: (state, getters, rootState, rootGetters) => role => {
    const allowedAccess = !!rootGetters.userId && hasCreatorAccess(role);
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canAddProductToCollection: (state, getters, rootState, rootGetters) => role => {
    const allowedAccess = !!rootGetters.userId && hasCreatorAccess(role);
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canFollowPage: (state, getters, rootState, rootGetters) => {
    // https://app.asana.com/0/1204511634344720/1204353485637463/f
    // Possibility to follow page have Owner, Creator(Admin), Creator
    const role = rootGetters['Workspace/getActiveWorkspaceRole'];
    const hasFullAccess = isOwner(role) || hasCreatorAccess(role) || hasCreatorAdminAccess(role);
    const allowedAccess = !!rootGetters.userId && hasFullAccess;
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canModifyProduct: (state, getters, rootState, rootGetters) => (role, otherReason = '') => {
    const allowedAccess = !!rootGetters.userId && hasCreatorAccess(role);
    return accessHelper(allowedAccess, !allowedAccess && otherReason || ACCESS_REASON);
  },
  canPublishCollection: (state, getters, rootState, rootGetters) => role => {
    if (!hasCreatorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canFollowCollection: (state, getters, rootState, rootGetters) => {
    const type = rootGetters['Collections/typeSharingOption'];
    if (type === PRIVATE) {
      return accessHelper(false, LOCKED_TO_WORKSPACE_FOLLOW_REASON);
    }
    const allowedAccess = !!rootGetters.userId;
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  // eslint-disable-next-line no-unused-vars
  canFollowProduct: (state, getters, rootState, rootGetters) => role => {
    return !!rootGetters.userId;
    // return rootGetters.userId && hasAccess(TYPE_VIEWER, role);
  },
  canCopyCollectionInDefaultLibrary: (state, getters, rootState, rootGetters) => role => {
    const hasNoLimitToCreate = rootGetters.premiumGroup || rootGetters['Collections/getPrivateCollectionsListLength'] < 5;
    const allowedAccess = hasEditorAccess(role) || isFollower(role);
    if (!allowedAccess) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!hasNoLimitToCreate && !rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canCopyCollectionInCustomLibrary: (state, getters, rootState, rootGetters) => role => {
    if (!hasCreatorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  /**
   * Check if the user can modify carousel photos based on their role.
   *
   * @param {String} role - A function that returns the user's role.
   * @returns {Boolean} True if the user can modify carousel photos, false otherwise.
   */
  canModifyCarouselPhotos: () => role => {
    /**
     * Check if the user has creator access.
     *
     * @param {String} role - The user's role.
     * @returns {Boolean} True if the user has creator access, false otherwise.
     */
    if (hasCreatorAccess(role)) return accessHelper(true);
    return accessHelper(false, ACCESS_REASON);
  },
  canRemoveCollectionFromLibrary: (state, getters, rootState, rootGetters) => role => {
    const allowedAccess = !!rootGetters.userId && (hasEditorAccess(role) || isFollower(role));
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canRemoveCollectionFromMyLibrary: () => role => {
    const allowedAccess = hasCreatorAccess(role);
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canAddCollectionToLibrary: () => role => {
    if (Array.isArray(role)) {
      return accessHelper(hasAccessV2(role, FOLLOWER_AND_EDITOR_AND_MORE), ACCESS_REASON);
    }
    return accessHelper(hasEditorAccess(role) || isFollower(role), ACCESS_REASON);
  },
  canShareCollectionWithLimit: (state, getters, rootState, rootGetters) => role => {
    return !rootGetters.premiumGroup && hasCreatorAccess(role);
  },
  canShareCollectionWithNoLimit: (state, getters, rootState, rootGetters) => role => {
    return rootGetters.premiumGroup && hasCreatorAccess(role);
  },
  canPublishInCurrentWorkspace: (state, getters, rootState) => {
    return rootState.activeHeader?.workspaceId === rootState.Workspace.activeWorkspaceId;
  },
  findUserRoleInLibrary: (state, getters, rootState, rootGetters) => ({
    members = [],
    creator,
    checkIsPublishCollection = true,
  }) => {
    if (creator?.id === rootGetters.userId) {
      return TYPE_OWNER;
    }
    if (checkIsPublishCollection && rootGetters['Collections/isPublishedActiveCollection']) {
      return rootState.roleInResource;
    }
    if (!members || !members.length || !Array.isArray(members)) {
      return '';
    }
    return members.find(({ id }) => id == rootGetters.userId)?.type;
  },
  findUserRoleInLibraryForActiveHeader: (state, getters, rootState) => {
    if (!rootState.activeHeader) {
      return '';
    }
    return getters.findUserRoleInLibrary(rootState.activeHeader);
  },
  usersRolesListInUpdateCollection: (state, getters, rootState, rootGetters) => {
    return getUserRolesListById({
      userId: rootGetters.userId,
      users: rootState.updateLibraryOrCollection?.members,
    });
  },
  usersRolesListInCollection: (state, getters, rootState, rootGetters) => {
    if (!rootState.activeHeader) {
      return [''];
    }
    const { checkIsPublishCollection, members } = rootState.activeHeader;
    if (checkIsPublishCollection && rootGetters['Collections/isPublishedActiveCollection']) {
      return [rootState.roleInResource];
    }
    const { userId } = rootGetters;
    return getUserRolesListById({
      userId,
      users: members,
    });
  },
  findUserRoleInUpdateLibraryOrCollection: (state, getters, rootState) => {
    if (!rootState.updateLibraryOrCollection) {
      return '';
    }
    return getters.findUserRoleInLibrary(rootState.updateLibraryOrCollection);
  },
  /**
   * General, Edit, Simple
   */
  canSubmitEditCommentPricePrivate: (state, getters, rootState, rootGetters) => role => {
    if (rootGetters['FeatureFlags/useViewerAndEditorUpdates']) {
      return getters.canSubmitEditCommentPricePrivateV2;
    }
    return getters.canSubmitEditCommentPricePrivateV1(role);
  },
  canSubmitEditCommentPricePrivateV1: (state, getters, rootState, rootGetters) => role => {
    if (!hasEditorAccess(role)) {
      return accessHelper(false, ACCESS_REASON);
    }
    if (!rootGetters.premiumGroup && !rootGetters.collaboratorGroup) {
      return accessHelper(false, GRADE_REASON);
    }
    return accessHelper(true);
  },
  canSubmitEditCommentPricePrivateV2: (state, getters, rootState, rootGetters) => {
    if (!rootGetters.userId) {
      return accessHelper(false, ACCESS_REASON);
    }
    return accessHelper(true);
  },
  canEditWorkspaceInfo: (state, getters, rootState, rootGetters) => role => {
    const allowedAccess = rootGetters.userId && hasCreatorAdminAccess(role);
    return accessHelper(allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canDragAndDropColumnsInSchedule(state, getters, rootState, rootGetters) {
    const { role } = rootState;
    return rootGetters.userId && hasCreatorAccess(role);
  },
  canResizeColumns: (state, getters, rootState, rootGetters) => role => {
    return rootGetters.userId && hasCreatorAccess(role);
  },
  canShowCollectionInfoDialog: (state, getters) => item => {
    const allowedAccess = getters.findUserRoleInLibrary({
      ...item, checkIsPublishCollection: false,
    });
    return accessHelper(!!allowedAccess, !allowedAccess && ACCESS_REASON);
  },
  canSeeInstructionNoteProductReference: (state, getters, rootState) => {
    const { role } = rootState;
    return hasEditorAccess(role);
  },
  disabledRoleShared: (state, getters, rootState, rootGetters) => (permission, canWorkWithModifyShare) => {
    const id = permission.userInfo?.id ?? permission.id;
    const isCurrentUser = rootGetters.userId === id;
    if (permission.type === TYPE_OWNER) {
      return true;
    }
    return (!canWorkWithModifyShare && !isCurrentUser)
      || !isCurrentUser && isOwner(permission.type);
  },
  canAddProductToProject: () => (role, library, productCollectionId) => {
    try {
      if (!hasEditorAccess(role)) {
        return accessHelper(false, NOT_PERMITTED_ACCESS);
      }
      if (library) {
        const { collections } = library;
        const allowed = !!Object.values(collections).flat().find(collection => collection?.id == productCollectionId);
        return accessHelper(allowed, !allowed && NOT_PERMITTED_SOURCE_LIBRARY);
      }
      return accessHelper(true);
    } catch (e) {
      console.log(e);
    }
  },
  canCreateEmbedLink: (state, getters, rootState) => role => {
    const item = rootState.Collections.currentCollectionSharingOption;
    if (item?.type == PRIVATE) {
      return accessHelper(false, LOCKED_TO_WORKSPACE_EMBED);
    }
    const allowed = hasCreatorAccess(role);
    return accessHelper(allowed, !allowed && ACCESS_REASON);
  },
  canPublishWs: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['Workspace/isActiveWorkspaceAllowPages']) {
      return accessHelper(false, ALLOW_PAGES_REASON);
    }
    const allowed = hasCreatorAdminAccess(rootGetters['Workspace/getActiveWorkspaceRole']);
    return accessHelper(allowed, !allowed && ACCESS_REASON);
  },
};
