
export const getDefaultState = () => {
  return {
    commentsList: [],
    rowFollowers: [],
    newCommentId: null,
    commentCellPointers: [],
    slants: [],
    projectSlants: [],
    collectionSlants: [],
    hideResolvedCollectionCommentsV2: false,
    hideResolvedProjectCommentsV2: false,
    hideResolvedComments: false,
    newCollectionCommentIdV2: null,
    newProjectCommentIdV2: null,
    collectionCommentsListV2: [], // new refactored comments
    projectCommentsListV2: [], // new refactored comments
    collectionCollaboratorsListV2: [],
    projectCollaboratorsListV2: [],
  };
};
export const state = getDefaultState();
