<template>
  <v-dialog
    :value="true"
    persistent
    max-width="450">
    <v-card>
      <v-card-title>
        Confirm
        <span
          class="sourcery__icon-wrapper black pointer"
          @click="$emit('resolve', false)">
          <v-icon
            color="white"
            size="15">
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-card-text class="text-center">
        <p
          v-safe-html="text"
          class="ma-0" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          @click="cancel">
          {{ params.cancel || 'Cancel' }}
        </v-btn>
        <v-btn
          class="white--text"
          color="blue"
          elevation="0"
          @click="accept">
          {{ params.accept || 'Ok' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    text() {
      return this.params?.text || '';
    },
  },
  methods: {
    cancel() {
      const { cancelResponse } = this.params;
      this.$emit('resolve', cancelResponse ?? false);
    },
    accept() {
      const { acceptResponse } = this.params;
      this.$emit('resolve', acceptResponse ?? true);
    },
  },
};
</script>
