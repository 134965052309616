export const getDefaultState = () => {
  return {
    lastProducts: [],
    recentlyAccessedCollection: {
      products: [],
      collectionId: '',
      collectionName: '',
      libraryId: '',
    },
  };
};
export const state = getDefaultState();
