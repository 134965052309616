import SortRows from '@/utils/sorting/SortRows';
import { isString } from 'lodash';
export default class SortTextRows extends SortRows {
  constructor({ sortOrder = 'ASC', sortBy, items }) {
    super({
      sortBy,
      sortOrder,
      items,
    });
  }
  sort() {
    const { items, sortBy } = this.getSortingParams();
    return items.sort((a, b) => {
      let [first, next] = [a[sortBy], b[sortBy]];
      if (this.useLowerCaseSort) {
        if (isString(first)) {
          first = first.toLowerCase();
        }
        if (isString(next)) {
          next = next.toLowerCase();
        }
      }
      if (first == next) {
        return 0;
      }
      if (this.isSortBottom()) {
        return first < next ? 1 : -1;
      }
      return first > next ? 1 : -1;
    });
  }
}
