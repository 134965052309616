<template>
  <app-btn
    :text="text"
    :top="top"
    :right="right"
    :color-tooltip="colorTooltip"
    :left="left"
    :bottom="bottom"
    :disabled="isDisabled"
    :access="access"
    :show-tooltip="showTooltip">
    <template #buttonWrapper="{ btnAttrs, btnListeners}">
      <p
        v-bind="btnAttrs"
        :class="[wrapperClass, isDisabled ? 'c-grey' : '']"
        v-on="mergeAttrs($listeners, btnListeners)">
        <slot name="iconWrapper">
          <img
            v-if="iconSrc"
            :alt="$attrs.alt"
            :class="iconClass"
            :width="iconWidth"
            :height="iconHeight"
            :src="iconSrc">
        </slot>
        <slot />
      </p>
    </template>
  </app-btn>
</template>
<script>
import appBtnMixin from '@/mixins/appBtnMixin';
export default {
  name: 'AppActionBtn',
  mixins: [appBtnMixin],
  props: {
    iconSrc: {
      type: String,
      default: '',
    },
    iconHeight: {
      type: String,
      default: '15px',
    },
    iconWidth: {
      type: String,
      default: '15px',
    },
    iconClass: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped lang="scss">
.c-grey {
  cursor: context-menu !important;
  color: grey;
}
</style>
