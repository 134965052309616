import { isString } from 'lodash';
import { NOT_A_STRING } from '@/constants/typeErrors';
import {
  DATE_PICKER_REG_EXP, NOT_VALID,
} from '@/constants/validations';
export default {
  isInteger(evt) {
    const theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length === 0) return;
    const regex = /^[0-9\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  },
  /**
   * @param value
   * @return {{reason: string, validate: boolean}|{reason: string, validated: boolean}|{validated: boolean}}
   */
  validateDatePicker(value) {
    if (!value) return {
      validated: true,
    };
    if (!isString(value)) {
      return {
        validated: false,
        reason: NOT_A_STRING,
      };
    }
    const foundMatches = value.match(DATE_PICKER_REG_EXP);
    if (foundMatches && foundMatches[0]) {
      return {
        validated: true,
      };
    }
    return {
      validate: false,
      reason: NOT_VALID,
    };
  },
};
