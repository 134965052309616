import { mapState } from 'vuex';
export default {
  created() {
    addEventListener('beforeunload', this.beforeWindowUnload, {
      capture: true,
    });
  },
  beforeDestroy() {
    removeEventListener('beforeunload', this.beforeWindowUnload, {
      capture: true,
    });
  },
  methods: {
    beforeWindowUnload(e) {
      if (!this.notSavedDataFlag) return;
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = 'Changes you made may not be saved.';
      return 'Changes you made may not be saved.';
    },
  },
  computed: {
    ...mapState({
      notSavedDataFlag: state => state.notSavedDataFlag,
    }),
  },
};
