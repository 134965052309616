<template>
  <v-dialog
    v-model="syncValue"
    v-bind="{...$attrs, ...$props}"
    @click:outside="$emit('outside')">
    <template
      v-for="(_, name) in $scopedSlots"
      v-slot:[name]="props">
      <slot
        :name="name"
        v-bind="props" />
    </template>
  </v-dialog>
</template>
<script>
import Vue from 'vue';
import store from '@/store';
/**
 * Dialog Store for handle dialog showing/hiding/deleting
 * Can record multiple dialogues. And close them one by one.
 */
const dialogStore = {
  namespaced: true,
  state: () => ({
    dialogs: {
    },
  }),
  mutations: {
    SHOW_DIALOG(state, dialog) {
      Vue.set(state.dialogs, dialog, true);
    },
    HIDE_DIALOG(state, dialog) {
      if (state.dialogs[dialog]) {
        Vue.set(state.dialogs, dialog, false);
      }
    },
    DELETE_DIALOG(state, dialog) {
      Vue.delete(state.dialogs, dialog);
    },
  },
};
if (!store.hasModule('dialog')) {
  store.registerModule('dialog', dialogStore);
}
function onpopstate(e) {
  if (e.state && e.state.hasDialog) {
    store.commit('dialog/HIDE_DIALOG', e.state.dialog);
  }
}
if (window.onpopstate !== onpopstate) {
  window.onpopstate = onpopstate;
}
export default {
  name: 'ProxyDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * Data of dialog.
   * @dialogName {string} - Unique dialog id.
   * @dialog {boolean} - Auxiliary variable to control the state of the dialog.
   */
  data() {
    return {
      dialogName: `_${Math.random().toString(36).substr(2, 9)}`,
      dialog: false,
    };
  },
  /**
   * Computed of dialog.
   * @dialogState {string} - Unique dialog id;
   * @isMobile {boolean} - handles is it mobile device;
   * @syncValue {boolean} - makes update of value;
   * @backButtonToCloseModalWindows {boolean} - returns status of feature flag;
   */
  computed: {
    syncValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('update:value', newValue);
      },
    },
    dialogState() {
      return this.$store.state.dialog.dialogs[this.dialogName];
    },
  },
  /**
   * Watch of dialog.
   * @value {boolean} - An auxiliary variable for controlling the state of a dialog,
   * so as not to be tied to the state of each specific dialog.
   * @dialogState {boolean} - Keeps track of the state of the dialog variable in the state.
   * @dialog {boolean} -Keeps track of the auxiliary change and, using native methods, makes a "jump" through history,
   * followed by removal from the dialog state pane. I didn't use vue-router here because
   * we need to use guards for that, but they in turn trigger even at replay,
   * which makes it impossible to know when to delete it.
   */
  watch: {
    value() {
      this.dialog = this.value;
    },
    dialogState(newValue) {
      this.dialog = newValue ? true : false;
    },
    async dialog(newValue) {
      await this.$nextTick();
      const history = window.history.state || {
      };
      if (newValue) {
        history.hasDialog = true;
        history.dialog = this.dialogName;
        window.history.replaceState(history, '');
        window.history.pushState(null, '');
        this.onShowDialog();
      } else {
        this.onCloseDialog();
        this.syncValue = false;
        const { disableProxyDialog = false } = this.$route.params ?? {
        };
        if (!disableProxyDialog && !('hasDialog' in history && history.dialog === this.dialogName)) {
          window.history.go(-1);
        }
        history.hasDialog = false;
        history.dialog = '';
        window.history.replaceState(history, '');
      }
    },
  },
  /**
   * Methods of dialog.
   * @onShowDialog {method} - Showing dialog.
   * @onCloseDialog {method} - Closing dialog.
   */
  methods: {
    onShowDialog() {
      this.$store.commit(
        'dialog/SHOW_DIALOG',
        this.dialogName
      );
    },
    onCloseDialog() {
      this.$store.commit(
        'dialog/HIDE_DIALOG',
        this.dialogName
      );
    },
  },
};
</script>
