import { getDefaultState } from './state';
export const mutations = {
  setCompareVersion(state, payload) {
    state.compareVersion = payload;
  },
  setCompareColumns(state, payload) {
    state.compareColumns = payload;
  },
  setComparingColumns(state, payload) {
    state.comparingColumns = payload;
  },
  setCompareListWithVersions(state, payload) {
    state.compareListWithVersions = payload;
  },
  setCompareReference(state, payload) {
    state.compareReference = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setModifiedRow(state, payload) {
    state.modifiedRow = payload;
  },
  clearModifiedRow(state) {
    state.modifiedRow = null;
  },
};
