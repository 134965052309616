import { Storage } from 'aws-amplify';
import { IS_STORE_ROOT } from '@/constants';
export const actions = {
  async parseFile({ getters, dispatch, rootGetters }, { key, config }) {
    try {
      if (!rootGetters['FeatureFlags/useIncreaseCaching'] || !getters.getFileByKey(key)) {
        await dispatch('cacheFile', {
          key, config,
        });
      }
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async cacheFile({ commit, state, dispatch }, { key, config }) {
    try {
      const url = await Storage.get(
        key, {
          ...config,
        }
      );
      commit('setFiles', [...state.cachedFiles, {
        key,
        url,
        createdAt: Date.now(),
      }], );
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async removeFileIfTimeIsNotLeft({ state, dispatch, getters }) {
    const keys = state.cachedFiles.reduce((acc = [], file) => {
      if (!getters.isActiveFile(file)) {
        acc.push(file?.key);
      }
      return acc;
    }, []);
    if (keys?.length) {
      dispatch('removeFilesFromCache', {
        keys,
      });
    }
  },
  removeFilesFromCache({ commit, state, dispatch }, { keys, removeKey = 'key' }) {
    if (!['key', 'url'].includes(removeKey)) {
      console.error('wrong key provided');
      return;
    }
    try {
      keys.forEach(key => {
        commit('setFiles', state.cachedFiles.filter(file => !file[removeKey].includes(key)));
      });
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
};
