import getTypeByHeaderName from '@/utils/getTypeByHeaderName';
import {
  setCollectionSchema,
  setCollectionSchemaMapping,
  setSelectedCollectionView,
  setMappedDropdownsOfView,
} from '@/utils/collectionSchemaAndViews';
import {
  TYPE_EDIT,
  TYPE_COMPARE,
  TYPE_CREATE,
  TYPE_UPDATE_REFERENCED_PRODUCT,
  TYPE_SAVE_AS_NEW,
  TYPE_SPEC_SHEET,
  TYPE_READONLY,
  GROUP_FOLLOWED,
  GROUP_SHARED,
  ALLOW_TYPES_TO_SUGGEST,
  PUBLISH,
  UNLISTED_PUBLISH,
  ROUTES_FOR_PRODUCT_MODAL_V1,
  ROUTES_FOR_PRODUCT_MODAL_V2,
} from '@/constants';
import {
  accessHelper, hasEditorAccess,
} from '@/utils';
import { LOCKED_TO_WORKSPACE_SUGGEST_REASON } from '@/constants/userPermissions';
import SortRowsFactory from '@/utils/sorting/SortRowsFactory';
import SearchProducts from '@/utils/searching/SearchProducts';
import { SPECIFIC_MATCHES_OF_PRODUCT_VALUES_IN_THE_DATABASE as MATCHES_OF_PRODUCT } from '@/constants/searching/searchingFilters';
import router from '@/router';
import { SORTABLE_FIELDS_COLLECTIONS } from '@/constants/scheduleViews';
import productHeaders from '@/constants/productHeaders';
import { COLLECTION_SORT_ITEMS } from '@/constants/sortable';
export const getters = {
  getListOfCollections(state) {
    const { isWorkspacePage } = router.currentRoute.meta;
    if (isWorkspacePage) {
      return state.listOfCollectionsV3_1;
    }
    return state.listOfCollectionsV3;
  },
  getCollectionSuggestionsList(state) {
    return state.collectionSuggestionsList;
  },
  getFollowedCollectionsList(state) {
    return state.followedCollectionsList;
  },
  getPublishedCollectionsList(state) {
    return state.publishedCollectionsList;
  },
  collectionsSchemaMapping(state) {
    const { schema = [] } = state?.collectionsSchema ?? {
    };
    return setCollectionSchemaMapping(schema);
  },
  collectionsSchema(state, getters) {
    const { collectionsSchema } = state;
    const { selectedCollectionView, collectionsSchemaMapping } = getters;
    return setCollectionSchema({
      collectionsSchema,
      selectedCollectionView,
      collectionsSchemaMapping,
    });
  },
  isUnlistedPublishActiveCollection(state) {
    return state.currentCollectionSharingOption?.type === UNLISTED_PUBLISH;
  },
  isPublishedActiveCollection(state) {
    return state.currentCollectionSharingOption?.type === PUBLISH;
  },
  selectedControlProducts(state) {
    const { currentCollectionInfo: products, selectedControlProductsIds } = state;
    return products.filter(e => selectedControlProductsIds.includes(e.id));
  },
  isGroupShared(state, getters, rootState) {
    return rootState.activeHeader?.collectionGroup === GROUP_SHARED;
  },
  checkIfMyCollection(state, getters, rootState, rootGetters) {
    return rootGetters.userId === rootState.activeHeader?.creator?.id;
  },
  isGroupFollowed(state, getters) {
    const { getCurrentCollection } = getters;
    return getCurrentCollection?.collectionGroup === GROUP_FOLLOWED;
  },
  selectedCollectionView(state) {
    const { collectionsViews: views } = state;
    return setSelectedCollectionView(views);
  },
  columnsSizeMapping(state, getters) {
    const { columnsSize = [] } = getters.selectedCollectionView ?? {
    };
    return columnsSize.reduce((acc = {
    }, column) => ({
      ...acc,
      [column?.id]: column,
    }), {
    });
  },
  collectionTableHeaders(state, getters) {
    const { columnsSizeMapping, collectionsSchemaMapping, selectedCollectionView } = getters;
    const { columnOrder = [] } = getters?.selectedCollectionView ?? [];
    const baseHeaders = [{
      text: '',
      value: 'order',
      sortable: false,
      align: 'center',
      column: {
        type: getTypeByHeaderName({
          headerObj: {
            name: 'order',
          },
        }),
      },
    }];
    return columnOrder.reduce(( acc = [], column) => {
      if (state.sharedLink?.mode === 'restricted'
        && !getters.fieldsToShow?.includes(column) ||
        getters.allowedFieldsToShow && !getters.allowedFieldsToShow.includes(column)) {
        return acc;
      }
      const headerObj = collectionsSchemaMapping && collectionsSchemaMapping[column];
      const { id = '' } = headerObj || {
      };
      let columnSize = columnsSizeMapping[id] || {
        id,
        size: 180,
        minSize: 180,
        unit: 'px',
        found: false, // flag to know that value doesn't present
      };
      //for supporting old columns size on dev
      if (process.env.NODE_ENV == 'development') {
        if (column == 'Image') {
          columnSize = {
            ...columnSize,
            size: 270,
            minSize: 270,
          };
        }
        if (column == productHeaders.PRODUCT_TYPE) {
          columnSize = {
            ...columnSize,
            size: columnSize.size < 180 ? 180 : columnSize.size,
            minSize: 180,
          };
        }
      }
      const colType = getTypeByHeaderName({
        headerObj,
        selectedView: selectedCollectionView,
      });
      const widthStyling = {
        columnSize,
        cellClass: 'v-data-table-cell__base',
        isAllowedToResize: column !== 'order' && !['ARRAY_AIRTABLE_IMAGE', 'TYPE_COL'].includes(colType),
      };
      const rightAlignment = [
        'Budget Price',
        'Total Cost',
        'Budget Price - Project Override',
        'Library Budget Price',
      ];
      return [
        ...acc, {
          text: column,
          value: column,
          sortable: false,
          isCustomSortable: SORTABLE_FIELDS_COLLECTIONS.includes(column),
          align: rightAlignment.includes(column) ? 'right' : 'left',
          column: {
            type: colType,
            id,
            name: column,
          },
          id,
          ...widthStyling,
        },
      ];
    }, [...baseHeaders]);
  },
  selectedColumnOrder(state, getters) {
    return getters.selectedCollectionView?.columnOrder || [];
  },
  selectedSortingData(state, getters) {
    return getters.selectedCollectionView?.sortingData;
  },
  selectedSchema(state, getters) {
    return getters.collectionsSchema?.schema || [];
  },
  dropdownsCollection(state, getters) {
    return getters.selectedCollectionView?.dropdowns;
  },
  dropdownsCollectionAsObj(state, getters) {
    const dropdowns = getters.dropdownsCollection;
    return setMappedDropdownsOfView(dropdowns);
  },
  updatedProduct(state) {
    return state.updateProduct;
  },
  isAddModalVariant(state) {
    return state.productModalVariant === TYPE_CREATE;
  },
  isCompareModalVariant(state) {
    return state.productModalVariant === TYPE_COMPARE;
  },
  isEditModalVariant(state) {
    return state.productModalVariant === TYPE_EDIT;
  },
  isReadOnlyModalVariant(state) {
    return state.productModalVariant === TYPE_READONLY;
  },
  isSpecSheetModalVariant(state) {
    return state.productModalVariant === TYPE_SPEC_SHEET;
  },
  isSaveAsNewVariant(state) {
    return state.productModalVariant === TYPE_SAVE_AS_NEW;
  },
  isDisallowClosingProductDialogAfterUpdate(state) {
    const MODAL_TYPES_TO_CLOSING = [TYPE_EDIT];
    return MODAL_TYPES_TO_CLOSING.includes(state.productModalVariant);
  },
  isUpdateReferencedProduct(state) {
    return state.productModalVariant == TYPE_UPDATE_REFERENCED_PRODUCT;
  },
  allowedFieldsToShow(state) {
    const { collectionsSchema } = state;
    const fieldsNameToShow = collectionsSchema?.allowedFields?.map(fieldId =>
      collectionsSchema?.schema.find(item => item.id === fieldId)?.name);
    return fieldsNameToShow;
  },
  fieldsToShow(state, getters) {
    return state.sharedLink?.fieldsToShow.map(fieldId =>
      getters.collectionsSchema?.schema.find(item => item.id === fieldId)?.value);
  },
  selectedControl: (state) => (id) => {
    const { selectedControlProductsIds } = state;
    return selectedControlProductsIds.includes(id);
  },
  isExistSelectedControlProductsIds(state) {
    const { selectedControlProductsIds } = state;
    return Boolean(selectedControlProductsIds.length);
  },
  collectionGroupsForFollowProduct(state, getters, rootState, rootGetters) {
    const { getListOfCollections } = getters;
    const groups = ['private', 'shared', 'published'];
    if (!rootGetters['Workspace/isPersonalWorkspace']) {
      groups.push('workspace');
    }
    return getListOfCollections.filter(item => groups.includes(item.group));
  },
  collectionsWithPermissions: state => id => {
    const {
      privateCollectionsListToAutocomplete,
      sharedCollectionsListToAutocomplete,
      publishedCollectionsListToAutocomplete,
    } = state;
    const sharedCollections = sharedCollectionsListToAutocomplete.filter(collection => hasEditorAccess(collection.type));
    const privateCollection = privateCollectionsListToAutocomplete.filter(collection => collection.id !== id);
    const collectionsForFollow = [...privateCollection, ...publishedCollectionsListToAutocomplete, ...sharedCollections];
    return collectionsForFollow;
  },
  collectionsForDuplicate: state => {
    const {
      privateCollectionsListToAutocomplete,
      sharedCollectionsListToAutocomplete,
      publishedCollectionsListToAutocomplete,
    } = state;
    const sharedCollections = sharedCollectionsListToAutocomplete.filter(collection => hasEditorAccess(collection.type));
    return [
      ...privateCollectionsListToAutocomplete,
      ...sharedCollections,
      ...publishedCollectionsListToAutocomplete,
    ];
  },
  getPrivateCollectionsListLength(state) {
    return state.privateCollectionsList.length;
  },
  getGroupInfo: (state, getters) => groupName => getters.getListOfCollections.find(el => el.group === groupName),
  getCollectionListByGroupName: (state, getters) => groupName => {
    const { list } = getters.getGroupInfo(groupName);
    return getters.collectionList(list);
  },
  collectionList: (state) => listName => {
    return state[listName];
  },
  getSaveAsNewCollections: state => [
    ...state.projectSourceLibraryCollections,
    ...state.userCollections,
  ],
  findSaveAsNewCollectionsById: (state, getters) => colId => getters.getSaveAsNewCollections.find(({ id }) => id == colId),
  getAppropriateCollectionsGroups(state, getters, rootState, rootGetters) {
    const { getListOfCollections } = getters;
    const isPersonalWorkspace = rootGetters['Workspace/isPersonalWorkspace'];
    const { activeWorkspaceId } = rootState.Workspace;
    const groups = getListOfCollections.filter(({ workspaceForRendering: workspace }) =>
      !workspace || workspace === activeWorkspaceId || (!isPersonalWorkspace && workspace === 'custom'));
    return groups;
  },
  getAppropriateCollectionsGroupsToAutocomplete(state, getters, rootState, rootGetters) {
    const { listOfCollectionsToAutocomplete } = state;
    const isPersonalWorkspace = rootGetters['Workspace/isPersonalWorkspace'];
    const { activeWorkspaceId } = rootState.Workspace;
    const groups = listOfCollectionsToAutocomplete.filter(({ workspaceForRendering: workspace }) =>
      !workspace || workspace === activeWorkspaceId || (!isPersonalWorkspace && workspace === 'custom'));
    return groups;
  },
  getCommunityCollectionGroup: (state, getters) => {
    return getters.getListOfCollections.find(({ name }) => name == 'Published Collections');
  },
  getAppropriateCollectionsGroupsByCriteria: (state, getters) => ({ routeName }) => {
    const { getAppropriateCollectionsGroups } = getters;
    return getAppropriateCollectionsGroups.filter(({ routeName: route }) => {
      if (Array.isArray(routeName)) {
        return routeName.includes(route);
      }
      return route === routeName;
    });
  },
  isActiveSuggestionCollection(state, getters, rootState) {
    return rootState.activeHeader?.suggested;
  },
  currentCollectionInfoLength(state) {
    const { currentCollectionInfo: arr = [] } = state;
    return arr.length;
  },
  sortedCurrentCollectionInfo(state, getters, rootState, rootGetters) {
    const sortingData = rootGetters['Collections/selectedSortingData'];
    if (!sortingData) {
      return getters.searchedCurrentCollectionInfo;
    }
    const sortedData = new SortRowsFactory({
      sortOrder: sortingData.sortOrder,
      sortBy: sortingData.sortBy,
      items: getters.searchedCurrentCollectionInfo,
    }).sort();
    return sortedData;
  },
  searchedCurrentCollectionInfo(state, getters) {
    const { isAppliedSearchingProducs } = getters;
    const { keysToSearchingProducts: keysObj } = state;
    if (!isAppliedSearchingProducs) {
      return state.currentCollectionInfo;
    }
    return new SearchProducts({
      items: state.currentCollectionInfo,
      keys: Object.values(MATCHES_OF_PRODUCT),
    }).search({
      keysObj,
    });
  },
  isAppliedSearchingProducs(state) {
    const { keysToSearchingProducts: obj } = state;
    if (!obj) return false;
    return !!Object.keys(obj).length;
  },
  showSuggestButton(state) {
    const { currentCollectionSharingOption: value } = state;
    const allowedToSuggest = ALLOW_TYPES_TO_SUGGEST.includes(value?.type);
    return accessHelper(allowedToSuggest, !allowedToSuggest && LOCKED_TO_WORKSPACE_SUGGEST_REASON);
  },
  getFullProductById: (state) => (id) => {
    return state.currentCollectionInfo.find(e => e.id === id);
  },
  getSuggestionErrorSnackbarDialogVal(state) {
    return state.suggestionErrorSnackbar?.open;
  },
  getSuggestionErrorSnackbarData(state) {
    return state.suggestionErrorSnackbar?.data;
  },
  getCurrentCollection(state, getters, rootState) {
    const { activeHeader } = rootState;
    const { activeCollection } = state;
    return activeHeader?.libraryId ? activeHeader : activeCollection;
  },
  isUserMemberCurrentCollection(state, getters, rootState, rootGetters) {
    const { getCurrentCollection: item } = getters;
    const { userId } = rootGetters;
    return item.members?.some(e => e.id === userId);
  },
  typeSharingOption(state) {
    return state.currentCollectionSharingOption?.type;
  },
  getRoutesForProductModal(state, getters, rootState, rootGetters) {
    return rootGetters['FeatureFlags/useAddProductRecordLinkInQuickView']
      ? ROUTES_FOR_PRODUCT_MODAL_V2
      : ROUTES_FOR_PRODUCT_MODAL_V1;
  },
  getItemFromCollectionSearch: state => criteria => {
    const { itemsFromCollectionSearch: arr } = state;
    if (!criteria) return null;
    return arr.find(({ criteria: name } = {
    }) => name === criteria);
  },
  getSortedItem(state, getters, rootState, rootGetters) {
    const mode = rootGetters['UserProfile/collectionSortingMode'];
    const isAscending = rootGetters['UserProfile/collectionSortingModeAscending'];
    return COLLECTION_SORT_ITEMS.find(item => {
      const { sortingMode, ascending = true } = item || {
      };
      return sortingMode == mode && ascending === isAscending;
    }) || COLLECTION_SORT_ITEMS[0];
  },
  getProductModalSpecSheetUrl(state) {
    return state.productModalSpecSheetUrl;
  },
};

