import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
const STARRED_PROJECT_LIST = `
  workspaceId
  id
  name
  sortingMode
  tags
`;
const STARRED_PROJECT_LIST_CONNECTION = `
  items {
    ${STARRED_PROJECT_LIST}
  }
  nextToken
`;
export default {
  getStarredProjectLists(variables) {
    return new requestBuilder({
      requestName: 'getStarredProjectLists',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        workspaceId: 'String!',
        nextToken: 'String',
        limit: 'Int',
      },
      response: STARRED_PROJECT_LIST_CONNECTION,
      variables,
    }).processRequest();
  },
  makeProjectListStarred(variables) {
    return new requestBuilder({
      requestName: 'makeProjectListStarred',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        tags: '[String]!',
        sortingMode: 'SortingMode!',
      },
      response: STARRED_PROJECT_LIST,
      variables,
    }).processRequest();
  },
  deleteStarredProjectList(variables) {
    return new requestBuilder({
      requestName: 'deleteStarredProjectList',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        starredListId: 'String!',
      },
      response: `id`,
      variables,
    }).processRequest();
  },
  updateStarredProjectList(variables) {
    return new requestBuilder({
      requestName: 'updateStarredProjectList',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        starredListId: 'String!',
        name: 'String',
        sortingMode: 'SortingMode',
      },
      response: STARRED_PROJECT_LIST,
      variables,
    }).processRequest();
  },
};
