<template>
  <v-overlay
    :z-index="zIndex"
    :value="overlay"
    class="v-overview__preview-image">
    <v-icon
      style="position: fixed; top: 24px; right: 48px; z-index: 1000000;"
      @click.stop="clearItems">
      mdi-close
    </v-icon>
    <carousel
      :starting-image="filesForPreviewIndex"
      :images="filesForPreview" />
  </v-overlay>
</template>
<script>
import Carousel from '@/components/App/AppFileImagePreview/AppFileImagePreviewCarousel';
import {
  mapMutations, mapState,
} from 'vuex';
export default {
  name: 'FilePreview',
  components: {
    Carousel,
  },
  data: () => ({
    overlay: false,
    zIndex: 10000,
  }),
  computed: {
    ...mapState(['filesForPreview', 'filesForPreviewIndex']),
  },
  watch: {
    filesForPreview: {
      handler(val) {
        this.overlay = Boolean(val.length);
        this.setImagePreviewOverlay(this.overlay);
        if (this.overlay) {
          document.body.classList.add('is-image-preview');
        } else {
          document.body.classList.remove('is-image-preview');
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(['setImagePreviewOverlay', 'clearFilesForPreview']),
    clearItems() {
      this.clearFilesForPreview();
    },
  },
};
</script>
<style scoped lang="scss">
.v-overlay--active {
  background: rgba(53, 49, 53, 0.95);
  z-index: 9999 !important;
  ::v-deep .v-overlay__content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  ::v-deep .v-overlay__scrim {
    background: none !important;
    opacity: 0 !important;
  }
  &--active {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
