import {
  COLLECTION, PROJECT,
} from '@/constants/cores';
import {
  PUBLISH, UNLISTED_PUBLISH,
} from '@/constants/index';
const COMMENT_RESOURCE_TYPE = {
  COLLECTION: COLLECTION,
  PROJECT: PROJECT,
};
const COMMENT_KIND = {
  GENERAL: 'general',
  ROW: 'row',
  FIELD: 'field',
};
const COMMENT_PRIVACY = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};
const COMMENT_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  DELETED: 'deleted',
};
const COMMENT_TYPE = {
  OFFER: 'offer',
  TEXT: 'text',
  ATTACHMENT: 'attachment',
};
const RESTRICTED_TYPES_FOR_COMMENTS = [UNLISTED_PUBLISH, PUBLISH];
const PROJECTS_COMMENTS_MUTATION = 'setProjectCommentsV2';
const PROJECTS_COMMENTS_COLLABORATORS_MUTATION = 'setProjectCollaboratorsV2';
const NEW_PROJECTS_COMMENTS_ID_MUTATION = 'setNewProjectCommentIdV2';
const COLLECTIONS_COMMENTS_MUTATION = 'setCollectionCommentsV2';
const COLLECTIONS_COMMENTS_COLLABORATORS_MUTATION = 'setCollectionCollaboratorsV2';
const NEW_COLLECTIONS_COMMENTS_ID_MUTATION = 'setNewCollectionCommentIdV2';
const COMMENTS_RESOURCES = {
  [COMMENT_RESOURCE_TYPE.PROJECT]: {
    list: 'projectCommentsListV2',
    collaboratorsList: 'projectCollaboratorsListV2',
    slantsList: 'projectSlants',
    hideResolvedToggle: 'hideResolvedProjectCommentsV2',
    totalUnreadCommentsLength: 'totalProjectsUnreadCommentsLength',
    setMutation: PROJECTS_COMMENTS_MUTATION,
    mutationCollaborators: PROJECTS_COMMENTS_COLLABORATORS_MUTATION,
    mutationNewComment: NEW_PROJECTS_COMMENTS_ID_MUTATION,
    slantsMutation: 'setProjectSlants',
    setResolvedToggleMutation: 'setProjectUserHideResolvedToggleV2',
  },
  [COMMENT_RESOURCE_TYPE.COLLECTION]: {
    list: 'collectionCommentsListV2',
    collaboratorsList: 'projectCollaboratorsListV2',
    slantsList: 'collectionSlants',
    hideResolvedToggle: 'hideResolvedCollectionCommentsV2',
    totalUnreadCommentsLength: 'totalCollectionsUnreadCommentsLength',
    setMutation: COLLECTIONS_COMMENTS_MUTATION,
    mutationCollaborators: COLLECTIONS_COMMENTS_COLLABORATORS_MUTATION,
    mutationNewComment: NEW_COLLECTIONS_COMMENTS_ID_MUTATION,
    slantsMutation: 'setCollectionsSlants',
    setResolvedToggleMutation: 'setCollectionUserHideResolvedToggleV2',
  },
};

// These are for the 'fake' initial comment.
// See the assistantStatus state in store/Projects for state
// of assistant being invited + id.
const SOURCERY_ASSISTANT_ID = 'sourceryAssistantId';
const SOURCERY_ASSISTANT_COMMENT_ID = 'sourceryAssistantInitComment';
const SOURCERY_ASSISTANT_EMAIL = 'service@gosourcery.com';

const SOURCERY_ASSISTANT_USER = {
  id: SOURCERY_ASSISTANT_ID,
  name: 'Sourcery Assistant',
  email: SOURCERY_ASSISTANT_EMAIL,
};
const SOURCERY_ASSISTANT_INITIAL_COMMENT = {
  commentType: 'text',
  id: SOURCERY_ASSISTANT_COMMENT_ID,
  message: 'You have now invited the Sourcery Assistant team into your project! \
            To ask a question, simply add @assistant to any new comment',
  privacy: 'public',
  creator: SOURCERY_ASSISTANT_USER,
  mentioned: [SOURCERY_ASSISTANT_USER],
};

export {
  COMMENT_RESOURCE_TYPE,
  COMMENT_KIND,
  COMMENT_PRIVACY,
  COMMENT_STATUS,
  COMMENT_TYPE,
  RESTRICTED_TYPES_FOR_COMMENTS,
  PROJECTS_COMMENTS_MUTATION,
  PROJECTS_COMMENTS_COLLABORATORS_MUTATION,
  NEW_PROJECTS_COMMENTS_ID_MUTATION,
  COLLECTIONS_COMMENTS_MUTATION,
  COLLECTIONS_COMMENTS_COLLABORATORS_MUTATION,
  NEW_COLLECTIONS_COMMENTS_ID_MUTATION,
  COMMENTS_RESOURCES,

  SOURCERY_ASSISTANT_ID,
  SOURCERY_ASSISTANT_USER,
  SOURCERY_ASSISTANT_EMAIL,
  SOURCERY_ASSISTANT_COMMENT_ID,
  SOURCERY_ASSISTANT_INITIAL_COMMENT,
};
