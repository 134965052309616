import Subscriptions from '@/services/graphql/subscriptions';
import { baseSubscription } from '@/utils/subscriptionHandler';
const subscribeForComments = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: Subscriptions.subscribeComments,
  unsubscribe: false,
},);
const subscribeForAttachments = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: Subscriptions.subscribeCommentAttachment,
});
const subscribeSlantDocument = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: Subscriptions.subscribeSlantDocument,
  unsubscribe: false,
});
const subscribeSlantDocumentsList = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: Subscriptions.subscribeSlantDocumentsList,
  unsubscribe: false,
});
const subscribeForTotalUnreadCounter = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: Subscriptions.subscribeTotalUnreadCounter,
  unsubscribe: false,
});
export {
  subscribeForAttachments, subscribeForComments,
  subscribeSlantDocument, subscribeForTotalUnreadCounter,
  subscribeSlantDocumentsList,
};
