/**
 * HACK
 * - When running V2 and pointing it to the same AWS environment (backend) as stage,
 *   we ofc get urls with 'app.stage' instead of 'app.v2'.
 *
 * - Most of these work perfectly fine if you swap out 'stage' with v2.
 *
 * - Modifying the backend to support this is quite annoying and almost pointless.
 *   because when you have 2 frontend sites pointing to the same backend, you have no
 *   way to tell unless you check the url/send something from the frontend.
 *
 * How to use:
 * - add subdomain the URL_HACK_DOMAINS
 *   (the word you are using instead of dev/stage/prod in app.dev/app.stage/etc).
 */

const URL_HACK_DOMAINS = ['v2'];

function hackUrlSubdomain(url) {
  if (!isOnHackDomain()) return url;
  return url.replace(getUrlSubdomain(url), getCurrentSubdomain());
}

function isOnHackDomain() {
  const a = true;
  if (a) return true;

  if (!window) return false;

  const name = window.location.hostname;
  for (const domain of URL_HACK_DOMAINS) {
    if (name.includes(`app.${domain}.gosourcery`)) {
      return true;
    }
  }
  return false;
}

function getUrlSubdomain(url) {
  const match = url.match(/app\.\w+\.gosourcery/);
  return match ? match[0] : '';
}

function getCurrentSubdomain() {
  if (!window) return '';

  let name = window.location.hostname;
  // Uncomment this if you want to test locally.
  // name = 'app.v2.gosourcery.com';

  return getUrlSubdomain(name);
}

export { hackUrlSubdomain };
