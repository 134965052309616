const FILE_IES = 'ies';
const FILE_ZIP = 'zip';
const FILE_XLSX = 'xlsx';
const FILE_DOCX = 'docx';
const IMAGE = 'image';
const PDF = 'application/pdf';
const IMAGE_JPEG = 'jpeg';
const IMAGE_JPG = 'jpg';
const CONTENT_TYPE_IES = `application/${FILE_IES}`;
const CONTENT_TYPE_ZIP = `application/${FILE_ZIP}`;
const CONTENT_TYPE_XLSX = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
const CONTENT_TYPE_DOCX = `application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
const CONTENT_TYPE_JPEG = 'image/jpeg';
const CONTENT_TYPE_MAP = {
  pdf: PDF,
  [FILE_ZIP]: CONTENT_TYPE_ZIP,
  [FILE_IES]: CONTENT_TYPE_IES,
  [FILE_XLSX]: CONTENT_TYPE_XLSX,
  [FILE_DOCX]: CONTENT_TYPE_DOCX,
  [IMAGE_JPEG]: CONTENT_TYPE_JPEG,
  [IMAGE_JPG]: CONTENT_TYPE_JPEG,
};
export default {
  FILE_IES,
  CONTENT_TYPE_IES,
  IMAGE,
  PDF,
  FILE_ZIP,
  CONTENT_TYPE_ZIP,
  FILE_XLSX,
  CONTENT_TYPE_XLSX,
  CONTENT_TYPE_MAP,
  FILE_DOCX,
  CONTENT_TYPE_DOCX,
};
