export default {
  USERS_LIMIT_ERROR(limit, limitOfSharedUsers) {
    return `You need remove ${limit * -1} users to share collection. Your limit to share is ${limitOfSharedUsers}. To share collection for more than ${limitOfSharedUsers} users you need to`;
  },
  WRONG_EMAILS_FOR_SHARING(emails) {
    let wrongEmails = emails;
    if (Array.isArray(emails)) {
      wrongEmails = emails.join(' ');
    }
    return `Sorry, could not understand the email addresses starting at ${wrongEmails}`;
  },
  SHARE_LIMIT_ERROR(extraUsers, usedLicenses, licenses) {
    return `
          You've reached the limit of accessible licenses.
          You need to remove ${Math.abs(extraUsers)} users to invite new
          workspace members. Your license limit is ${usedLicenses}/${licenses}.
        `;
  },
  ARRAY_ERRORS_ACCESS: ["You don't have permission for this collection.", 'Access denied.'],
  ENTER_REQUIRED_VALUE: 'Please enter required value',
  ERROR_VALID_URL: 'Must be a valid URL',
  SUGGEST_ACCESS: 'This collection became private. You can ignore the suggestion or keep it.',
  ERROR_WITH_PASSWORD: 'Incorrect password',
  ERROR_WITH_USERNAME_OR_PASSWORD: 'Incorrect username or password.',
  ERROR_VERSION_CONFLICT: 'Version Conflict',
};
