// Sizes from @/constants/thumbnails

/**
 * Image caching uses IndexedDB because it's a lot of data to just have in memory.
 * - The state here shows existence in indexedDB
 */
export const getDefaultState = () => {
  return {
    /**
     * Maps of images (key) to bool that determines if image exists in cache (indexedDB)
     * for 3 variations of image:
     * - 3 thumbnail sizes
     * - Original image
     *
     * [imageKey<string>] : {
     *    [EXTRA_TALL]: <bool>
     *    [TALL]: <bool>
     *    [MEDIUM]: <bool>
     *    [ORIGINAL]: <bool>
     * }
     */
    imageCache: {
    },
    /**
     * Map of images (key) to promises for different variations of the image:
     * - 3 thumbnail sizes
     * - Original image
     *
     *    [imageKey<string>] : {
     *      [EXTRA_TALL]: <Promise>
     *      [TALL]: <Promise>
     *      [MEDIUM]: <Promise>
     *      [ORIGINAL]: <Promise>
     *    }
     * }
     */
    fetchPromises: {
    },
  };
};

export const state = getDefaultState();