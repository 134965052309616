export const mutations = {
  setViewInfo(state, payload) {
    state.viewInfo = payload;
  },
  setListDeletedRows(state, payload) {
    state.listDeletedRows = payload;
  },
  setCheckboxSelectedRows(state, payload) {
    state.checkboxSelectedRows = payload;
  },
  setCellsVersions(state, payload) {
    state.cellsVersions = payload;
  },
};
