import {
  isObject, isEmpty,
} from 'lodash';
/**
 * @param item
 * @param searchBy
 * @param replacedItem
 * @returns {(*&{item})|*}
 */
const findAndReplace = ({ item, searchBy, replacedItem }) => {
  if (Array.isArray(item)) {
    return findAndReplaceArray({
      item,
      searchBy,
      replacedItem,
    });
  }
  if (!isObject(item)) {
    throw new Error('Item is not an array or object type');
  }
  return mergeObject(item, replacedItem);
};
/**
 * @param item
 * @param searchBy
 * @param replacedItem
 * @returns {*}
 */
const findAndReplaceArray = ({ item, searchBy, replacedItem }) => {
  if (!Array.isArray(item)) {
    throw new Error('Sorry item is not an array');
  }
  if (!searchBy || isEmpty(searchBy)) {
    throw new Error('Please provide id');
  }
  const key = Object.keys(searchBy)[0];
  const value = Object.values(searchBy)[0];
  return item.map(i => {
    if (isObject(searchBy)) {
      return i[key] == value
        ? mergeObject(i, replacedItem)
        : i;
    }
    return i;
  });
};
/**
 * @param item
 * @param replacedItem
 * @returns {*&{item}}
 */
const mergeObject = (item, replacedItem) => {
  return {
    ...item,
    ...replacedItem,
  };
};
export {
  findAndReplaceArray,
  findAndReplace,
  mergeObject,
};
