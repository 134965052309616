export const mutations = {
  openExportScheduleSnackbar(state, {
    title,
    text,
    color,
    timeout = -1,
    dataTest,
  }) {
    state.snackbar = {
      timeout,
      isSnackBarShown: true,
      title,
      text,
      color,
      dataTest,
    };
  },
  closeSnackBar(state) {
    state.snackbar = {
      ...state.snackbar,
      isSnackBarShown: false,
      dataTest: null,
      title: null,
      text: '',
    };
  },
  setExportedFileForPreview(state, payload) {
    state.exportedFileForPreview = payload;
  },
  setListOfFilesForSubscriptions(state, payload) {
    state.listOfFilesForSubscriptions = payload;
  },
  clearListOfFilesForSubscriptions(state) {
    state.listOfFilesForSubscriptions = [];
  },
  setListOfFilesToNotify(state, payload) {
    state.listOfFilesToNotify = payload;
  },
  clearListOfFilesToNotify(state) {
    state.listOfFilesToNotify = [];
  },
};
