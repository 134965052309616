export const getters = {
  getDownloadedFileName: (state) => {
    const { listOfFilesForSubscriptions: list, exportedFileForPreview } = state;
    const { downloadFileName = null } = exportedFileForPreview || {
    };
    if (downloadFileName) {
      return downloadFileName;
    }
    const file = list.find(sub => sub.pdfName === exportedFileForPreview?.pdfName);
    return file?.downloadPdfName;
  },
};
