const PROJECT = 'project';
const COLLECTION = 'collection';
const LIBRARY = 'library';
const WORKSPACE = 'workspace';
const WS_PAGE = 'wsPage';
const MAX_CACHE_AGE = '604800';
const cacheControl = `max-age=${MAX_CACHE_AGE}`;
const WORKSPACE_PAGE = 'workspace page';
const UPLOAD_STATUSES = {
  FAILED: 'failed',
  PROCESSING: 'processing',
  FINISHED: 'finished',
};
const GRAPHQL_TYPES = {
  QUERY: 'query',
  SUBSCRIPTION: 'subscription',
  MUTATION: 'mutation',
};
const PROMISE_STATUSES = {
  REJECTED: 'rejected',
  FULFILLED: 'fulfilled',
};
const INPUT = 'input';
const GOOGLE_MAP = 'google map';
export {
  PROJECT,
  COLLECTION,
  LIBRARY,
  WORKSPACE,
  GRAPHQL_TYPES,
  cacheControl,
  MAX_CACHE_AGE,
  PROMISE_STATUSES,
  UPLOAD_STATUSES,
  INPUT,
  GOOGLE_MAP,
  WORKSPACE_PAGE,
  WS_PAGE,
};
