import FeatureFlags from '@/services/graphql/featureFlags';
export const actions = {
  async getListFeatureFlags({ commit, dispatch }) {
    try {
      const { data } = await FeatureFlags.listFeatureFlags();
      commit('setListFeatureFlags', data.response);
    } catch (err) {
      dispatch('handleError', err, {
        root: true,
      });
    }
  },
};
