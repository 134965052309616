import ExportScheduleApi from '@/services/graphql/exportSchedule';
import Vue from 'vue';
import { IS_STORE_ROOT } from '@/constants';
import router from '@/router';
export const actions = {
  async exportScheduleProject({ dispatch, rootState }, payload) {
    const { selectedViewId } = rootState.ScheduleViews;
    const variables = {
      ...payload,
      viewId: payload.viewId ? payload.viewId : selectedViewId,
    };
    await dispatch('fileExporterInvoke', variables);
  },
  async fileExporterInvoke({ dispatch }, variables) {
    try {
      const { data } = await ExportScheduleApi.fileExporterInvoke(variables);
      const { requestId = null } = data?.response || {
      };
      if (!requestId) {
        dispatch('notifyErrortDuringExporting');
        console.error('No requestId for file export?');
        return;
      }
      dispatch('notifyStartOfExporting', {
        fileFormat: variables.fileFormat,
      });
      dispatch('subscribeNotifyExportFiles', {
        requestId,
      });
      dispatch('addNewFileToSubscriptions', {
        requestId,
        time: Vue.moment().valueOf(),
      });
    } catch (err) {
      dispatch('notifyErrortDuringExporting');
      console.error('Err when exporting', err);
    }
  },
  async subscribeNotifyExportFileData({ dispatch }, payload) {
    try {
      await ExportScheduleApi.subscribeNotifyExportFileData(payload).subscribe({
        next: ({ value }) => {
          const { response } = value.data;
          dispatch('addExportFileToList', response);
        },
      });
    } catch (err) {
      console.log('subscribeNotifyExportFileData err', err);
    }
  },
  async subscribeToListOfFilesToNotify({ dispatch, commit, state }) {
    try {
      const { listOfFilesToNotify } = state;
      Promise.all(listOfFilesToNotify.map(async item => {
        const { projectId, username, time } = item;
        const DIFF_MINUTES = 20;
        const compareDateWithDiff = Vue.moment().diff(time, 'minutes');
        if (compareDateWithDiff < DIFF_MINUTES) {
          await dispatch('subscribeNotifyExportFileData', {
            projectId,
            username,
          });
        } else {
          dispatch('removeFileFromNotifyList', {
            username,
            projectId,
          });
        }
      }));
    } catch (err) {
      commit('clearListOfFilesToNotify');
    }
  },
  async subscribeExportSchedule({ dispatch }, { pdfName } = {
  }) {
    try {
      await ExportScheduleApi.subscribeExportSchedule({
        pdfName,
      }).subscribe({
        next: ({ value }) => {
          const { response } = value.data;
          dispatch('handlerExportReady', response);
        },
      });
    } catch (err) {
      console.log('subs err', err);
    }
  },
  async subscribeNotifyExportFiles({ dispatch }, { requestId } = {
  }) {
    try {
      await ExportScheduleApi.subscribeNotifyExportFiles({
        requestId,
      }).subscribe({
        next: ({ value }) => {
          const { response } = value.data;
          const { errorMessage, ...rest } = response;
          if (errorMessage) {
            dispatch('notifyErrortDuringExporting');
            console.error('Err when exporting', errorMessage);
            return;
          }
          dispatch('handlerExportReady', {
            ...rest,
            pdfName: rest?.fileName,
          });
        },
      });
    } catch (err) {
      console.log('subs err', err);
    }
  },
  async subscribeToListOfExportScheduleFiles({ dispatch, commit, state }) {
    try {
      const { listOfFilesForSubscriptions } = state;
      Promise.all(listOfFilesForSubscriptions.map(async item => {
        const { pdfName, time, requestId } = item;
        const DIFF_MINUTES = 20;
        const compareDateWithDiff = Vue.moment().diff(time, 'minutes');
        if (compareDateWithDiff < DIFF_MINUTES) {
          if (requestId) {
            await dispatch('subscribeNotifyExportFiles', {
              requestId,
            });
          } else {
            await dispatch('subscribeExportSchedule', {
              pdfName,
            });
          }
        } else {
          dispatch('removeFileFromSubscriptions', {
            pdfName,
            requestId,
          });
        }
      }));
    } catch (err) {
      commit('clearListOfFilesForSubscriptions');
    }
  },
  async addExportFileToList({ dispatch }, { pdfName, downloadPdfName, requestId } = {
  }) {
    dispatch('addNewFileToSubscriptions', {
      downloadPdfName,
      pdfName,
      requestId,
      time: Vue.moment().valueOf(),
    });
    await dispatch('subscribeExportSchedule', {
      pdfName,
    });
  },
  addNewFileToSubscriptions({ commit, state }, payload) {
    const { listOfFilesForSubscriptions } = state;
    const newList = [...listOfFilesForSubscriptions, payload];
    commit('setListOfFilesForSubscriptions', newList);
  },
  removeFileFromSubscriptions({ commit, state }, { pdfName, requestId }) {
    const { listOfFilesForSubscriptions } = state;
    const newList = listOfFilesForSubscriptions.filter(file => file.pdfName !== pdfName && file.requestId === requestId);
    commit('setListOfFilesForSubscriptions', newList);
  },
  async addFileToNotifyToList({ dispatch, rootGetters, state }) {
    const { id: projectId } = router.currentRoute.params;
    const { userId: username } = rootGetters;
    const { listOfFilesToNotify } = state;
    const notifySub = listOfFilesToNotify.find(item =>
        item?.username === username && item?.projectId === projectId);
    if (!notifySub) {
      dispatch('addNewFileToNotify', {
        projectId,
        username,
        time: Vue.moment().valueOf(),
      });
      dispatch('subscribeNotifyExportFileData', {
        projectId,
        username,
      });
    } {
      dispatch('updateTimeOfFileToNotify', {
        projectId, username,
      });
    }
  },
  updateTimeOfFileToNotify({ commit, state }, { username, projectId }) {
    const { listOfFilesToNotify } = state;
    let newList = listOfFilesToNotify.map(el =>
      el.username === username && el.projectId === projectId ? {
        ...el,
        time: Vue.moment().valueOf(),
      } : el
    );
    commit('setListOfFilesToNotify', newList);
  },
  removeFileFromNotifyList({ commit, state }, { username, projectId }) {
    const { listOfFilesToNotify } = state;
    const newList = listOfFilesToNotify.filter(file =>
      file.projectId === projectId && file.username === username);
    commit('setListOfFilesToNotify', newList);
  },
  addNewFileToNotify({ commit, state }, payload) {
    const { listOfFilesToNotify } = state;
    const newList = [...listOfFilesToNotify, payload];
    commit('setListOfFilesToNotify', newList);
  },
  notifyStartOfExporting({ commit }, { fileFormat }) {
    commit('openSnackBar', {
      title: `Export to ${fileFormat.toUpperCase()} started`,
      timeout: -1,
      dataTest: 'export_started_popup',
      text: `This process can take up to 3 minutes.
    You will receive an email notification with document download URL.`,
    }, IS_STORE_ROOT);
  },
  notifyErrortDuringExporting({ commit }) {
    commit('openSnackBar', {
      title: 'Something went wrong',
      text: 'Please try to export again.',
    }, IS_STORE_ROOT);
  },
  handlerExportReady({ commit }, payload) {
    commit('closeSnackBar', null, IS_STORE_ROOT);
    commit('setExportedFileForPreview', payload);
    commit('openExportScheduleSnackbar', {
      title: 'Export ready',
      dataTest: 'export_ready_popup',
    });
  },
};
