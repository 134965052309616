import { GRAPHQL_TYPES } from '@/constants/cores';
import requestBuilder from '@/services/graphql/requestBuilder';
import { USER_DATA_NO_BRACKETS as USER_DATA } from '@/services/graphql/fragments';
export default {
  updateUserProfile(variables) {
    return new requestBuilder({
      requestName: 'updateUserProfile',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        username: 'String!',
        attributes: 'UserInput',
      },
      response: USER_DATA,
      variables,
    }).processRequest();
  },
  getUserProfile(variables) {
    return new requestBuilder({
      requestName: 'getUserProfile',
      requestVariables: {
        username: 'String!',
      },
      response: USER_DATA,
      variables,
    }).processRequest();
  },
  getUserProfileByEmail(variables) {
    return new requestBuilder({
      requestName: 'getUserProfileByEmail',
      requestVariables: {
        email: 'String!',
      },
      response: USER_DATA,
      variables,
    }).processRequest();
  },
  getUserCountryCode() {
    return new requestBuilder({
      requestName: 'getUserCountryCode',
      responseWrapperText: 'code',
    }).processRequest();
  },
};
