export default class SearchProductsKeys {
  setFormOfSearchingKeys(data = []) {
    if (!data) return [];
    return data.reduce((acc, { value, alias, specificFieldInDatabase = null, match } = {
    }) => {
      if (!value) return acc;
      const item = acc[alias];
      if (!item) {
        return {
          ...acc,
          [alias]: {
            values: [value],
            field: specificFieldInDatabase || alias,
            match,
          },
        };
      }
      return {
        ...acc,
        [alias]: {
          ...item,
          values: [
            ...item.values,
            value,
          ],
        },
      };
    }, {
    });
  }
}
