import {
  hasViewerAccess, isFollowerV2,
} from '@/utils';
const beforeQuickLinkEnterV2 = async (to, from, next, store) => {
  const id = to.params.id;
  await store.dispatch('Collections/getCollectionSharingOptions', {
    id,
  });
  const { currentCollectionSharingOption } = store.state.Collections;
  await store.dispatch('Collections/getRolesInCurrentCollection', {
    resourceId: id,
    workspaceId: store.state.Workspace.activeWorkspaceId,
  });
  const { roles } = store.state;
  if (!hasViewerAccess(roles) || isFollowerV2(roles)) {
    if (currentCollectionSharingOption.type == 'unlistedPublish') {
      store.commit('setAccessToken', currentCollectionSharingOption.accessToken);
      next();
      return;
    }
    if (currentCollectionSharingOption.type == 'publish') {
      next({
        name: 'community-collection-product-link',
        params: to.params,
      });
      return;
    }
  }
  next();
};
export { beforeQuickLinkEnterV2 };
