import { API } from 'aws-amplify';
const TYPES = `
  {
    type
    string
    integer
    float
  }
`;
const FEATURE_FLAG = `
  {
    name
    enabled
    value ${TYPES}
  }
`;
export default {
  listFeatureFlags() {
    const query = `
    query listFeatureFlags {
      response: listFeatureFlags ${FEATURE_FLAG}
    }
    `;
    return API.graphql({
      query,
      authMode: 'AWS_IAM',
    });
  },
};
