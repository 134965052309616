import { IS_STORE_ROOT } from '@/constants';
import { SCHEDULE_UPDATE_CELL } from '@/constants/undoActions';
export const actions = {
  setUndoActions({ commit, state }, payload) {
    const NUMBER_OF_UNDO_ACTIONS = 10;
    const { undoActions } = state;
    const newUndoActions = [...undoActions, payload];
    const limitedUndoActions = newUndoActions.slice(0, NUMBER_OF_UNDO_ACTIONS);
    commit('setUndoActions', limitedUndoActions);
  },
  async undoHandleAction({ dispatch, commit, getters }) {
    const { lastUndoAction } = getters;
    if (!lastUndoAction) {
      return;
    }
    dispatch('setLastUndoAction', lastUndoAction);
    commit('openSnackBar', {
      text: 'The beginning of the cancellation of the previous action',
    }, IS_STORE_ROOT);
    const actions = Array.isArray(lastUndoAction) ? lastUndoAction : [lastUndoAction];
    dispatch('removeTheLastUndoAction');
    await Promise.all(actions.map(async ({ action, ...payload } = {
    }) => (
      await dispatch(action, {
        ...payload,
        isUndoAction: true,
      }, IS_STORE_ROOT)
    )));
  },
  removeTheLastUndoAction({ commit, state, getters }) {
    const { undoActions } = state;
    const { undoActionsLength } = getters;
    const newUndoActions = undoActions.filter((action, index) => index + 1 !== undoActionsLength);
    commit('setUndoActions', newUndoActions);
  },
  setLastUndoAction({ commit, dispatch }, payload) {
    dispatch('manageSelectedCells', payload);
    commit('setLastUndoAction', payload);
  },
  manageSelectedCells({ dispatch }, lastUndoAction) {
    const actions = Array.isArray(lastUndoAction) ? lastUndoAction : [lastUndoAction];
    actions.reverse().forEach(item => {
      const { row, col, action } = item || {
      };
      if (action === SCHEDULE_UPDATE_CELL) {
        const rowId = row?.rowId;
        dispatch('ProjectDetailsTableSchedule/setSelectedCells', [{
          col,
          rowId,
        }], IS_STORE_ROOT);
      } else {
        dispatch('ProjectDetailsTableSchedule/setSelectedCells', [], IS_STORE_ROOT);
      }
    });
  },
};
