const hasAccessV2 = (userRoles, rolesList) => {
  try {
    if (!userRoles) {
      return false;
    }
    if (!Array.isArray(userRoles)) {
      userRoles = [userRoles];
    }
    if (Array.isArray(rolesList)) {
      rolesList = new Set(rolesList);
    }
    return !!new Set(
      userRoles.filter(element => {
        return rolesList.has(element);
      })
    ).size;
  } catch (e) {
    console.log(e);
  }
};
const getUserRolesListById = ({ userId, users }) => {
  try {
    if (!users || !users.length) {
      return [''];
    }
    const rolesList = getRolesList(users.find(member => member.id == userId));
    if (!rolesList.length) {
      return [];
    }
    return rolesList;
  } catch (e) {
    console.log(e);
  }
};
const getRolesList = (user) => {
  try {
    const [directGrant, libraryGrants] = [user?.directGrant, user?.libraryGrants || []];
    return [directGrant, ...libraryGrants].map(grant => grant?.type).filter(grant => grant);
  } catch (e) {
    console.log(e);
  }
};
const hasAnyRole = (rolesList) => rolesList.some(r => r);
export {
  hasAccessV2, getRolesList, getUserRolesListById, hasAnyRole,
};
