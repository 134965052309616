<template>
  <v-dialog
    v-model="syncValue"
    v-bind="{...$attrs, ...$props}"
    @click:outside="$emit('outside')">
    <template
      v-for="(_, name) in $scopedSlots"
      v-slot:[name]="props">
      <slot
        :name="name"
        v-bind="props" />
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: 'RegularDialog',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    syncValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
  },
};
</script>
