import { API } from 'aws-amplify';
import requestBuilder from '@/services/graphql/requestBuilder';
import { WORKSPACE_PERMISSION } from './fragments';
import { GRAPHQL_TYPES } from '@/constants/cores';
const LIST_PAGES = `
  data {
    createdDate
    id
    lastModified
    pageId
    pageWorkspaceId
    status
    username
    workspaceId
    page {
      isVerified
      id
      logo
      name
      searchText
      status
      description
      workspaceId
    }
    user {
      email
      id
    }
  }
`;
const FOLLOW_PAGE = `
  createdDate
  id
  lastModified
  pageId
  pageWorkspaceId
  status
  username
  workspaceId
  page {
    id
    name
    workspaceId
    status
    description
    logo
    searchText
  }
  user {
    id
    isVerified
    job_title
    location
    logo
    name
    company
    email
  }
`;
const UNFOLLOW_PAGE = `
  createdDate
  id
  lastModified
  pageId
  pageWorkspaceId
  status
  username
  workspaceId
  user {
    email
    id
  }
  page {
    description
    id
    logo
    name
    searchText
    status
    workspaceId
  }
`;
export default {
  listFollowedWorkspacePages(variables) {
    const query = new requestBuilder({
      requestName: 'listFollowedWorkspacePages',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        workspaceId: 'String!',
      },
      response: LIST_PAGES,
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  followWorkspacePage(variables) {
    const query = new requestBuilder({
      requestName: 'followWorkspacePage',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        pageId: 'String!',
        pageWorkspaceId: 'String!',
      },
      response: FOLLOW_PAGE,
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  listWorkspacesWithFollowedPage({ variables, responseTemplate = '' } = {
  }) {
    const query = new requestBuilder({
      requestName: 'listWorkspacesWithFollowedPage',
      requestVariables: {
        pageId: 'String!',
      },
      response: responseTemplate ? responseTemplate : LIST_PAGES,
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  unfollowWorkspacePage(variables) {
    const query = new requestBuilder({
      requestName: 'unfollowWorkspacePage',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        pageId: 'String!',
        workspaceId: 'String!',
      },
      response: UNFOLLOW_PAGE,
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  subscribeFollowedWorkspacePage(variables) {
    const query = new requestBuilder({
      requestName: 'subscribeFollowedWorkspacePage',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      response: FOLLOW_PAGE,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  shareWorkspacePage(variables) {
    const query = new requestBuilder({
      requestName: 'shareWorkspacePage',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        workspacePageId: 'String!',
        pageWorkspaceId: 'String!',
        sharingUserId: 'String!',
        email: 'String!',
        emailMessage: 'String',
      },
      response: WORKSPACE_PERMISSION,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  inviteUserToWorkspacePage(variables) {
    const query = new requestBuilder({
      requestName: 'inviteUserToWorkspacePage',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        workspacePageId: 'String!',
        pageWorkspaceId: 'String!',
        email: 'String!',
        emailMessage: 'String',
      },
      response: WORKSPACE_PERMISSION,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
};
