import { baseSubscription } from '@/utils/subscriptionHandler';
import SearchCollectionsAndProducts
  from '@/services/graphql/searching/searchCollectionsAndProducts';
import SearchWorkspacePages from '@/services/graphql/searching/searchWorkspacePages';
const subscribeSearchResultV2 = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: SearchCollectionsAndProducts.subscribeSearchResultV2,
  unsubscribe: false,
});
const subscribeSearchResultToVerifiedArea = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: SearchCollectionsAndProducts.subscribeSearchResultToVerifiedArea,
  unsubscribe: false,
});
const subscribeSearchWorkspacePagesResult = (variables, next) => baseSubscription({
  variables,
  next,
  subscriptionFunction: SearchWorkspacePages.subscribeSearchWorkspacePagesResult,
  unsubscribe: false,
});
export {
  subscribeSearchWorkspacePagesResult,
  subscribeSearchResultV2,
  subscribeSearchResultToVerifiedArea,
};
