const COUNT_LAST_PRODUCT_RENDERING = 3;
export const getters = {
  lastProductRendering(state) {
    const { lastProducts } = state;
    return lastProducts.slice(0, COUNT_LAST_PRODUCT_RENDERING);
  },
  productsIdsWithoutLastRendering(state) {
    const { lastProducts } = state;
    return lastProducts.slice(COUNT_LAST_PRODUCT_RENDERING).map(e => e?.id) || [];
  },
  productsRemovingSubscription(state, getters, rootState) {
    const { productsIdsWithoutLastRendering } = getters;
    return rootState.ManageSubscriptions.subscriptions.reduce((result, current) => {
      const { title = '' } = current;
      const res = productsIdsWithoutLastRendering.includes(title) ? title : null;
      return res ? [...result, res] : result;
    }, []);
  },
};
