
import {
  OPERATOR_AND,
  OPERATOR_OR,
  FINE_TUNE_RESULTS,
  PART_MATCH,
  KEYWORD,
  PRODUCT_FILTERS_FOR_KEYWORD,
  SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE,
  BASIC_FUSE_OPTIONS,
} from '@/constants/searching/searchingFilters';
import Fuse from 'fuse.js';
import { compact } from 'lodash';
export default class SearchProducts {
  fuse;
  constructor({ items = [], keys = [] } = {
  }) {
    this.items = items;
    this.keys = keys;
  }
  initFuse() {
    this.fuse = new Fuse(this.items, {
      ...BASIC_FUSE_OPTIONS,
      keys: this.keys,
    });
    return this.fuse;
  }
  parseKeywordValuesWithSeparatedTokens({ values, result: initResult }) {
    const formatedValues = values.reduce((acc, item) => {
      if (!item) return acc;
      const arrItemValues = item.replace(/[,.-]/g, ' ')?.split(' ') || [];
      return [...acc, ...compact(arrItemValues)];
    }, []);
    const keywordMatchingArr = PRODUCT_FILTERS_FOR_KEYWORD.reduce((result, option) => {
      const keyMatches = formatedValues.map(val => {
        return {
          [SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE[option]]: `${FINE_TUNE_RESULTS[PART_MATCH]}"${val}"`,
        };
      });
      return [...result, ...keyMatches];
    }, []);
    return {
      [OPERATOR_AND]: [
        {
          [OPERATOR_OR]: keywordMatchingArr,
        },
      ],
      ...initResult,
    };
  }
  parseMainValues({ values, field, match, result }) {
    const { [OPERATOR_OR]: OR = [] } = result ?? {
    };
    const singleValueObj = values.map(val => {
      const fineTuneItem = `${FINE_TUNE_RESULTS[match]}"${val}"`;
      return {
        [field]: fineTuneItem,
      };
    });
    return {
      ...result,
      [OPERATOR_OR]: [
        ...OR,
        {
          [OPERATOR_AND]: singleValueObj,
        },
      ],
    };
  }
  search(data) {
    const { keysObj: obj } = data;
    this.initFuse();
    const filtersArr = Object.keys(obj);
    const formatedFuseKeys = filtersArr.reduce((result, option) => {
      const { values, field, match } = obj[option] ?? {
      };
      if (option === KEYWORD) {
        return this.parseKeywordValuesWithSeparatedTokens({
          values,
          result,
        });
      }
      return this.parseMainValues({
        values,
        field,
        match,
        result,
      });
    }, {
    });
    console.log('FUSE.JS MATCHES KEYS', formatedFuseKeys);
    const result = this.fuse.search(formatedFuseKeys).map(({ item }) => item);
    return result;
  }
}
