<template>
  <v-text-field
    :id="fieldId"
    v-model="location"
    :class="fieldClass"
    class="px-0"
    :placeholder="placeholder"
    autocomplete="off"
    :single-line="singleLine"
    :dense="dense"
    :error-messages="errorMessages"
    :disabled="disabled"
    @focus="onInteractWithInput"
    @blur="onInteractWithInput" />
</template>
<script>
import loader from '@/services/googleApi';
import { TYPE_CITIES } from '@/constants/maps';
import { debounce } from 'lodash';
export default {
  name: 'AppGoogleLocationField',
  props: {
    currentLocation: {
      type: String,
      default: '',
    },
    fieldId: {
      type: String,
      default: 'placeAuto',
    },
    fieldClass: {
      type: [String, Array],
      default: 'google-input',
    },
    placeholder: {
      type: String,
      default: 'Location',
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initOnMounted: {
      type: Boolean,
      default: false,
    },
    useLocationLogic: {
      type: Boolean,
      default: false,
    },
    locationTypes: {
      type: Array,
      default: () => [TYPE_CITIES], // any type supported by from google place types
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      location: '',
      locationOnGoogleMap: '',
      autocomplete: null,
    };
  },
  computed: {
    setPlace() {
      if (!this.useLocationLogic) {
        return this.setPlaceV2;
      }
      return this.setPlaceV1;
    },
  },
  watch: {
    currentLocation: {
      handler(val) {
        this.location = val;
      },
      immediate: true,
    },
  },
  mounted() {
    this.initAutocomplete();
  },
  methods: {
    onInteractWithInput: debounce(function () {
      this.location = this.locationOnGoogleMap;
    }, 300),
    getAutocompletePlace() {
      return this.autocomplete.getPlace();
    },
    async initAutocomplete() {
      try {
        this.google = await loader.load();
        this.$nextTick(() => {
          const autocomplete = new this.google.maps.places.Autocomplete(
            document.getElementById(this.fieldId),
            {
              types: this.locationTypes,
            }
          );
          this.autocomplete = autocomplete;
          autocomplete.addListener('place_changed', this.setPlace);
        });
        this.locationOnGoogleMap = this.currentLocation;
      } catch (e) {
        console.log(e);
      }
    },
    setPlaceV1() {
      const data = document.getElementById('placeAuto').value;
      this.locationOnGoogleMap = data;
      this.$emit('setLocationData', data);
    },
    setPlaceV2() {
      this.locationOnGoogleMap = document.getElementById('placeAuto').value;
      const { geometry, formatted_address, address_components } = this.getAutocompletePlace();
      if (this.getAutocompletePlace()) {
        this.locationOnGoogleMap = formatted_address;
        this.$emit('setAddressData', {
          geometry: {
            location: {
              lat: geometry?.location.lat(),
              lng: geometry?.location.lng(),
            },
          },
          formatted_address,
          address_components,
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
