import { GRAPHQL_TYPES } from '@/constants/cores';
import {
  API, graphqlOperation,
} from 'aws-amplify';
export default class requestBuilder {
  #requestName = '';
  #requestVariables = '';
  #requestType = '';
  #response = '';
  #responseName = '';
  #variables = {
  };
  #authMode = '';
  #responseWrapperText = '';
  #useRemovingOfVariables = false;
  /**
   * @param {String} requestName
   * @param {Object} requestVariables
   * @param {String} response
   * @param {String} responseName if not set we use responseName
   * @param responseWrapperText
   * @param {String} requestType
   * @param {Object} variables
   * @param {String} authMode
   * @param useRemovingOfVariables
   */
  constructor({
    requestName, requestVariables, response = '', responseName, responseWrapperText = 'response', requestType = GRAPHQL_TYPES.QUERY, authMode, variables = {
    }, useRemovingOfVariables = false,
  }) {
    this.#useRemovingOfVariables = useRemovingOfVariables;
    this
      .setRequestType(requestType)
      .setRequestName(requestName)
      .setRequestVariables(requestVariables)
      .setResponse(response)
      .setResponseName(responseName)
      .setVariables(variables)
      .setResponseWrapperText(responseWrapperText)
      .setAuthMode(authMode);
  }
  buildVariablesRow(name, type) {
    return `$${name}: ${type}\n`;
  }
  /**
   * @returns {requestBuilder}
   */
  buildRequestHeader() {
    let variables = '';
    for (const key in this.#requestVariables) {
      if (this.#useRemovingOfVariables && this.#variables[key] || !this.#useRemovingOfVariables) {
        variables = variables.concat(this.buildVariablesRow(key, this.#requestVariables[key]));
      }
    }
    return `
      ${this.#requestType} ${this.#requestName}
      ${ variables && '('.concat(variables, ')')}
    `;
  }
  buildResponseRow(name) {
    return `${name}: $${name} \n`;
  }
  buildResponse() {
    let variables = '';
    for (const key in this.#requestVariables) {
      if (this.#useRemovingOfVariables && this.#variables[key] || !this.#useRemovingOfVariables) {
        variables = variables.concat(this.buildResponseRow(key));
      }
    }
    return `
      ${this.#responseWrapperText}: ${this.#responseName ? this.#responseName : this.#requestName}
      ${ variables && '('.concat(variables, ')')}
      ${this.buildResponseVariables()}
    `;
  }
  buildResponseVariables() {
    if (this.#response) {
      return `
        {
          ${this.#response}
        }
      `;
    }
    return '';
  }
  /**
   * @param requestType
   * @returns {requestBuilder}
   */
  setRequestType(requestType) {
    if (!Object.values(GRAPHQL_TYPES).includes(requestType)) {
      console.error('sorry but you are sending wrong request type');
    }
    this.#requestType = requestType;
    return this;
  }
  /**
   * @param requestName
   * @returns {requestBuilder}
   */
  setRequestName(requestName) {
    this.#requestName = requestName;
    return this;
  }
  /**
   *
   * @param variables
   * @returns {requestBuilder}
   */
  setRequestVariables(variables) {
    this.#requestVariables = variables;
    return this;
  }
  setAuthMode(authMode) {
    this.#authMode = authMode;
    return this;
  }
  debugQuery() {
    console.log(this.buildQuery());
    return this;
  }
  buildQuery() {
    return `
      ${this.buildRequestHeader()} {
        ${this.buildResponse()}
      }
    `;
  }
  /**
   * @param response
   * @returns {requestBuilder}
   */
  setResponse(response) {
    this.#response = response;
    return this;
  }
  setResponseWrapperText(text) {
    this.#responseWrapperText = text;
    return this;
  }
  /**
   *
   * @param responseName
   * @returns {requestBuilder}
   */
  setResponseName(responseName) {
    this.#responseName = responseName;
    return this;
  }
  setVariables(variables) {
    this.#variables = variables;
    return this;
  }
  buildArguments() {
    const authMode = this.#authMode;
    return {
      ...(authMode && ({
        authMode,
      })),
      ...graphqlOperation(this.buildQuery(), this.#variables),
    };
  }
  debugArguments() {
    console.log(this.buildArguments());
    return this;
  }
  processRequest() {
    return API.graphql(this.buildArguments());
  }
}
