export const getters = {
  getPreSelectedTypePlan: (state, getters, rootState) => (plansTypes) => {
    const { paramsFromCustomView } = rootState;
    if (!paramsFromCustomView) return false;
    const plan = paramsFromCustomView.find(e => e.name === 'tire');
    return plansTypes.findIndex(e => e.type === plan?.value);
  },
  getClearRole: () => (role) => {
    return role === 'Pro User' ? 'Premium' : role === 'Collaborator' ? 'Design Partner' : '';
  },
  getPreSelectedRole(state, getters, rootState) {
    const { paramsFromCustomView } = rootState;
    if (!paramsFromCustomView) return false;
    const interval = paramsFromCustomView.find(e => e.name === 'interval')?.value || '';
    const role = paramsFromCustomView.find(e => e.name === 'role')?.value;
    return {
      interval, role: getters.getClearRole(role),
    };
  },
};

