import {
  PERSONAL_WORKSPACE_ID,
  ROUTE_COMMUNITY_COLLECTIONS,
  ROUTE_COMMUNITY,
  TYPE_WORKSPACE_FREE,
  TYPE_WORKSPACE_PREMIUM,
} from '@/constants';
import {
  TYPE_CREATOR,
  TYPE_GUEST,
  NAME_CREATOR_ADMIN,
  TYPE_CREATOR_ADMIN,
  TYPE_MEMBER,
} from '@/constants/userPermissions';
import { WORKSPACE } from '@/constants/cores';
export const getters = {
  getWorkspaceStoragePrefix: (state, getters, rootState, rootGetters) => {
    const { userId } = rootGetters;
    return state.activeWorkspacePrefix.concat(userId);
  },
  getWorkspaceById: state => id => {
    return state.workspacesList.find(workspace => workspace.id == id);
  },
  getFirstWorkspaceIdInList(state) {
    const FIRST_ELEMENT_IN_LIST_INDEX = 0;
    return state.workspacesList.find((w, index) => index === FIRST_ELEMENT_IN_LIST_INDEX)?.id;
  },
  getCorrectLinkWithWS: state => path => {
    return '/' + WORKSPACE + '/' + state.activeWorkspaceId + path;
  },
  getWorkspacePageRedirectLink: (state) => (pageId) => {
    return `/${WORKSPACE}/` + state.activeWorkspaceId + `/${ROUTE_COMMUNITY_COLLECTIONS}/` + pageId + `/${ROUTE_COMMUNITY}/`;
  },
  getActiveWorkspace (state, getters) {
    return getters.getWorkspaceById(state.activeWorkspaceId);
  },
  getActiveWorkspacePage(state, getters) {
    return getters.findWorkspacePageByWsId(state.activeWorkspaceId);
  },
  findWorkspacePageByWsId: (state) => wsId => {
    return state.workspacePagesForSpecifiedWs.find((({ workspaceId }) => workspaceId == wsId));
  },
  findWorkspacePageByPageId: (state) => pageId => {
    return state.workspacePagesForSpecifiedWs.find((({ id }) => id == pageId));
  },
  getActiveWorkspaceName(state, getters) {
    return getters.getActiveWorkspace?.name;
  },
  isActiveWorkspacePublished(state) {
    return state.workspacePagePublished;
  },
  isActiveWorkspaceAllowPages(state, getters) {
    return !!getters.getActiveWorkspace?.allowPages;
  },
  isFreeWorkspace: (state, getters) => {
    return getters.getActiveWorkspace?.workspaceType == TYPE_WORKSPACE_FREE;
  },
  isPremiumWorkspace: (state, getters) => {
    return getters.getActiveWorkspace?.workspaceType == TYPE_WORKSPACE_PREMIUM;
  },
  getPersonalWorkspace: (state, getters) => {
    return getters.getWorkspaceById(PERSONAL_WORKSPACE_ID);
  },
  isPersonalWorkspace: (state) => {
    return state.activeWorkspaceId == PERSONAL_WORKSPACE_ID;
  },
  disabledPersonalWs(state, getters) {
    return getters.isPersonalWorkspace;
  },
  isUserExistsInWorkspace: (state, getters) => userId => {
    return getters.isUserGuestInWorkspace(userId) || getters.isUserMemberInWorkspace(userId);
  },
  getMemberById: (state) => userId => {
    return state.memberUsers.find(({ userInfo }) => userInfo.id == userId);
  },
  isUserMemberInWorkspace: (state, getters) => userId => {
    return !!getters.getMemberById(userId);
  },
  getGuestById: (state) => userId => {
    return state.guestUsers.find(({ userInfo }) => userInfo.id == userId);
  },
  isUserGuestInWorkspace: (state, getters) => userId => {
    return !!getters.getGuestById(userId);
  },
  getActiveWorkspaceOwner(state, getters) {
    return getters.getActiveWorkspace?.creator;
  },
  isWorkspaceOwner: (state, getters) => userId => getters.getActiveWorkspaceOwner?.id == userId,
  isActiveWorkspaceOwner(state, getters, rootState, rootGetters) {
    return getters.isWorkspaceOwner(rootGetters.userId);
  },
  getActiveWorkspaceUsedLicences(state, getters) {
    return getters.getActiveWorkspace?.usedLicenses;
  },
  isAdminConsole(state, getters) {
    return getters.getActiveWorkspace?.type === TYPE_CREATOR_ADMIN;
  },
  canPermissionAdminConsole(state, getters) {
    return !getters.isPersonalWorkspace && (getters.isActiveWorkspaceOwner || getters.isAdminConsole);
  },
  getActiveWorkspaceLicencesAmount(state, getters) {
    const { isPremiumWorkspace, isFreeWorkspace, getActiveWorkspace } = getters;
    if (isFreeWorkspace) {
      return getActiveWorkspace?.freeLicenses;
    }
    if (isPremiumWorkspace) {
      return getActiveWorkspace?.premiumLicenses;
    }
    return 0;
  },
  getCorrectTypeRole: () => (type) => {
    return type === TYPE_CREATOR_ADMIN ? NAME_CREATOR_ADMIN : type;
  },
  canAddMoreMembersToActiveWorkspace(state, getters) {
    return getters.getActiveWorkspaceLicencesAmount > getters.getActiveWorkspaceUsedLicences;
  },
  //only for active workspace
  getActiveWorkspaceMembership: (state, getters) => getters.getActiveWorkspace?.workspaceMembership,
  getActiveWorkspaceRole: (state, getters) => getters.getActiveWorkspace?.type,
  isActiveUserGuestInWorkspace: (state, getters) => getters.getActiveWorkspaceMembership == TYPE_GUEST,
  isActiveUserMemberInWorkspace: (state, getters) => getters.getActiveWorkspaceMembership == TYPE_MEMBER,
  isActiveUserCreatorInWorkspace: (state, getters) => getters.getActiveWorkspaceMembership == TYPE_CREATOR,
  getActiveWorkspaceProjectsCount: (state, getters) => getters.getActiveWorkspace?.projectCount,
  getActiveWorkspaceLogoKey: (state, getters) => getters.getActiveWorkspace?.logo,
};
