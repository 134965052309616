export const actions = {
  setSubscription({ commit, state }, { subscription, title }) {
    const isSubscriptionExist = state.subscriptions.some((s) => s.title === title);
    if (!isSubscriptionExist) {
      commit('setSubscriptions', [...state.subscriptions, {
        subscription,
        title,
      }]);
    }
  },
  removeSubscriptions({ commit, state }, titles) {
    const titlesArr = Array.isArray(titles) ? titles : [titles];
    const newSubscriptions = state.subscriptions.filter((s) => {
      if (titlesArr.includes(s.title)) {
        s.subscription.unsubscribe();
        return false;
      }
      return true;
    });
    commit('setSubscriptions', newSubscriptions);
  },
};
