import {
  USER_DATA_NO_BRACKETS, WORKSPACE_SETTINGS,
} from '@/services/graphql/fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
export default {
  setSortingMode(variables) {
    return new requestBuilder({
      requestName: 'setSortingMode',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        resourceType: 'ResourceType!',
        sortingMode: 'SortingMode!',
        ascending: 'Boolean',
      },
      response: USER_DATA_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  reorderSections(variables) {
    return new requestBuilder({
      requestName: 'reorderSections',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        resourceType: 'ResourceType!',
        workspaceId: 'String!',
        libraryId: 'String',
        sectionOrder: '[String]!',
      },
      response: WORKSPACE_SETTINGS,
      variables,
    }).processRequest();
  },
};
