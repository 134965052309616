
<template>
  <div>
    <FileImagePreview
      :items="items"
      @clearFilePreview="items = []" />
    <v-snackbar
      v-model="isSnackBarShown"
      :multi-line="true"
      :timeout="snackbar.timeout"
      :data-test="snackbar.dataTest"
      :color="snackbar.color">
      <p class="medium-p white--text font-weight-medium mr-auto">
        {{ snackbar.title }}
      </p>
      <v-row
        no-gutters>
        <p
          v-if="isPdf"
          class="mr-2">
          <a
            dataTest="export_ready_popup_preview_button"
            @click="addPdfToPreview">
            PREVIEW
          </a>
        </p>
        <p>
          <a
            dataTest="export_ready_popup_download_button"
            @click.prevent="downloadPdf">
            DOWNLOAD
          </a>
        </p>
      </v-row>
      <p class="small-p white--text">
        {{ snackbar.text }}
      </p>
      <v-icon
        data-test="export_ready_popup_close_button"
        color="#fff"
        style="position: absolute; right: -5px; top: 5px"
        @click="closeSnackBar">
        mdi-close
      </v-icon>
    </v-snackbar>
  </div>
</template>
<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
import FileImagePreview from '@/components/App/AppFileImagePreview';
import {
  isDocxExt,
  isXlsxExt, isZipExt,
} from '@/utils/fileExtensions';
import FileExtensions from '@/constants/fileExtensions';
export default {
  name: 'ProjectDetailsExportSnackBar',
  components: {
    FileImagePreview,
  },
  data: () => ({
    items: [],
  }),
  computed: {
    ...mapState('ExportSchedule', [
      'exportedFileForPreview',
      'snackbar',
    ]),
    ...mapGetters('ExportSchedule', ['getDownloadedFileName']),
    ...mapGetters('ScheduleViews', ['selectedView']),
    ...mapGetters('ProjectDetails', ['getDetailProjectTitle']),
    isSnackBarShown: {
      get() {
        return this.snackbar.isSnackBarShown;
      },
      set() {
        this.closeSnackBar();
      },
    },
    isZip() {
      return isZipExt(this.pdfName || '');
    },
    isDocx() {
      return isDocxExt(this.pdfName || '');
    },
    contentType() {
      if (this.isDocx) {
        return {
          type: FileExtensions.CONTENT_TYPE_DOCX,
        };
      }
      if (this.isZip) {
        return {
          type: FileExtensions.CONTENT_TYPE_ZIP,
        };
      }
      if (this.isXlsx) {
        return {
          type: FileExtensions.CONTENT_TYPE_XLSX,
        };
      }
      return {
        type: FileExtensions.PDF,
      };
    },
    isXlsx() {
      return isXlsxExt(this.pdfName || '');
    },
    isPdf() {
      return this.pdfName?.split('.').pop() === 'pdf';
    },
    pdfName() {
      return this.exportedFileForPreview?.pdfName;
    },
    jsonToDownloadFile() {
      const { getDownloadedFileName: filename, exportedFileForPreview, contentType } = this;
      const { type } = contentType;
      const { pdfName, bucketName } = exportedFileForPreview;
      const finalFileName = filename.includes('Current view') ?
        `${this.selectedView.name} - ${this.getDetailProjectTitle} - ${this.$moment().format('YYYY-mm-DD')}` :
        filename;
      return {
        type,
        filename: finalFileName,
        url: `https://${bucketName}.s3.amazonaws.com/${pdfName}`,
      };
    },
  },
  methods: {
    ...mapActions(['downloadFileByBlob']),
    ...mapMutations({
      spinner: 'spinner',
      closeSnackBar: 'ExportSchedule/closeSnackBar',
    }),
    downloadPdf() {
      this.spinner(true);
      this.downloadFileByBlob(this.jsonToDownloadFile);
    },
    addPdfToPreview() {
      this.items.push(
        {
          allowDefault: false,
          id: this.pdfName,
          ...this.jsonToDownloadFile,
        },
      );
    },
  },
};
</script>
