import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        blue: '#2479ff',
        yellow: '#F06F00',
        green: '#00a774',
        red: '#da304c',
        black: '#0C0C0C',
        white: '#FEFEFE',
        lightGrey: '#F7F7F9',
        darkGrey: '#ACACAC',
        // TODO: remove colors below
        // grey and error--text are still used please check
        lightBlack: '#171E37',
      },
    },
  },
  breakpoint: {
    mobileBreakpoint: 992,
    // this defines the max-width of a breakpoint
    // keep in sync with $grid-breakpoints variables
    // inside src/styles/variables.scss
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1280,
      xl: 1600,
    },
    scrollBarWidth: 0,
  },
  icons: {
    iconfont: 'md',
  },
});
