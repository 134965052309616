import { IS_STORE_ROOT } from '@/constants';
import ProjectAggregationApi from '@/services/graphql/projectAggregation';
import Subscriptions from '@/services/graphql/subscriptions';
import { RECALCULATE_TAG_TOTAL_COST } from '@/constants/userPermissions';
export const actions = {
  async getProjectAggregation({ commit, rootState }, { projectId, showSpinner }) {
    if (showSpinner !== false) {
      commit('spinner', true, IS_STORE_ROOT);
    }
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await ProjectAggregationApi.getProjectAggregation({
        projectId,
        workspaceId,
      });
      commit('setAggregationData', data.response);
    } catch (err) {
      console.log(err);
    } finally {
      if (showSpinner !== false) {
        commit('spinner', false, IS_STORE_ROOT);
      }
    }
  },
  async subscribeRecalculateAggregation({ commit }, variables) {
    try {
      const subscription = await Subscriptions.subscribeRecalculateAggregation(variables).subscribe({
        next: ({ value }) => {
          commit('setAggregationData', value.data.response);
          commit('addTableSubscriptions', subscription, IS_STORE_ROOT);
        },
      });
    } catch (err) {
      console.log('subscribeRecalculateAggregation err', err);
    }
  },
  async subscribeRecalculateTagTotalCost({ commit, dispatch }, tagInfo) {
    try {
      const subscription = await Subscriptions.subscribeRecalculateTagTotalCost(tagInfo).subscribe({
        next: (res) => {
          commit('setTagTotal', res.value.data.response);
        },
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: RECALCULATE_TAG_TOTAL_COST,
      }, IS_STORE_ROOT);
    }
    catch (err) {
      console.log('subscribeRecalculateTagTotalCost', err);
    }
  },
  async getTotalOfTag({ commit, dispatch, getters }, tagInfo) {
    const { canSelectedViewTriggerTotalTag: canTrigger } = getters;
    if (!canTrigger) {
      return;
    }
    try {
      const { data } = await ProjectAggregationApi.getTotalOfTag(tagInfo);
      dispatch('subscribeRecalculateTagTotalCost', data.response);
      commit('setTagTotal', data.response);
    } catch (err) {
      console.log(err);
    }
  },
  async recalculateAggregation( { commit }, variables) {
    try {
      const { data } = await ProjectAggregationApi.recalculateProjectAggregation(variables);
      commit('setAggregationData', data.response);
    } catch (err) {
      console.log(err);
    }
  },
};
