import CollapseApi from '@/services/graphql/collapse';
import SortingApi from '@/services/graphql/sorting';
import { IS_STORE_ROOT } from '@/constants';
import API from '@/services/graphql';
import SubscriptionsApi from '@/services/graphql/subscriptions';
import { COLLAPSE_REORDER_SUBSCRIPTION } from '@/constants/userPermissions';
export const actions = {
  async  getWorkspaceSectionSettings({ dispatch, rootState, commit }, {
    libraryId,
    resourceType = 'collection',
  }) {
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await API.getWorkspaceSectionSettings({
        resourceType,
        workspaceId,
        libraryId,
      });
      const { collectionsSectionsOrder: order = [] } = data.response || {
      };
      commit('setSectionsOrder', order);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async setExpandedSection ({ dispatch, rootState, commit, rootGetters }, {
    libraryId,
    resourceType = 'collection',
    section = {
    },
  }) {
    const useSpinner = !rootGetters['FeatureFlags/useLazyLoading'];
    const { activeWorkspaceId: workspaceId } = rootState.Workspace;
    try {
      if (useSpinner) commit('spinner', true, IS_STORE_ROOT);
      const { group: sectionId, expanded } = section;
      await CollapseApi.changeSectionState({
        resourceType,
        libraryId,
        workspaceId,
        sectionId,
        expanded,
      });
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
    finally {
      if (useSpinner) commit('spinner', false, IS_STORE_ROOT);
    }
  },
  subscriptionsCollapse({ dispatch, rootState, commit }, {
    libraryId,
    resourceType = 'collection',
  }) {
    const { id: userId } = rootState.UserProfile.userData;
    const { activeWorkspaceId: workspaceId } = rootState.Workspace;
    const subscription = SubscriptionsApi.subscribeUserWorkspaceSettings({
      userId,
      libraryId,
      resourceType,
      workspaceId,
    }).subscribe({
      next: ({ value }) => {
        const { collectionsSectionsOrder: order = [] } = value.data.response || {
        };
        commit('setSectionsOrder', order);
      },
    });
    dispatch('ManageSubscriptions/setSubscription', {
      subscription,
      title: COLLAPSE_REORDER_SUBSCRIPTION,
    }, IS_STORE_ROOT);
  },
  async setReorderSections ({ dispatch, rootState, commit }, {
    libraryId,
    resourceType = 'collection',
    sectionOrder = [],
  }) {
    const { activeWorkspaceId: workspaceId } = rootState.Workspace;
    try {
      commit('spinner', true, IS_STORE_ROOT);
      await SortingApi.reorderSections({
        sectionOrder,
        resourceType,
        workspaceId,
        libraryId,
      });
    }
    catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
    finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
};
