import { IS_STORE_ROOT } from '@/constants';
import SearchProjectCellsApi from '@/services/graphql/searchProjectCells';
import { subscribeSearchProjectCellsResult } from '@/services/graphql/searchProjectCellsSubscription';
import { SEARCH_PROJECT_CELLS_RESULT } from '@/constants/userPermissions';
import { uniqBy } from 'lodash';
export const actions = {
  async searchProjectCells({ commit, dispatch, rootState, rootGetters }, { query = null, limit = 100, projectId, scrollID } = {
  }) {
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { selectedViewId: viewId } = rootState.ScheduleViews;
      const { userId: username } = rootGetters;
      const versionId = rootGetters['ProjectVersions/getCurrentVersion'];
      const isVersionMode = rootGetters['ProjectVersions/isVersionMode'];
      commit('showSearchLoader', true);
      const variables = {
        query,
        projectId,
        viewId,
        ...(isVersionMode && {
          versionId,
        }),
        workspaceId,
        limit,
        scrollID,
      };
      await dispatch('subscribeSearchProjectCells', {
        projectId,
        username,
        query,
        viewId,
        ...(isVersionMode && {
          versionId,
        }),
        scrollID,
      });
      await SearchProjectCellsApi.searchProjectCells(variables);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
      dispatch('resetSearching');
    }
  },
  async subscribeSearchProjectCells({ dispatch, commit, state, rootGetters }, { scrollID: scrollIDToRequest, ...variables }) {
    try {
      const subscription = await subscribeSearchProjectCellsResult(variables, ({ data }) => {
        const isSubscriptionExist = rootGetters['ManageSubscriptions/isSubscriptionExist'](SEARCH_PROJECT_CELLS_RESULT);
        if (!isSubscriptionExist) return;
        dispatch('removeSearchProjectCellsSubs');
        const { data: dataArr, scrollID = null, query } = data.response;
        if (state.searchString !== query) return;
        const { searchingResult: currentArr } = state;
        const deduplicatedArr = uniqBy([...currentArr, ...dataArr], 'rowId');
        const mergedResultArr = scrollIDToRequest ? deduplicatedArr : dataArr;
        commit('setSearchingResult', mergedResultArr);
        if (!scrollID || !dataArr?.length) {
          commit('showSearchLoader', false);
          commit('setCurrentIndexResultItem', 1);
          return;
        }
        dispatch('searchProjectCells', {
          ...variables,
          scrollID,
        });
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: SEARCH_PROJECT_CELLS_RESULT,
      }, IS_STORE_ROOT);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
      dispatch('resetSearching');
    }
  },
  resetSearching({ commit, dispatch }) {
    dispatch('removeSearchProjectCellsSubs');
    commit('setSearchingResult', []);
    commit('showSearchLoader', false);
    commit('setCurrentIndexResultItem', 0);
  },
  removeSearchProjectCellsSubs({ dispatch }) {
    dispatch('ManageSubscriptions/removeSubscriptions', SEARCH_PROJECT_CELLS_RESULT, IS_STORE_ROOT);
  },
};
