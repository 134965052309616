import {
  PROJECTS_COMMENTS_COLLABORATORS_MUTATION,
  PROJECTS_COMMENTS_MUTATION,
  COLLECTIONS_COMMENTS_MUTATION,
  COLLECTIONS_COMMENTS_COLLABORATORS_MUTATION,
  NEW_PROJECTS_COMMENTS_ID_MUTATION,
  NEW_COLLECTIONS_COMMENTS_ID_MUTATION,
} from '@/constants/comments';
import { getDefaultState } from '@/store/Comments/state';
export const mutations = {
  setComments(state, payload) {
    state.commentsList = payload;
  },
  [COLLECTIONS_COMMENTS_MUTATION]: (state, payload) => {
    state.collectionCommentsListV2 = payload;
  },
  [PROJECTS_COMMENTS_MUTATION]: (state, payload) => {
    state.projectCommentsListV2 = payload;
  },
  setRowFollowers(state, payload) {
    state.rowFollowers = payload;
  },
  [COLLECTIONS_COMMENTS_COLLABORATORS_MUTATION]: (state, payload) => {
    state.collectionCollaboratorsListV2 = payload;
  },
  [PROJECTS_COMMENTS_COLLABORATORS_MUTATION]: (state, payload) => {
    state.projectCollaboratorsList = payload;
  },
  setNewCommentId(state, payload) {
    state.newCommentId = payload;
  },
  [NEW_COLLECTIONS_COMMENTS_ID_MUTATION]: (state, payload) => {
    state.newCollectionCommentIdV2 = payload;
  },
  [NEW_PROJECTS_COMMENTS_ID_MUTATION]: (state, payload) => {
    state.newProjectCommentIdV2 = payload;
  },
  setCommentCellPointers(state, payload) {
    state.commentCellPointers = payload;
  },
  setSlants(state, payload) {
    state.slants = payload;
  },
  setProjectSlants(state, payload) {
    state.projectSlants = payload;
  },
  setCollectionsSlants(state, payload) {
    state.collectionSlants = payload;
  },
  clearSlants(state) {
    state.slants = [];
  },
  setProjectUserHideResolvedToggle(state, payload) {
    state.hideResolvedComments = payload;
  },
  setCollectionUserHideResolvedToggleV2(state, payload) {
    state.hideResolvedCollectionCommentsV2 = payload;
  },
  setProjectUserHideResolvedToggleV2(state, payload) {
    state.hideResolvedProjectCommentsV2 = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};
