import { orderBy } from 'lodash';
import getTypeByHeaderName from '@/utils/getTypeByHeaderName';
import productHeaders from '@/constants/productHeaders';
import {
  SORTABLE_ACTIONS_WIDTH, SORTABLE_FIELDS_PROJECTS,
} from '@/constants/scheduleViews';
import { setMappedDropdownsOfView } from '@/utils/collectionSchemaAndViews';
const { TYPE_DESIGNATION, IMAGE, DRAWING_UPLOADED_DATE, SHOP_DRAWINGS } = productHeaders;
export const getters = {
  dropdownsProject(state, getters, rootState, rootGetters) {
    const selectedView = rootGetters['ScheduleViews/selectedView'];
    return selectedView?.dropdowns;
  },
  dropdownsProjectAsObj(state, getters) {
    const dropdowns = getters.dropdownsProject;
    return setMappedDropdownsOfView(dropdowns);
  },
  copiedContent(state) {
    let content = state.copiedContent;
    try {
      content = JSON.parse(content);
    } catch (err) {
      console.log('err parse copied content');
    }
    return content;
  },
  sortedProjectPermissions(state) {
    const { projectPermissions } = state;
    return orderBy(projectPermissions, ['type'], ['asc']);
  },
  rowIdsForSlants(state, getters, rootState) {
    const { viewInfo } = rootState['ScheduleRows'];
    return viewInfo.map(item => item.id);
  },
  allTags(state) {
    return state.projectTags;
  },
  tableHeadersWidth(state, getters, rootState, rootGetters) {
    const tableHeaders = rootGetters['ScheduleViews/tableHeaders'];
    return tableHeaders.reduce((result, header) => {
      let width = 0;
      let sortSize = header?.isCustomSortable ? 45 : 0;
      if (header?.columnSize?.size) {
        width = header?.columnSize?.size + sortSize || 0;
      }
      result = result + width;
      return result;
    }, 0);
  },
  getTableHeaderFromObj: (state, getters) => name => {
    const { defaultTableHeadersAsObj } = getters ?? {
    };
    return defaultTableHeadersAsObj[name] ?? null;
  },
  defaultTableHeadersAsObj(state, getters, rootState, rootGetters) {
    const { schema, rightAlignment } = state;
    const viewsDropDownsObj = rootGetters['ScheduleViews/viewsDropDownsObj'];
    if (!viewsDropDownsObj || !schema.length) {
      return [];
    }
    const initialHeaders = {
      order: {
        text: '',
        value: 'order',
        sortable: false,
        align: 'center',
        column: {
          type: getTypeByHeaderName({
            headerObj: {
              name: 'order',
            },
          }),
        },
        style: {
        },
      },
    };
    const columnsSize = rootGetters['ScheduleViews/columnsSizeMapping'] ?? {
    };
    return schema?.reduce((result = {
    }, option) => {
      const column = columnsSize[option.id] ?? undefined;
      const isCustomSortable = SORTABLE_FIELDS_PROJECTS.includes(option?.name);
      if (column && isCustomSortable) {
        column.size = column.size + SORTABLE_ACTIONS_WIDTH;
        column.minSize = column.minSize + SORTABLE_ACTIONS_WIDTH;
      }
      let columnSize = column || {
        id: option.id,
        size: 180,
        minSize: 180,
        unit: 'px',
        found: false, // flag to know that value doesn't present
      };
      //for supporting old columns size on dev
      if (process.env.NODE_ENV == 'development') {
        if (option.name == IMAGE) {
          columnSize = {
            ...columnSize,
            size: 270,
          };
        }
        if (option.name == TYPE_DESIGNATION) {
          columnSize = {
            ...columnSize,
            size: 180 + SORTABLE_ACTIONS_WIDTH,
          };
        }
      }
      const isDeprecatedField = rootGetters['FeatureFlags/useScheduleViewAndFieldsModifications'] && [SHOP_DRAWINGS, DRAWING_UPLOADED_DATE].includes(option.name);
      const deprecatedClass = isDeprecatedField ? 'v-data-table-header__deprecated' : '';
      const widthStyling = {
        columnSize,
        thClass: deprecatedClass,
        cellClass: 'v-data-table-cell__base',
        deprecated: isDeprecatedField,
      };
      const colType = getTypeByHeaderName({
        headerObj: option,
        selectedView: viewsDropDownsObj,
        showFieldProduct: true,
      });
      const objectToMerge = {
        [option?.name || '']: {
          permissions: option.permissions,
          text: option.name,
          value: option.name,
          sortable: false,
          isCustomSortable,
          isAllowedToResize: option.name !== 'order' && !['ARRAY_AIRTABLE_IMAGE', 'TYPE_COL'].includes(colType),
          align: rightAlignment.includes(option) ? 'right' : 'left',
          hoveredSettings: null,
          draggableSettings: null,
          column: {
            type: colType,
            shortPref: option?.shortPref,
            hint: option?.hint,
            hintWithSourceLibrary: option?.hintWithSourceLibrary,
            id: option.id,
            name: option.name,
          },
          ...widthStyling,
        },
      };
      return {
        ...result,
        ...objectToMerge,
      };
    }, {
      ...initialHeaders,
    });
  },
  defaultTableHeaders(state, getters) {
    const { defaultTableHeadersAsObj } = getters;
    return Object.values(defaultTableHeadersAsObj);
  },
  defaultTableHeadersToPreview(state, getters, rootState, rootGetters) {
    const selectedColumnOrder = rootGetters['Collections/selectedColumnOrder'];
    if (!selectedColumnOrder?.length) {
      return [];
    }
    const { defaultTableHeaders } = getters;
    const headers = selectedColumnOrder.reduce((result, option) => {
      const header = defaultTableHeaders.find(({ value }) => value === option);
      if (header) {
        return [...result, header];
      }
      return result;
    }, []);
    return headers;
  },
  getTableHeader: (state, getters) => name => {
    const { defaultTableHeaders } = getters;
    return defaultTableHeaders?.find(item => item?.value === name);
  },
  isReadOnlyCell: state => cellName => state.readOnlyFields.includes(cellName),
  getProjectLogoAccordingToWorkspace(state, getters, rootState, rootGetters) {
    const { userId } = rootGetters;
    if (!userId) {
      return rootGetters['ProjectDetails/workspaceLogoToProject'];
    }
    const isPersonalWorkspace = rootGetters['Workspace/isPersonalWorkspace'];
    if (isPersonalWorkspace) {
      return state.creatorLogo;
    }
    return rootState.Workspace.activeWorkspaceLogoUrl;
  },
  isSelectedCell: (state) => ({ columnName, currentRowId: id }) => {
    const { selectedCells } = state;
    if (!selectedCells?.length) return false;
    return selectedCells.findIndex(({ col, rowId }) => columnName === col && id === rowId) !== -1;
  },
  mappingControlFields: (state, getters) => (fields) => {
    const { getTableHeaderFromObj } = getters;
    return fields.map(e => {
      const item = getTableHeaderFromObj(e);
      if (item) {
        return {
          name: e, permissions: item.permissions,
        };
      }
      return {
        name: e,
      };
    });
  },
  isListingView(state, getters, rootState, rootGetters) {
    const { selectedViewId } = rootState.ScheduleViews;
    return rootGetters.toggleViewCondition(selectedViewId);
  },
  selectedCellsLength(state) {
    const { selectedCells: arr } = state;
    if (!arr) return 0;
    return arr.length;
  },
  selectedCells: (state) => state.selectedCells,
  getFirstActiveCell: state => state.selectedCells[0] || null,
  isActiveEditMode: state => state.isActiveEditMode,
  isDisableKeyboardController: state => state.isDisableKeyboardController,
  isTableFocused: state => Boolean(state.selectedCells.length),
};
