import productHeaders from '@/constants/productHeaders';
const getTypeByHeaderName = (params) => {
  const {
    headerObj = {
    }, selectedView = {
    }, showFieldProduct = false,
  } = params || {
  };
  const {
    name = '',
    type = '',
    multiline = false,
    formulaType = '',
    arrayType = '',
  } = headerObj || {
  };
  const { custom_dropdowns = [], dropdowns = [] } = selectedView || {
  };
  /*,
      Якщо буде тип массив то додаємо ARRAY до назви,
      додавши до назви ARRAY компонента буде на бек передавати не value-"" a values []
    */
  if (custom_dropdowns?.includes(name)) {
    return 'COMBOBOX';
  }
  if (dropdowns?.findIndex(dropdown => dropdown.name === name) > -1) {
    return 'DROPDOWN';
  }
  if (name === productHeaders.TYPE_DESIGNATION) {
    return 'TYPE_COL';
  }
  if (showFieldProduct) {
    if (name === 'Product Reference') {
      return 'FIELD_PRODUCT_REFERENCE';
    }
    if (name === 'Product Equal') {
      return 'FIELD_PRODUCT_EQUAL';
    }
  }
  if (name === 'order') {
    return 'ORDER_COL';
  }
  switch (type) {
  case 'string':
    if (multiline) {
      return 'MULTILINE';
    } else {
      return 'MULTILINE';
      // return 'SINGLE';
    }
  case 'attachment':
    return 'ARRAY_AIRTABLE_IMAGE';
  case 'number':
    return 'NUMBER';
  case 'url':
    return 'URL';
  case 'currency':
    return 'CURRENCY';
  case 'unit_price':
    return 'CURRENCY_WITH_UNIT';
  case 'relation':
    return 'ASSOCIATED_DOCUMENTS';
  case 'checkbox':
    return 'CHECKBOX';
  case 'date':
    return 'DATE';
  case 'integer':
    if (['Total Cost', 'Budget Price'].includes(name)) {
      return 'CURRENCY';
    }
    if (['Quantity', 'Efficacy (lm/w)'].includes(name)) {
      return 'NUMBER';
    }
    break;
  case 'float':
    if (['Total Cost', 'Budget Price'].includes(name)) {
      return 'CURRENCY';
    }
    if (['Quantity', 'Efficacy (lm/w)'].includes(name)) {
      return 'NUMBER';
    }
    break;
  case 'formula':
    return formulaType ? `FORMULA_${formulaType.toUpperCase()}` : 'FORMULA';
  case 'array':
    if (arrayType === 'string' && name.includes('Link')) {
      return 'URL';
    }
    if (arrayType === 'string') {
      return 'ARRAY_STRING';
    }
    if (arrayType === 'attachment' || arrayType === 'airtableImage') {
      return 'ARRAY_AIRTABLE_IMAGE';
    }
    if (arrayType === 'url') {
      return 'URL_MULTILINE';
    }
    break;
  default:
    return 'FORMULA';
  }
};
export default getTypeByHeaderName;
