import { API } from 'aws-amplify';
import {
  ROWS_DATA,
  UNIVERSAL_ROWS_QUICK_INDEX,
} from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
export default {
  getStructuredScheduleRows(variables) {
    return new requestBuilder({
      requestName: 'getStructuredScheduleRows',
      requestVariables: {
        scheduleId: 'String!',
        viewId: 'String!',
        projectId: 'String!',
        limit: 'Int',
        nextToken: 'String',
        versionId: 'String',
        sharedLink: 'Boolean',
        workspaceId: 'String',
      },
      response: ROWS_DATA,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  // use for quick index
  getStructuredScheduleRowsV2(variables) {
    return new requestBuilder({
      requestName: 'getStructuredScheduleRowsV2',
      requestVariables: {
        viewId: 'String!',
        projectId: 'String!',
        limit: 'Int',
        nextToken: 'String',
        versionId: 'String',
        sharedLink: 'Boolean',
        workspaceId: 'String',
      },
      response: UNIVERSAL_ROWS_QUICK_INDEX,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  createEmptyRow(variables) {
    const query = `
      mutation createEmptyRow (
        $tableId: String!
        $tableType: TableType!
        $projectId: String
        $workspaceId: String
      ) {
        response: createEmptyRow (
          tableId: $tableId
          tableType: $tableType
          projectId: $projectId
          workspaceId: $workspaceId
        ) {
          fields {
            name
            value
          }
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  deleteRow(variables) {
    const query = `
      mutation deleteRow (
        $tableId: String!
        $tableType: TableType!
        $rowId: String!
        $projectId: String
        $workspaceId: String
      ) {
        response: deleteRow (
          tableId: $tableId
          tableType: $tableType
          rowId: $rowId
          projectId: $projectId
          workspaceId: $workspaceId
        ) {
          fields {
            name
            value
          }
          tableId
          tableType
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  duplicateRow(variables) {
    const query = `
      mutation duplicateRow (
        $tableId: String!
        $tableType: TableType!
        $rowId: String!
        $projectId: String!
        $workspaceId: String
      ) {
        response: duplicateRow (
          tableId: $tableId
          tableType: $tableType
          rowId: $rowId
          projectId: $projectId
          workspaceId: $workspaceId
        ) {
          fields {
            name
            value
          }
          tableId
          tableType
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getRowsByTags(variables) {
    return new requestBuilder({
      requestName: 'getRowsByTags',
      requestType: 'mutation',
      requestVariables: {
        projectId: 'String!',
        scheduleId: 'String!',
        tags: '[String!]',
        sortingField: 'String',
        workspaceId: 'String',
      },
      response: ROWS_DATA,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  listDeletedRows(variables) {
    return new requestBuilder({
      requestName: 'listDeletedRows',
      requestVariables: {
        projectId: 'String!',
        scheduleId: 'String!',
        viewId: 'String!',
        limit: 'Int',
        nextToken: 'String',
        workspaceId: 'String',
      },
      response: `
        data {
          fields {
            name
            value
          }
        }
        nextToken
      `,
      variables,
    }).processRequest();
  },
  restoreRows(variables) {
    const query = `
      mutation restoreRows (
        $scheduleId: String!
        $projectId: String!
        $rowsIds: [String!]!
        $workspaceId: String
      ) {
        response: restoreRows (
          scheduleId: $scheduleId
          projectId: $projectId
          rowsIds: $rowsIds
          workspaceId: $workspaceId
        )
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  manageRowVisibility(variables, criteria) {
    const query = `
      mutation ${criteria} (
        $projectId: String!
        $tableType: TableType!
        $tableId: String!
        $rowId: String!
        $workspaceId: String
      ) {
        response: ${criteria} (
          projectId: $projectId
          tableType: $tableType
          tableId: $tableId
          rowId: $rowId
          workspaceId: $workspaceId
        ) {
          fields {
            name
            value
          }
          tableId
          tableType
        }
      }`;
    return API.graphql({
      query,
      variables,
    });
  },
};
