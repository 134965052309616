export default {
  Auth: {
    identityPoolId: 'ca-central-1:32878c27-2cdb-4b00-8b10-d86f48841b96',
    region: 'ca-central-1',
    userPoolId: 'ca-central-1_yx8aV7yo8',
    userPoolWebClientId: '5jvoav2l54229j79oe3atj29tr',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  API: {
    aws_appsync_graphqlEndpoint: 'https://li72w6uzevgbbmptn6etdxers4.appsync-api.ca-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'ca-central-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  Storage: {
    AWSS3: {
      bucket: 'lightning-prod-assets-storage',
      region: 'ca-central-1',
    },
  },
  oauth: {
    domain: 'auth.gosourcery.com',
  },
};
