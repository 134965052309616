import MyPageApi from '@/services/graphql/myPage';
import { IS_STORE_ROOT } from '@/constants';
export const actions = {
  async getListOfPages({ commit, rootState, rootGetters }) {
    const mutationName = rootGetters['FeatureFlags/useSkeleton'] ? 'setIsLoadingRowData' : 'spinner';
    const skipSpinner = rootGetters['FeatureFlags/useLazyLoading'] && mutationName === 'spinner';
    if (!skipSpinner) commit(mutationName, true, IS_STORE_ROOT);
    try {
      const { data } = await MyPageApi.listFollowedWorkspacePages({
        workspaceId: rootState.Workspace.activeWorkspaceId,
      });
      const { data: pages = [] } = data.response || {
      };
      commit('setPagesList', pages);
    } catch (err) {
      console.log('err get page list', err);
    } finally {
      if (!skipSpinner) commit(mutationName, false, IS_STORE_ROOT);
    }
  },
  async followPage({ commit, rootState, rootGetters }, payload) {
    const mutationName = rootGetters['FeatureFlags/useSkeleton'] ? 'setIsLoadingRowData' : 'spinner';
    commit(mutationName, true, IS_STORE_ROOT);
    try {
      const {
        pageId = '',
        pageWorkspaceId = '',
        workspaceId = '',
      } = payload ?? {
      };
      await MyPageApi.followWorkspacePage({
        workspaceId: workspaceId ? workspaceId : rootState.Workspace.activeWorkspaceId,
        pageId,
        pageWorkspaceId,
      });
    } catch (e) {
      console.log('err followPage', e);
    } finally {
      commit(mutationName, false, IS_STORE_ROOT);
    }
  },
  async unfollowPage({ commit, rootState, rootGetters }, payload) {
    const mutationName = rootGetters['FeatureFlags/useSkeleton'] ? 'setIsLoadingRowData' : 'spinner';
    commit(mutationName, true, IS_STORE_ROOT);
    try {
      const {
        pageId = '',
        workspaceId = '',
      } = payload ?? {
      };
      await MyPageApi.unfollowWorkspacePage({
        workspaceId: workspaceId ? workspaceId : rootState.Workspace.activeWorkspaceId,
        pageId,
      });
    } catch (e) {
      console.log('err unfollowPage', e);
    } finally {
      commit(mutationName, false, IS_STORE_ROOT);
    }
  },
  async subscribeFollowedWorkspacePage({ rootState, dispatch, commit, state }) {
    const nextSub = ({ value }) => {
      const {
        data = {
          response: {
          },
        },
      } = value ?? {
      };
      const {
        response = {
        },
      } = data ?? {
      };
      const { id = '', status = '' } = response ?? {
      };
      if (status === 'deleted') {
        commit('removePageFromPageList', id);
      }
      if (status === 'active') {
        const { pageList: arr } = state;
        const isPageExist = arr.some(({ id: pageId } = {
        }) => pageId === id);
        if (!isPageExist) {
          commit('addPageToPageList', response);
        }
      }
    };
    const subscription = await MyPageApi.subscribeFollowedWorkspacePage({
      workspaceId: rootState.Workspace.activeWorkspaceId,
    }, nextSub);
    subscription.subscribe({
      next: nextSub,
    });
    dispatch('ManageSubscriptions/setSubscription', {
      subscription,
      title: 'Product Follow',
    }, IS_STORE_ROOT);
  },
  async suggestWorkspacePage( { commit, dispatch }, {
    selectedUsers,
    workspaceId,
    workspacePageId,
    pageWorkspaceId,
    emailMessage = '',
  }) {
    await Promise.allSettled(selectedUsers.map(async user => {
      if (typeof user === 'object') {
        await MyPageApi.shareWorkspacePage({
          workspaceId,
          workspacePageId,
          pageWorkspaceId,
          email: user.email,
          emailMessage: emailMessage ?? '',
          sharingUserId: user.id,
        });
      } else {
        await MyPageApi.inviteUserToWorkspacePage({
          workspaceId,
          workspacePageId,
          pageWorkspaceId,
          email: user,
          emailMessage: emailMessage ?? '',
        });
      }
    })).catch((err) => {
      dispatch('handleError', err, IS_STORE_ROOT);
    }).finally(() => {
      commit('spinner', false, IS_STORE_ROOT);
    });
  },
};
