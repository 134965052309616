import { DELETED } from '@/constants';
export const getters = {
  compareVersionLength(state) {
    return state.compareVersion.length;
  },
  compareListWithVersionsLength(state) {
    return state.compareListWithVersions.length;
  },
  deletedComparedRows(state) {
    const { compareListWithVersions: data } = state;
    return data.reduce((result, { fields = [], status, rowId } = {
    }) => {
      if (status !== DELETED || !fields) return result;
      return [...result, {
        fields,
        rowId,
      }];
    }, []);
  },
  compareColumnsLength(state) {
    return state.compareColumns.length;
  },
  isResultCompare(state, getters) {
    const { compareColumnsLength, compareListWithVersionsLength } = getters;
    const { compareVersion, compareReference } = state;
    return compareColumnsLength && compareListWithVersionsLength && (compareVersion || compareReference);
  },
};
