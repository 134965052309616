import requestBuilder from '@/services/graphql/requestBuilder';
import { ASYNC_SEARCH_RESULT } from './fragments';
import { GRAPHQL_TYPES } from '@/constants/cores';
const PROJECT_CELLS_MATCH = `
  field
  value
`;
const SEARCH_PROJECT_CELLS_MATCH = `
  rowId
  scheduleId
  status
  matches {
    ${PROJECT_CELLS_MATCH}
  }
`;
const SEARCH_PROJECT_CELLS_CONNECTION = `
  data {
    ${SEARCH_PROJECT_CELLS_MATCH}
  }
  projectId
  scrollID
  query
`;
export default {
  searchProjectCells(variables) {
    return new requestBuilder({
      requestName: 'searchProjectCells',
      requestVariables: {
        query: 'String!',
        projectId: 'String!',
        versionId: 'String',
        workspaceId: 'String!',
        viewId: 'String!',
        limit: 'Int',
        scrollID: 'String',
      },
      response: ASYNC_SEARCH_RESULT,
      variables,
    }).processRequest();
  },
  subscribeSearchProjectCellsResult(variables) {
    return new requestBuilder({
      requestName: 'subscribeSearchProjectCellsResult',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        projectId: 'String!',
        username: 'String!',
        query: 'String',
        viewId: 'String',
        ...(variables?.versionId && {
          versionId: 'String',
        }),
      },
      response: SEARCH_PROJECT_CELLS_CONNECTION,
      variables,
    }).processRequest();
  },
};
