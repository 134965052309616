const EMBED_HEIGHT = '100%';
const EMBED_WIDTH = '100%';
const COLLECTION_NAME = 'collection-name';
const COLLECTION_PHOTOS = 'collection-photos';
const COLLECTION_PRODUCTS = 'collection-products';
const QUERY_ALIAS_TO_PROPS = {
  [COLLECTION_NAME]: 'collectionName',
  [COLLECTION_PHOTOS]: 'collectionPhotos',
  [COLLECTION_PRODUCTS]: 'collectionProducts',
};
export {
  EMBED_HEIGHT,
  EMBED_WIDTH,
  QUERY_ALIAS_TO_PROPS,
  COLLECTION_PHOTOS,
  COLLECTION_PRODUCTS,
  COLLECTION_NAME,
};
