import { API } from 'aws-amplify';
import {
  COLOR_DETAIL, ICON_DETAIL,
} from './fragments';
const PALLETE = `
  {
    default_colors ${COLOR_DETAIL}
    default_icons ${ICON_DETAIL}
  }
`;
export default {
  getColorIconPalette(variables) {
    const query = `
        query getColorIconPalette (
          $prefix: String
          $id: String
        ) {
          response: getColorIconPalette(
              prefix: $prefix
              id: $id
            ) ${PALLETE}
        }
      `;
    return API.graphql({
      query,
      variables,
    });
  },
  setColorIcon(variables) {
    const query = `
      mutation setColorIcon (
        $projectId: String!
        $colorId: String
        $iconId: String
        $workspaceId: String
      ) {
        response: setColorIcon (
          projectId: $projectId
          colorId: $colorId
          iconId: $iconId
          workspaceId: $workspaceId
        )
          {
            projectColor ${COLOR_DETAIL}
            projectIcon ${ICON_DETAIL}
          }
      }`;
    return API.graphql({
      query,
      variables,
    });
  },
};
