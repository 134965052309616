import ProjectVersionsApi from '@/services/graphql/projectVersions';
export const actions = {
  async getComparingColumns({ commit, dispatch }) {
    try {
      const { data } = await ProjectVersionsApi.getComparisonFields();
      commit('setComparingColumns', data.response);
    } catch (err) {
      dispatch('handleError', err);
    }
  },
  setAllItemsForCompare({ commit }, data = []) {
    commit('clearModifiedRow');
    if (!data) return;
    const mappedData = data.map(item => {
      const { SK = '' } = item ?? {
      };
      const [, rowId] = SK.split('ROW#') ?? [];
      return {
        ...item,
        rowId,
      };
    });
    commit('setCompareListWithVersions', mappedData);
  },
};
