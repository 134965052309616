// search queries
const SEARCH_COLLECTIONS_V3 = 'searchCollectionsV3';
const SEARCH_COMMUNITY_COLLECTIONS_V3 = 'searchCommunityCollectionsV3';
const SEARCH_PRODUCTS_V2 = 'searchProductsV2';
const SEARCH_PRODUCTS_V3 = 'searchProductsV3';
const SEARCH_COMMUNITY_PRODUCTS_V3 = 'searchCommunityProductsV3';
const SEARCH_PRODUCT_REF_V2 = 'searchProductRefv2';
const SEARCH_PRODUCT_REF_V3 = 'searchProductRefV3';
const SEARCH_WORKSPACE_PAGES = 'searchWorkspacePages';
const SEARCH_FOLLOWED_PAGES = 'searchFollowedPages';
// items criteria
const PRODUCTS_CRITERIA = 'products';
const COLLECTIONS_CRITERIA = 'collections';
const WORKSPACE_PAGES_CRITERIA = 'wsPages';
const SEARCH_V2_QUERIES = {
  [SEARCH_PRODUCTS_V2]: PRODUCTS_CRITERIA,
  [SEARCH_COLLECTIONS_V3]: COLLECTIONS_CRITERIA,
  [SEARCH_COMMUNITY_COLLECTIONS_V3]: COLLECTIONS_CRITERIA,
  [SEARCH_PRODUCTS_V3]: PRODUCTS_CRITERIA,
  [SEARCH_COMMUNITY_PRODUCTS_V3]: PRODUCTS_CRITERIA,
  [SEARCH_WORKSPACE_PAGES]: WORKSPACE_PAGES_CRITERIA,
  [SEARCH_FOLLOWED_PAGES]: WORKSPACE_PAGES_CRITERIA,
};
export {
  SEARCH_V2_QUERIES,
  SEARCH_COMMUNITY_COLLECTIONS_V3,
  SEARCH_PRODUCTS_V2,
  SEARCH_COMMUNITY_PRODUCTS_V3,
  SEARCH_PRODUCT_REF_V2,
  SEARCH_WORKSPACE_PAGES,
  SEARCH_FOLLOWED_PAGES,
  PRODUCTS_CRITERIA,
  WORKSPACE_PAGES_CRITERIA,
  COLLECTIONS_CRITERIA,
  SEARCH_COLLECTIONS_V3,
  SEARCH_PRODUCTS_V3,
  SEARCH_PRODUCT_REF_V3,
};
