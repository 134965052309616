import FileExtensions from '@/constants/fileExtensions';
const getFileExt = filename => {
  if (!filename) {
    return '';
  }
  const basename = filename.split(/[\\/]/).pop();
  const pos = basename.lastIndexOf('.');
  if (basename === '' || pos < 1) {
    return ''; //  `.` not found (-1) or comes first (0)
  }
  return basename.slice(pos + 1); // extract extension ignoring `.`
};
const isIesExt = filename => getFileExt(filename) === FileExtensions.FILE_IES;
const isZipExt = filename => getFileExt(filename) === FileExtensions.FILE_ZIP;
const isXlsxExt = filename => getFileExt(filename) === FileExtensions.FILE_XLSX;
const isDocxExt = filename => getFileExt(filename) === FileExtensions.FILE_DOCX;
const isPhotometricExt = filename => {
  return isZipExt(filename) || isIesExt(filename);
};
export {
  getFileExt,
  isIesExt,
  isPhotometricExt,
  isZipExt,
  isXlsxExt,
  isDocxExt,
};
