import { getDefaultState } from './state';
export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  showSearchLoader(state, payload) {
    state.searchingLoader = payload;
  },
  setSearchingResult(state, payload) {
    state.searchingResult = payload;
  },
  setCurrentIndexResultItem(state, payload) {
    state.currentIndexResultItem = payload;
  },
  setSearchString(state, payload) {
    state.searchString = payload;
  },
  clearSearchString(state) {
    state.searchString = null;
  },
};
