import ProcoreApi from '@/services/graphql/procore';
export const actions = {
  async getProcoreAccountsList({ commit }) {
    try {
      let { data } = await ProcoreApi.getProcoreAccountsList();
      let accountsList = data.listProcoreAccounts;
      commit('setProcoreAccountsList', accountsList);
    } catch (err) {
      console.log('getProcoreAccountsList err', err);
    }
  },
};
