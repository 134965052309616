import { ROW_DATA } from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
export default {
  getRowDiffProduct(variables) {
    return new requestBuilder({
      requestName: 'getRowDiffProduct',
      requestVariables: {
        tableId: 'String!',
        tableType: 'TableType!',
        rowId: 'String!',
        rowVersionId: 'String',
        compareVersion: 'String',
        workspaceId: 'String',
      },
      response: ROW_DATA,
      variables,
    }).processRequest();
  },
};
