import { IS_STORE_ROOT } from '@/constants';
import { PAYMENT_IS_PROCESSING } from '@/constants/notificationMessages';

export const actions = {
  paymentIsProcessingNotification({ commit }) {
    commit(
      'openSnackBar',
      {
        title: PAYMENT_IS_PROCESSING,
        dataTest: 'payment_processing_popup',
      },
      IS_STORE_ROOT
    );
  },
};
