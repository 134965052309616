import { getDefaultState } from './state';
export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setWorkspacesList(state, payload) {
    state.workspacesList = payload;
  },
  setWorkspacesListForFollowing(state, payload) {
    state.workspacesListForFollowing = payload;
  },
  setWorkspacePagesForSpecifiedWs(state, payload) {
    state.workspacePagesForSpecifiedWs = payload;
  },
  setActiveWorkspaceId(state, payload) {
    state.activeWorkspaceId = payload;
  },
  setMemberUsers(state, payload) {
    state.memberUsers = payload;
  },
  setGuestUsers(state, payload) {
    state.guestUsers = payload;
  },
  setInvitedUsersForShareWorkspace(state, payload) {
    state.invitedUsersForShareWorkspace = payload;
  },
  setNeedUserPremium(state, payload) {
    state.userPremium = payload;
  },
  setIsNeedRedirect(state, payload) {
    state.needRedirect = payload;
  },
  setActiveWorkspaceLogoUrl(state, payload) {
    state.activeWorkspaceLogoUrl = payload;
  },
  setWorkspacePagePublished(state, payload) {
    state.workspacePagePublished = payload;
  },
  setIsFetching(state, payload) {
    state.isFetching = {
      ...state.isFetching, ...payload,
    };
  },
};
