export const getDefaultState = () => {
  return {
    detailProjectData: null,
    projectColor: null,
    projectIcon: null,
    workspaceLogoToProject: null,
    associatedDocuments: {
      submittals: [],
      designDocument: [],
    },
    sidebarComponent: null,
  };
};
export const state = getDefaultState();
