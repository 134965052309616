import { API } from 'aws-amplify';
import { DROPDOWN_VALUES } from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
export default {
  /**
   * Update a cell in a project.
   *
   * @param {Object} variables - The variables for the mutation.
   * @param {string} [variables.value] - The new value for the cell (optional).
   * @param {string[]} [variables.values] - An array of new values for multiple cells (optional).
   * @param {Object} variables.row - The row information for the cell.
   * @param {string} variables.col - The column identifier for the cell.
   * @param {string} variables.projectId - The ID of the project containing the cell.
   * @param {string} [variables.workspaceId] - The ID of the workspace (optional).
   * Check the version of a cell and optionally force an update.
   *
   * @param {string} [variables.version] - The version of the cell (optional).
   *                                      Format: "version+session" or "version-Decimal session-String" (e.g., "10.1+444").
   *                                      To force an update in subscribePutRow result, add DEFAULT_FORCE_UPDATE to session like "DEFAULT_FORCE_UPDATE&444".
   * @returns {Object} - The response from the updateCell mutation containing proposedCells.
   */
  updateCell(variables) {
    const query = `
      mutation updateCell (
        ${variables.value ? '$value: String' : '$values: [String]'}
        $row: RowInput!
        $col: String!
        $projectId: String!
        $workspaceId: String
        $version: String
      ) {
        response: updateCell(
          ${variables.value ? 'value: $value' : 'values: $values'}
          row: $row,
          col: $col,
          projectId: $projectId
          workspaceId: $workspaceId
          version: $version
        ) {
          proposedCells
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getDropdownValues(variables) {
    const query = `
      query getDropdownValues (
        $col: String!
        $projectId: String
        $scheduleId: String
      ) {
        response: getDropdownValues(
          col: $col
          projectId: $projectId
          scheduleId: $scheduleId
        ) ${DROPDOWN_VALUES}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getAvailReferenceFields() {
    const query = `
      query getAvailReferenceFields {
        response: getAvailReferenceFields
      }
    `;
    return API.graphql({
      query,
    });
  },
  getProductReferencePreselectedFields() {
    return new requestBuilder({
      requestName: 'getProductReferencePreselectedFields',
    }).processRequest();
  },
};
