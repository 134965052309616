import { LIBRARIES_SORT_ITEMS } from '@/constants/sortable';

export const getDefaultState = () => {
  return {
    librariesList: [],
    isFetchingLibraries: false,
    sortingMode: LIBRARIES_SORT_ITEMS.find(el => el.default),
  };
};
export const state = getDefaultState();
