import Vue from 'vue';
import { isEqual } from 'lodash';
export const mutations = {
  setScheduleSessionUuid(state, payload) {
    state.scheduleSessionUuid = payload;
  },
  setScheduleId(state, payload) {
    state.scheduleId = payload;
  },
  setSchema(state, payload) {
    state.schema = payload;
  },
  setActiveCellHeader(state, payload) {
    state.activeCellHeader = payload;
  },
  setSelectedCells(state, payload) {
    state.selectedCells = payload;
  },
  setEditCellEditMode(state, payload) {
    state.isActiveEditMode = payload;
  },
  setDisableKeyboardController(state, payload) {
    state.isDisableKeyboardController = payload;
  },
  setCopiedContent(state, payload) {
    state.copiedContent = payload;
  },
  setLibraryId(state, payload) {
    state.libraryId = payload;
  },
  setProjectPermissions(state, payload) {
    state.projectPermissions = payload;
  },
  setCellPointer(state, payload) {
    state.cellPointer = payload;
  },
  setCreatorLogo(state, payload) {
    state.creatorLogo = payload;
  },
  setCreatorLogoKey(state, payload) {
    state.creatorLogoKey = payload;
  },
  setAvailReferenceFields(state, payload) {
    state.availReferenceFields = payload;
  },
  setDefaultFields(state, payload) {
    state.defaultFields = payload;
  },
  setReadOnlyFields(state, payload) {
    state.readOnlyFields = payload;
  },
  setProjectTags(state, payload) {
    state.projectTags = payload;
  },
  setCommentsModal(state) {
    state.showCommentsModal = !state.showCommentsModal;
  },
  clearCommentsModal(state) {
    state.showCommentsModal = false;
  },
  setModalMode(state, payload = '') {
    state.modalMode = payload;
  },
  setModalRowId(state, payload) {
    state.modalRowId = payload;
  },
  setModalType(state, payload) {
    state.modalType = payload;
  },
  initHoveredColumns(state, payload) {
    state.hoveredColumn = payload;
  },
  setHoveredColumn(state, { name, isResized = false, active = false, clicked = false }) {
    if (!name) return;
    const hoveredColumn = {
      isResized,
      active,
      clicked,
    };
    if (isEqual(hoveredColumn, state.hoveredColumn[name])) return;
    Vue.set(state.hoveredColumn, name, hoveredColumn);
  },
};
