import requestBuilder from '@/services/graphql/requestBuilder';
import { RESPONSE } from './fragments';
import { GRAPHQL_TYPES } from '@/constants/cores';
const PDF_DATA = `
  pdfName
  downloadPdfName
  bucketName
`;
const PDF_PROGRESS = `
  pdfName
  bucketName
`;
const FILE_EXPORTER_RESPONSE = `
  requestId
`;
const FILE_DATA = `
  fileName
  downloadFileName
  bucketName
  requestId
  username
  projectId
  error
  errorMessage

`;
export default {
  downloadProjectPDF(variables) {
    return new requestBuilder({
      requestName: 'downloadProjectPDF',
      requestType: 'mutation',
      requestVariables: {
        tableId: 'String!',
        tableType: 'TableType!',
        templateName: 'String!',
        projectId: 'String!',
        viewId: 'String!',
        versionId: 'String',
        pdfType: 'String',
        fileFormat: 'fileFormat',
        primaryVersionId: 'String',
        secondaryVersionId: 'String',
        workspaceId: 'String',
        columns: '[String]',
      },
      response: PDF_DATA,
      variables,
    }).processRequest();
  },
  // new export request according to cut sheet export timeout problem
  exportProject(variables) {
    return new requestBuilder({
      requestName: 'exportProject',
      requestType: 'mutation',
      requestVariables: {
        tableId: 'String!',
        tableType: 'TableType!',
        templateName: 'String!',
        projectId: 'String!',
        viewId: 'String!',
        versionId: 'String',
        pdfType: 'String',
        fileFormat: 'fileFormat',
        primaryVersionId: 'String',
        secondaryVersionId: 'String',
        workspaceId: 'String',
        columns: '[String]',
      },
      response: RESPONSE,
      variables,
    }).processRequest();
  },
  // new export request according to error execution
  fileExporterInvoke(variables) {
    return new requestBuilder({
      requestName: 'fileExporterInvoke',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        tableId: 'String!',
        tableType: 'TableType!',
        exportType: 'ExportType',
        templateName: 'String!',
        projectId: 'String!',
        viewId: 'String!',
        versionId: 'String',
        pdfType: 'String',
        fileFormat: 'fileFormat',
        primaryVersionId: 'String',
        secondaryVersionId: 'String',
        workspaceId: 'String',
        columnName: 'String',
        columns: '[String]',
        sharedLink: 'Boolean',
      },
      response: FILE_EXPORTER_RESPONSE,
      authMode: 'AWS_IAM',
      variables,
    }).processRequest();
  },
  // new export subscription request according to error execution
  subscribeNotifyExportFiles(variables) {
    return new requestBuilder({
      requestName: 'subscribeNotifyExportFiles',
      requestType: 'subscription',
      requestVariables: {
        requestId: 'String!',
      },
      response: FILE_DATA,
      authMode: 'AWS_IAM',
      variables,
    }).processRequest();
  },
  // Subscription to the response that previously came from the downloadProjectPDF request
  subscribeNotifyExportFileData(variables) {
    return new requestBuilder({
      requestName: 'subscribeNotifyExportFileData',
      requestType: 'subscription',
      requestVariables: {
        projectId: 'String!',
        username: 'String!',
      },
      response: PDF_DATA,
      variables,
    }).processRequest();
  },
  subscribeExportSchedule(variables) {
    return new requestBuilder({
      requestName: 'subscribePdfDownloadProgressEvent',
      requestType: 'subscription',
      requestVariables: {
        pdfName: 'String!',
      },
      response: PDF_PROGRESS,
      variables,
    }).processRequest();
  },
};
