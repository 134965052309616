import productHeaders from '../productHeaders';
// searching fields
const MODEL = 'model';
const KEYWORD = 'keyword';
const PRODUCT_TYPE = 'productType';
const ENVIRONMENT = 'environment';
const MANUFACTURER = 'manufacturer';
const LOCATION = 'location';
const COLLECTION_NAME = 'collectionName';
const MOUNTING = 'mounting';
const DESCRIPTION = 'description';
const VERIFIED = 'isVerified';
// filters for searching products
const PRODUCT_FILTERS_WITHOUT_KEYWORD = [
  MANUFACTURER,
  MODEL,
  PRODUCT_TYPE,
  ENVIRONMENT,
];
const PRODUCT_FILTERS = [
  ...PRODUCT_FILTERS_WITHOUT_KEYWORD,
  KEYWORD,
];
const PRODUCT_AND_COLLECTION_FILTERS = [
  ...PRODUCT_FILTERS,
  LOCATION,
  COLLECTION_NAME,
  KEYWORD,
  VERIFIED,
];
const PRODUCT_FILTERS_FOR_KEYWORD = [
  ...PRODUCT_FILTERS_WITHOUT_KEYWORD,
  MOUNTING,
  DESCRIPTION,
];
const WORKSPACE_PAGE_FILTERS = [
  VERIFIED,
];
// matching criteria
const FULL_MATCH = 'full_match';
const PART_MATCH = 'part-match';
const FUZZY_MATCH = 'fuzzy-match';
// basic Options for Fuse.js(https://fusejs.io/)
const BASIC_FUSE_OPTIONS = {
  useExtendedSearch: true,
  threshold: 0.0,
};
// Logical Query Operators https://fusejs.io/
const OPERATOR_AND = '$and';
const OPERATOR_OR = '$or';
// Extended Search (fine-tune results) https://fusejs.io/
const FINE_TUNE_RESULTS = {
  [PART_MATCH]: `'`,
  [FULL_MATCH]: `=`,
  [FUZZY_MATCH]: ``,
};
// filters match config
const FILTERS_MATCH_CONFIG = {
  [MANUFACTURER]: FULL_MATCH,
  [MODEL]: PART_MATCH,
  [PRODUCT_TYPE]: FULL_MATCH,
  [ENVIRONMENT]: FULL_MATCH,
  [LOCATION]: PART_MATCH,
  [COLLECTION_NAME]: PART_MATCH,
  [VERIFIED]: FULL_MATCH,
};
const SPECIFIC_MATCHES_OF_PRODUCT_VALUES_IN_THE_DATABASE = {
  [MODEL]: productHeaders.MODEL,
  [PRODUCT_TYPE]: productHeaders.PRODUCT_TYPE,
  [ENVIRONMENT]: productHeaders.ENVIRONMENT,
  [MOUNTING]: productHeaders.MOUNTING,
  [MANUFACTURER]: productHeaders.MANUFACTURER,
  [DESCRIPTION]: productHeaders.DESCRIPTION,
  [VERIFIED]: productHeaders.VERIFIED,
};
const SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE = {
  ...SPECIFIC_MATCHES_OF_PRODUCT_VALUES_IN_THE_DATABASE,
};
export {
  PRODUCT_FILTERS,
  PRODUCT_AND_COLLECTION_FILTERS,
  WORKSPACE_PAGE_FILTERS,
  MANUFACTURER,
  MODEL,
  KEYWORD,
  PRODUCT_TYPE,
  ENVIRONMENT,
  LOCATION,
  COLLECTION_NAME,
  FILTERS_MATCH_CONFIG,
  SPECIFIC_MATCHES_OF_PRODUCT_VALUES_IN_THE_DATABASE,
  SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE,
  FULL_MATCH,
  PART_MATCH,
  OPERATOR_AND,
  OPERATOR_OR,
  FINE_TUNE_RESULTS,
  FUZZY_MATCH,
  PRODUCT_FILTERS_FOR_KEYWORD,
  BASIC_FUSE_OPTIONS,
  VERIFIED,
};