import { API } from 'aws-amplify';
import { ROW_DATA } from './fragments';
import { GRAPHQL_TYPES } from '@/constants/cores';
import requestBuilder from '@/services/graphql/requestBuilder';
const PROJECT_VERSION = `
  {
    title
    id
    projectId
    shortName
    versionDate
    scheduleId
    createdAt
    status
  }
`;
export default {
  listProjectVersions(variables) {
    const query = `
      query listProjectVersions (
        $projectId: String!
        $workspaceId: String
      ) {
        response: listProjectVersions(
          projectId: $projectId
          workspaceId: $workspaceId
        ) {
            data ${PROJECT_VERSION}
            amount
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  createProjectVersion(variables) {
    const query = `
      mutation createProjectVersion (
        $projectId: String!
        $title: String!
        $shortName: String
        $versionDate:String
        $workspaceId: String
      ){
        response: createProjectVersion (
          projectId: $projectId
          title: $title
          versionDate:$versionDate
          shortName:$shortName
          workspaceId: $workspaceId
        ) ${PROJECT_VERSION}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  archiveProjectVersion(variables) {
    return new requestBuilder({
      requestName: 'archiveProjectVersion',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        projectId: 'String!',
        versionId: 'String!',
        workspaceId: 'String',
      },
      response: `
        id
        status
      `,
      variables,
    }).processRequest();
  },
  unarchiveProjectVersion(variables) {
    return new requestBuilder({
      requestName: 'unarchiveProjectVersion',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        projectId: 'String!',
        versionId: 'String!',
        workspaceId: 'String',
      },
      response: `
        id
        status
      `,
      variables,
    }).processRequest();
  },
  updateProjectVersion(variables) {
    const query = `
      mutation updateProjectVersion (
        $projectId: String!
        $versionId: String!
        $title: String
        $shortName: String
        $versionDate: String
        $workspaceId: String
      ) {
        response: updateProjectVersion(
          projectId: $projectId
          versionId: $versionId
          title: $title
          shortName: $shortName
          versionDate: $versionDate
          workspaceId: $workspaceId
        ) {
            title
            id
            shortName
            versionDate
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  deleteProjectVersion(variables) {
    const query = `
      mutation deleteProjectVersion (
        $projectId: String!
        $versionId: String!
        $workspaceId: String
      ) {
        response: deleteProjectVersion(
          projectId: $projectId
          versionId: $versionId
          workspaceId: $workspaceId
        ) {
            id
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  compareProjectVersion(variables) {
    const query = `
      mutation compareProjectVersion (
        $projectId: String!
        ${variables.primaryVersionId ? '$primaryVersionId: String!' : ''}
        $secondaryVersionId: String
        $columns: [String]
        $workspaceId: String
      ) {
        response: compareProjectVersion (
          projectId: $projectId
          ${variables.primaryVersionId ? 'primaryVersionId: $primaryVersionId' : ''}
          secondaryVersionId: $secondaryVersionId
          columns: $columns
          workspaceId: $workspaceId
        ) {
          PK
          SK
          modified
          status
          ${ ROW_DATA }
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  compareScheduleWithReferences(variables) {
    const query = `
    mutation compareScheduleWithReferences(
      $projectId: String!
      $scheduleId: String!
      $workspaceId: String
    ) {
      compareScheduleWithReferences(
        projectId: $projectId
        scheduleId: $scheduleId
        workspaceId: $workspaceId
      ) {
        PK
        SK
        modified
        status
        ${ ROW_DATA }
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getComparisonFields() {
    return new requestBuilder({
      requestName: 'getComparisonFields',
    }).processRequest();
  },
};
