import { PERSONAL_WORKSPACE_ID } from '@/constants/index';
import { findAndReplace } from '@/utils/replaceHelper';

/**
 * {name} - header name
 * {list} - getter fn to fetch the data
 * {mappingName} - name used to define the list inside object
 * {request} - fn used to fetch the data
 * {routeName} - used to distinguish where to display the data obj
 */
const LIST_OF_COLLECTIONS_V3 = [
  {
    // collections that user created
    name: 'Workspace Collections',
    mutationName: 'setPrivateCollectionsList',
    routeName: 'collections',
    intersect: true,
    list: 'privateCollectionsList',
    type: 'any',
    group: 'workspace',
    workspaceForRendering: 'custom',
    paginationState: 'collectionPrivateListPageOptions',
    paginationMutation: 'setPrivateCollectionListPageOptions',
    allowGetAllItems: true,
    actions: true,
    showExpanded: false,
    showCreateCollectionButton: true,
    headerIcon: '',
    actionTypes: [
      {
        name: 'CollectionInfo',
        kind: ['default', 'custom'],
      },
      {
        name: 'ShareAdvancedDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionFollow',
        kind: ['default'],
      },
      {
        name: 'SuggestCollectionDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCopy',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionAddToCustomLibrary',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCustomRemove',
        kind: ['custom'],
      },
      {
        name: 'CollectionDelete',
        kind: ['default', 'custom'],
      },
    ],
  },
  {
    // TODO: where is this used?
    name: 'My Collections',
    mutationName: 'setPrivateCollectionsList',
    routeName: 'collections',
    intersect: true,
    list: 'privateCollectionsList',
    type: 'any',
    group: 'private',
    workspaceForRendering: PERSONAL_WORKSPACE_ID,
    paginationState: 'collectionPrivateListPageOptions',
    paginationMutation: 'setPrivateCollectionListPageOptions',
    allowGetAllItems: true,
    actions: true,
    showExpanded: false,
    headerIcon: '',
    actionTypes: [
      {
        name: 'CollectionInfo',
        kind: ['default', 'custom'],
      },
      {
        name: 'ShareAdvancedDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionFollow',
        kind: ['default', 'custom'],
      },
      {
        name: 'SuggestCollectionDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCopy',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionAddToCustomLibrary',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCustomRemove',
        kind: ['custom'],
      },
      {
        name: 'CollectionDelete',
        kind: ['default', 'custom'],
      },
    ],
  },
  {
    name: 'Shared Collections',
    mutationName: 'setSharedCollectionsList',
    routeName: 'collections',
    intersect: true,
    list: 'sharedCollectionsList',
    type: 'any',
    group: 'shared',
    workspaceForRendering: PERSONAL_WORKSPACE_ID,
    paginationState: 'collectionSharedListPageOptions',
    paginationMutation: 'setSharedCollectionListPageOptions',
    allowGetAllItems: true,
    actions: true,
    showExpanded: false,
    headerIcon: '',
    actionTypes: [
      {
        name: 'CollectionInfo',
        kind: ['default', 'custom'],
      },
      {
        name: 'ShareAdvancedDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionFollow',
        kind: ['default', 'custom'],
      },
      {
        name: 'SuggestCollectionDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCopy',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionAddToCustomLibrary',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionDelete',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCustomRemove',
        kind: ['custom'],
      },
    ],
  },
  {
    name: 'Published Collections',
    mutationName: 'setPublishedCollectionsList',
    routeName: 'collections',
    intersect: true,
    list: 'publishedCollectionsList',
    type: 'any',
    group: 'published',
    paginationState: 'collectionPublishListPageOptions',
    paginationMutation: 'setPublishedCollectionListPageOptions',
    allowGetAllItems: true,
    actions: true,
    showExpanded: false,
    headerIcon: '',
    actionTypes: [
      {
        name: 'CollectionInfo',
        kind: ['default', 'custom'],
      },
      {
        name: 'ShareAdvancedDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionFollow',
        kind: ['default', 'custom'],
      },
      {
        name: 'SuggestCollectionDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCopy',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionAddToCustomLibrary',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCustomRemove',
        kind: ['custom'],
      },
      {
        name: 'CollectionDelete',
        kind: ['default', 'custom'],
      },
    ],
  },
  {
    name: 'Followed Pages',
    allowGetAllItems: true,
    actions: true,
    group: 'wsPage',
    headerIcon: '',
    intersect: true,
    list: 'MyPages/getFormattedListOfPages',
    mappingName: 'followedPages',
    mutationName: 'setPagesList',
    paginationState: '',
    paginationMutation: '',
    request: 'MyPages/getListOfPages',
    routeName: 'community-collections',
    rowsToShow: 3,
    showCounter: false,
    showExpanded: true,
    store: 'MyPage',
    type: 'default',
    actionTypes: [
      {
        name: 'PageFollow',
        kind: ['default'],
      },
    ],
  },
  {
    name: 'Followed Collections',
    allowGetAllItems: true,
    actions: true,
    group: 'followed',
    headerIcon: '',
    intersect: true,
    list: 'Collections/getFollowedCollectionsList',
    mappingName: 'followedCollections',
    mutationName: 'setFollowedCollectionsList',
    paginationState: 'collectionFollowedListPageOptions',
    paginationMutation: 'setFollowedCollectionListPageOptions',
    request: 'Collections/getCollectionsList',
    routeName: 'community-collections',
    showExpanded: false,
    store: 'Collections',
    type: 'any',
    actionTypes: [
      {
        name: 'CollectionInfo',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionFollow',
        kind: ['default', 'custom'],
      },
      {
        name: 'SuggestCollectionDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCopy',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCustomRemove',
        kind: ['custom'],
      },
      {
        name: 'CollectionAddToCustomLibrary',
        kind: ['default', 'custom'],
      },
    ],
  },
  {
    // replica of above just for community page
    name: 'Published Collections',
    allowGetAllItems: true,
    actions: true,
    group: 'published',
    headerIcon: '',
    intersect: true,
    list: 'Collections/getPublishedCollectionsList',
    mappingName: 'publishedCollections',
    mutationName: 'setPublishedCollectionsList',
    paginationState: 'collectionPublishListPageOptions',
    paginationMutation: 'setPublishedCollectionListPageOptions',
    request: 'Collections/getCollectionsList',
    routeName: 'community-collections',
    showExpanded: false,
    store: 'Collections',
    type: 'any',
    actionTypes: [
      {
        name: 'CollectionInfo',
        kind: ['default', 'custom'],
      },
      {
        name: 'ShareAdvancedDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionFollow',
        kind: ['default', 'custom'],
      },
      {
        name: 'SuggestCollectionDialog',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCopy',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionAddToCustomLibrary',
        kind: ['default', 'custom'],
      },
      {
        name: 'CollectionCustomRemove',
        kind: ['custom'],
      },
      {
        name: 'CollectionDelete',
        kind: ['default', 'custom'],
      },
    ],
  },
  {
    name: 'Suggestions',
    allowGetAllItems: false,
    actions: false,
    group: 'suggestion',
    headerIcon: '',
    intersect: true,
    list: 'Collections/getCollectionSuggestionsList',
    mappingName: 'suggestions',
    mutationName: 'setCollectionSuggestionsList',
    paginationState: 'collectionSuggestionsListPageOptions',
    paginationMutation: 'setCollectionSuggestionsListPageOptions',
    // TODO: this request is in SubscriptionCommunityPage
    // request: collectionActions.getCollectionSuggestions,
    routeName: 'community-collections',
    rowsToShow: 1,
    showExpanded: true,
    store: 'Collections',
    type: 'default',
    actionTypes: [
      {
        name: 'CollectionFollow',
        kind: ['default'],
      },
      {
        name: 'CollectionCopy',
        kind: ['default'],
      },
      {
        name: 'SuggestionIgnore',
        kind: ['default'],
      },
      {
        name: 'SuggestCollectionDialog',
        kind: ['default', 'custom'],
      },
    ],
  },
  {
    name: 'Verified Pages',
    mutationName: 'setVerifiedWsPages',
    routeName: '',
    intersect: true,
    list: 'verifiedWsPages',
    type: 'default',
    group: 'verifiedWsPages',
    paginationState: 'verifiedWsListPageOptions',
    paginationMutation: 'setVerifiedWsListPageOptions',
    mappingName: 'verifiedWsPages',
    allowGetAllItems: false,
    actions: false,
    showExpanded: true,
    showCounter: false,
    expandedAmount: 12,
    headerIcon: {
      name: 'mdi-checkbox-marked-circle',
      color: 'blue',
    },
    actionTypes: [
      {
        name: 'PageFollow',
        kind: ['default'],
      },
    ],
  },
  {
    name: 'Community Pages',
    headerIcon: {
      name: 'mdi-checkbox-marked-circle',
      color: 'blue',
    },
    expandedAmount: 0,
    rowsToShow: 3,
    mutationName: 'setWsPages',
    routeName: '',
    intersect: true,
    list: 'wsPages',
    type: 'default',
    group: 'wsPage',
    paginationState: 'wsListPageOptions',
    paginationMutation: 'setWsListPageOptions',
    mappingName: 'wsPages',
    allowGetAllItems: false,
    actions: false,
    showExpanded: true,
    showCounter: false,
    actionTypes: [
      {
        name: 'PageFollow',
        kind: ['default'],
      },
    ],
  },
  {
    name: 'Community Collections',
    mutationName: 'setCommunityCollections',
    routeName: '',
    intersect: true,
    list: 'communityCollections',
    type: 'default',
    group: 'community',
    paginationState: 'collectionCommunityListPageOptions',
    paginationMutation: 'setCommunityCollectionListPageOptions',
    mappingName: 'collections',
    allowGetAllItems: false,
    actions: false,
    showExpanded: false,
    headerIcon: '',
    actionTypes: [
      {
        name: 'CollectionFollow',
        kind: ['default'],
      },
      {
        name: 'CollectionCopy',
        kind: ['default'],
      },
      {
        name: 'SuggestCollectionDialog',
        kind: ['default', 'custom'],
      },
    ],
  },
  {
    name: 'Verified Pages',
    mutationName: '',
    list: '',
    group: 'wsPage',
    paginationState: '',
    paginationMutation: '',
    mappingName: 'wsPages',
    routeName: 'verified-community',
    intersect: true,
    type: 'default',
    allowGetAllItems: false,
    actions: false,
    showExpanded: true,
    showCounter: false,
    expandedAmount: 0,
    rowsToShow: 3,
    headerIcon: {
      name: 'mdi-checkbox-marked-circle',
      color: 'blue',
    },
    actionTypes: [
      {
        name: 'PageFollow',
        kind: ['default'],
      },
    ],
  },
  {
    name: 'Verified Community Collections',
    mutationName: 'setCommunityCollections',
    list: 'communityCollections',
    group: 'community',
    paginationState: '',
    paginationMutation: '',
    mappingName: 'collections',
    routeName: 'verified-community',
    intersect: true,
    type: 'default',
    allowGetAllItems: false,
    actions: false,
    showExpanded: false,
    expandedAmount: 0,
    rowsToShow: 3,
    headerIcon: {
      name: 'mdi-checkbox-marked-circle',
      color: 'blue',
    },
    actionTypes: [
      {
        name: 'CollectionFollow',
        kind: ['default'],
      },
      {
        name: 'CollectionCopy',
        kind: ['default'],
      },
      {
        name: 'SuggestCollectionDialog',
        kind: ['default', 'custom'],
      },
    ],
  },
];
const LIST_OF_COLLECTIONS_V3_1 = findAndReplace({
  item: LIST_OF_COLLECTIONS_V3,
  searchBy: {
    name: 'Community Collections',
  },
  replacedItem: {
    alternativeText: 'Published Collections',
    useAlternativeText: true,
  },
});
const NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW = {
  BIGGEST: 4,
  MIDDLE: 3,
  MOBILE_BIG: 2,
  MOBILE_SMALL: 1,
};

const SUGGESTIONS_GROUP = LIST_OF_COLLECTIONS_V3_1.find(item => item.name === 'Suggestions')?.group;
const COMMUNITY_COLLECTIONS_GROUP = LIST_OF_COLLECTIONS_V3_1.find(item => item.name === 'Community Collections')?.group;
const COMMUNITY_PAGES_MAPPING = LIST_OF_COLLECTIONS_V3_1.find(item => item.name === 'Community Pages')?.mappingName;
const COMMUNITY_PAGES_GROUP = LIST_OF_COLLECTIONS_V3_1.find(item => item.name === 'Community Pages')?.group;
const VERIFIED_WS_PAGES_MAPPING = LIST_OF_COLLECTIONS_V3_1.find(item => item.name === 'Verified Pages')?.mappingName;
const VERIFIED_WS_PAGES_GROUP = LIST_OF_COLLECTIONS_V3_1.find(item => item.name === 'Verified Pages')?.group;

const COLLECTIONS_WORKSPACE_GROUP = LIST_OF_COLLECTIONS_V3_1.find(item => item.name === 'Workspace Collections')?.group;
const COLLECTIONS_PUBLISHED_GROUP = LIST_OF_COLLECTIONS_V3_1.find(item => item.name === 'Published Collections')?.group;
const COLLECTIONS_FOLLOWED_GROUP = LIST_OF_COLLECTIONS_V3_1.find(item => item.name === 'Followed Collections')?.group;

export {
  LIST_OF_COLLECTIONS_V3,
  LIST_OF_COLLECTIONS_V3_1,
  NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW,
  SUGGESTIONS_GROUP,
  COMMUNITY_COLLECTIONS_GROUP,
  COMMUNITY_PAGES_GROUP,
  COMMUNITY_PAGES_MAPPING,
  VERIFIED_WS_PAGES_GROUP,
  VERIFIED_WS_PAGES_MAPPING,
  COLLECTIONS_WORKSPACE_GROUP,
  COLLECTIONS_PUBLISHED_GROUP,
  COLLECTIONS_FOLLOWED_GROUP,
};
