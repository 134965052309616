const PROFILE_REQUIRED_FIELDS = [
  {
    value: 'name', title: 'Name',
  },
  {
    value: 'company', title: 'Company',
  },
  {
    value: 'projectTeamRole', title: 'Project Team Role',
  },
  {
    value: 'location', title: 'Location',
  },
];

export { PROFILE_REQUIRED_FIELDS };