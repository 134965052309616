import { extractNumberFromString } from '@/utils';
import productHeaders from '@/constants/productHeaders';
import { NO_TYPE } from '@/constants/projectAggregation';

export const getters = {
  getPowerSumValues(state) {
    return state.aggregationData?.powerSumValues || [];
  },
  getTypeSumValues(state) {
    return state.aggregationData?.typeSumValues || [];
  },
  getTotalCost(state) {
    return state.aggregationData?.totalCost || 0;
  },
  getTotalPower(state) {
    return state.aggregationData?.totalPower || 0;
  },
  getTagTotalCost(state) {
    return state.tagTotal?.TotalCost || 0;
  },
  getActiveTagsTotalCost(state, getters) {
    let total = 0;
    const tagSumValues = getters.getActiveTagsTypeSumValues;
    for (const sv of tagSumValues) {
      total += sv.values;
    }
    return total;
  },
  getActiveTagsTypeSumValues(state, getters, rootState, rootGetters) {
    const rows = rootGetters['ScheduleRows/sortedViewInfo'];
    const sumMap = new Map();
    for (const row of rows) {
      const total = extractNumberFromString(row[productHeaders.TOTAL_COST]);
      const type = row[productHeaders.TYPE_DESIGNATION] ?? NO_TYPE;
      if (sumMap.has(type)) {
        sumMap.set(type, sumMap.get(type) + total);
      } else {
        sumMap.set(type, total);
      }
    }
    const result = [];
    sumMap.forEach((val, key) => {
      if (val != 0) {
        result.push({
          'name': key,
          'values': val,
        });
      }
    });
    return result;
  },

  canSelectedViewTriggerTotalTag(state, getters, rootState, rootGetters) {
    const selectedViewName = rootGetters['ScheduleViews/selectedViewName'];
    if (!selectedViewName) {
      return false;
    }
    const canTrigger = rootGetters['ScheduleViews/isSelectedSustainabilityView']
      || rootGetters['ScheduleViews/isSelectedProjectBudgetView'];
    return canTrigger;
  },
};
