import {
  TYPE_CREATOR, TYPE_CREATOR_ADMIN, TYPE_FOLLOWER, TYPE_OWNER, TYPE_EDITOR, TYPE_VIEWER,
} from '@/constants/userPermissions';
const CREATOR_AND_MORE = [TYPE_CREATOR, TYPE_CREATOR_ADMIN, TYPE_OWNER];
const EDITOR_AND_MORE = [TYPE_EDITOR, TYPE_CREATOR, TYPE_CREATOR_ADMIN, TYPE_OWNER];
const VIEWER_AND_MORE = [TYPE_VIEWER, TYPE_EDITOR, TYPE_CREATOR, TYPE_EDITOR, TYPE_CREATOR, TYPE_CREATOR_ADMIN, TYPE_OWNER];
const FOLLOWER_AND_EDITOR_AND_MORE = [TYPE_FOLLOWER, TYPE_EDITOR, TYPE_CREATOR, TYPE_CREATOR_ADMIN, TYPE_OWNER];
const ROLES_BY_TYPE = {
  [TYPE_EDITOR]: EDITOR_AND_MORE,
  [TYPE_CREATOR]: CREATOR_AND_MORE,
  [TYPE_FOLLOWER]: [TYPE_FOLLOWER],
  [TYPE_VIEWER]: VIEWER_AND_MORE,
};
export {
  CREATOR_AND_MORE,
  FOLLOWER_AND_EDITOR_AND_MORE,
  EDITOR_AND_MORE,
  ROLES_BY_TYPE,
  VIEWER_AND_MORE,
};
