<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="70%"
      max-width="800px"
      transition="scale-transition"
      persistent>
      <v-card>
        <v-card-title class="text-h5 justify-space-between">
          Getting Started with Sourcery
          <span
            class="sourcery__icon-wrapper black"
            @click="closeModal">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text class="text-center">
          <video
            :src="videoUrl"
            controlslist="nodownload"
            height="400"
            width="100%"
            controls />
        </v-card-text>
        <v-card-actions
          class="justify-center gap-6 py-3">
          <v-btn
            color="blue"
            outlined
            @click="bookMeeting">
            Book a success call
          </v-btn>
          <v-btn
            class="white--text elevation-0"
            color="blue"
            @click="closeModal">
            Get Started
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Storage } from 'aws-amplify';
export default {
  name: 'TourModal',
  data () {
    return {
      dialog: true,
      videoUrl: null,
    };
  },
  async created() {
    this.videoUrl = await Storage.get('User Intro Video - Platform Overview.mp4', {
      customPrefix: {
        public: 'public/',
      },
      level: 'public',
    });
  },
  methods: {
    bookMeeting() {
      window.open('https://meetings.hubspot.com/paul-boken/support-call', '_blank');
      this.closeModal();
    },
    closeModal() {
      this.dialog = false;
      // update the value in store and DB
      this.$store.dispatch('disableNewUser');
    },
  },
};
</script>
