import { MAX_CACHE_AGE } from '@/constants/cores';
export const getters = {
  getFileByKey: state => fileKey => {
    return state.cachedFiles.find(({ key }) => fileKey === key)?.url;
  },
  getFullFileByKey: state => fileKey => {
    return state.cachedFiles.find(({ key }) => fileKey === key);
  },
  getFullFileByUrl: state => fileUrl => {
    return state.cachedFiles.find(({ url }) => {
      return fileUrl === url;
    });
  },
  isActiveFile: () => file => {
    const createdAt = file?.createdAt;
    const currentTime = Date.now();
    const leftTime = (currentTime - createdAt) / 1000;
    return leftTime <= MAX_CACHE_AGE;
  },
};
