export const getDefaultState = () => ({
  snackbar: {
    isSnackBarShown: false,
    title: null,
    text: '',
    pdfLink: null,
    timeout: -1,
  },
  exportedFileForPreview: null,
  listOfFilesForSubscriptions: [],
  listOfFilesToNotify: [],
});
export const state = getDefaultState();
