export const mutations = {
  setUserData(state, payload) {
    state.userData = payload;
  },
  setCredentials(state, payload) {
    state.credentials = payload;
  },
  setOwnLogo(state, payload) {
    state.ownLogo = payload;
  },
  setUserAvatar(state, payload) {
    state.userAvatar = payload;
  },
  setUserAvatarKey(state, payload) {
    state.userAvatarKey = payload;
  },
  // if user first time sign up
  setUserSignUp(state, payload) {
    state.isSignUp = payload;
  },
};
