import { API } from 'aws-amplify';
import { USER_INFO } from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
export default {
  getProjectPermissions(variables) {
    return new requestBuilder({
      requestName: 'getProjectPermissions',
      requestVariables: {
        projectId: 'String!',
        workspaceId: 'String',
      },
      response: `
        type
        userInfo ${USER_INFO}
        hideResolvedComments
      `,
      variables,
    }).processRequest();
  },
  managePermission(variables, changedUsers) {
    let str = '';
    let values = `
      $projectId: String!
      $workspaceId: String
    `;
    Object.keys(changedUsers).forEach((item) => {
      changedUsers[item].forEach((user, index) => {
        values += `
        $${item}${index}: String!
        ${item !== 'deletePermission' ? `$${item}Type${index}: PermissionType!` : ''}
        ${item == 'createPermission' || item == 'inviteUserToProject' ? `$emailMessage: String` : ''}
        `;
        str += `
        ${item}${index}: ${item}(
          projectId: $projectId
          workspaceId: $workspaceId
          ${item !== 'inviteUserToProject' ? `username: $${item}${index}` : `email: $${item}${index}`}
          ${item !== 'deletePermission' ? `type:$${item}Type${index}` : ''}
          ${item == 'createPermission' || item == 'inviteUserToProject' ? `emailMessage: $emailMessage` : ''}
          ) {
          type
          userInfo ${USER_INFO}
        }`;
      });
    });
    const query = `
      mutation managePermission (
        ${values}
      ) {
        ${str}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  suggestColloborator(variables, changedUsers) {
    let str = '';
    let values = `
      $projectId: String!
      $type: PermissionType!
      $workspaceId: String
    `;
    Object.keys(changedUsers).forEach((item) => {
      changedUsers[item].forEach((user, index) => {
        values += `
        $${item}${index}: String!`;
        str += `
        ${item}${index}: suggestColloborator(
          projectId: $projectId
          type: $type
          workspaceId: $workspaceId
          ${`email: $${item}${index}`}
        )`;
      });
    });
    const query = `
      mutation suggestColloborator (
        ${values}
      ) {
        ${str}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
};
