
export const getDefaultState = () => {
  return {
    searchingLoader: false,
    searchingResult: [],
    currentIndexResultItem: 0,
    searchString: null,
  };
};
export const state = getDefaultState();
