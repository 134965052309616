import {
  SORT_TOP, SORT_BOTTOM,
} from '@/constants/scheduleViews';
import { cloneDeep } from 'lodash';
export default class SortRows {
  #sortOrder = SORT_TOP;
  #sortBy = '';
  #items = [];
  useLowerCaseSort = true;
  constructor({ sortOrder = SORT_TOP, sortBy, items, useLowerCaseSort = true }) {
    if (!Array.isArray(items)) {
      throw new Error('items must be an array');
    }
    this.useLowerCaseSort = useLowerCaseSort;
    this
      .setSortOrder(sortOrder)
      .setSortBy(sortBy)
      .setItems(cloneDeep(items));
  }
  setSortOrder(sortOrder) {
    if (![SORT_TOP, SORT_BOTTOM].includes(sortOrder)) {
      throw new Error('Please provide right sorting order type');
    }
    this.#sortOrder = sortOrder;
    return this;
  }
  setSortBy(sortBy) {
    this.#sortBy = sortBy;
    return this;
  }
  setItems(items) {
    this.#items = items;
    return this;
  }
  isSortBottom() {
    return this.#sortOrder == SORT_BOTTOM;
  }
  isSortTop() {
    return this.#sortOrder == SORT_TOP;
  }
  getSortingParams() {
    return {
      sortBy: this.#sortBy,
      sortOrder: this.#sortOrder,
      items: cloneDeep(this.#items),
    };
  }
  sort() {
    const { items } = this.getSortingParams();
    return items;
  }
}
