import { USER_INFO_NO_BRACKETS as USER_INFO } from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
const COLLABORATORS = `
  usersData {
    ${USER_INFO}
  }
  projectId
  rowId
`;
export default {
  getRowFollowers(variables) {
    return new requestBuilder({
      requestName: 'getRowFollowers',
      requestVariables: {
        tableId: 'String!',
        tableType: 'TableType!',
        projectId: 'String!',
        rowId: 'String!',
        workspaceId: 'String',
      },
      response: USER_INFO,
      variables,
    }).processRequest();
  },
  addRowFollower(variables) {
    return new requestBuilder({
      requestName: 'addRowFollower',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        tableId: 'String!',
        tableType: 'TableType!',
        projectId: 'String!',
        rowId: 'String!',
        userId: 'String!',
        workspaceId: 'String',
      },
      response: COLLABORATORS,
      variables,
    }).processRequest();
  },
  deleteRowFollower(variables) {
    return new requestBuilder({
      requestName: 'deleteRowFollower',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        tableId: 'String!',
        tableType: 'TableType!',
        projectId: 'String!',
        rowId: 'String!',
        userId: 'String!',
        workspaceId: 'String',
      },
      response: COLLABORATORS,
      variables,
    }).processRequest();
  },
};
