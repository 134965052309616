import { SORTING_MODE_LAST_ACCESSED } from '@/constants/sortable';

export const getters = {
  isUnsuccessfulPayment(state) {
    return state.userData?.paymentStatus === 'unsuccessful';
  },
  identityId(state) {
    const { identityId = '' } = state.credentials || {
    };
    return identityId;
  },
  getName(state) {
    return state.userData?.name;
  },
  projectSortingMode(state) {
    return state.userData?.projectSortingMode;
  },
  collectionSortingMode(state) {
    return state.userData?.collectionSortingMode;
  },
  isSortedCollectionByLastAccessed(state, getters) {
    return getters.collectionSortingMode === SORTING_MODE_LAST_ACCESSED;
  },
  isSortedByLastAccessed(state, getters) {
    return getters.projectSortingMode === 'lastAccessed';
  },
  projectSortingModeAscending(state) {
    return state.userData?.projectSortingModeAscending;
  },
  isUserSignUp(state) {
    return state.isSignUp;
  },
  collectionSortingModeAscending(state) {
    return state.userData?.collectionSortingModeAscending;
  },
  getLocation(state) {
    return state.userData?.location;
  },
};
