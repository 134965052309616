import { formatValue } from './index';
import productHeaders from '@/constants/productHeaders';
import lodash from 'lodash';
/**
 * Parses an array of field objects and converts them into a single object with optional ID rewriting.
 *
 * @param {Array} fields - The array of field objects to parse.
 * @param {number} [index] - The optional index value to set for the resulting object.
 * @param {boolean} [useRewriteId=false] - Whether to rewrite the 'id' field based on 'SK' field.
 * @returns {Object} - The parsed object.
 */
const parseGetRows = (fields, index, useRewriteId = false) => {
  const obj = {
  };
  fields.forEach((column) => {
    Object.keys(column).forEach((item) => {
      if (column[item] !== null && column[item] !== undefined && item !== 'name') {
        if (item !== 'array') {
          if (item === 'attachment' || item === 'airtableImage') {
            obj[column.name] = [column[item]];
          // FIXME: There's a bug somewhere (maybe from older implementation)
          //        that causes isVerified to be sent as a string.
          } else if (column.name == 'isVerified' && item == 'string') {
            obj[column.name] = column[item] == 'true';
          } else {
            obj[column.name] = formatValue(column[item]);
            if (useRewriteId && column.name === 'SK') {
              // Split the 'SK' value to extract the 'id'
              const [, id] = column[item]?.split('ROW#') ?? [];
              obj['id'] = id;
            }
          }
        } else {
          const tempArr = [];
          column[item].forEach((unit) => {
            Object.keys(unit).forEach((i) => {
              const val = formatValue(unit[i]);
              if (val) {
                tempArr.push(val);
              }
            });
          });
          obj[column.name] = tempArr;
        }
      }
    });
  });
  obj.order = index !== undefined ? index + 1 : null;
  return obj;
};

/**
 * Filters and returns the custom rows based on a given tag list.
 * @param {Object} options - The options object.
 * @param {Array} [options.arr=[]] - The array of views to filter.
 * @param {boolean} [options.checkCompareRow=false] - Flag indicating whether to check for compared rows.
 * @param {Array [string]} [options.filterTags] - The tag to filter by.
 * @returns {Array} - The filtered array of views.
 */
const setFilteredCustomRows = ({ arr = [], checkCompareRow = false, filterTags = [] } = {
}) => {
  if (!Array.isArray(arr)) return [];
  if (!Array.isArray(filterTags) || filterTags.length < 0) return arr;

  return arr.filter(view => {
    /**
     * The tags array and isCompared flag of a view.
     * @typedef {Object} ViewInfo
     * @property {Array} [tags] - The tags of the view.
     * @property {boolean} [isCompared=false] - Flag indicating whether the view is compared.
     */
    /** @type {ViewInfo} */
    const { [productHeaders.TAG]: tags, isCompared = false } = view || {
    };
    if (checkCompareRow && isCompared) return true;

    return !!lodash.intersection(tags, filterTags).length;
  });
};
/**
 * Get the next version for a specific row in an array.
 *
 * @param {object} params - Parameters for calculating the next version.
 * @param {Array} params.arr - The array of rows.
 * @param {string} params.rowId - The ID of the row for which to calculate the next version.
 * @returns {string} - The next version for the row.
 */
const getNextRowVersion = ({ arr = [], rowId = null } = {
}) => {
  const DEFAULT_VERSION = 1.1;
  const VERSION_STEP = 0.1;

  // Check for invalid inputs
  if (!rowId || !Array.isArray(arr) || !arr.length) return DEFAULT_VERSION;

  // Find the row by ID
  const { [productHeaders.VERSION]: version } = arr.find(({ id } = {
  }) => id === rowId);

  // Check if the version is a valid number
  if (isNaN(version)) return DEFAULT_VERSION;

  // Calculate the next version
  const nextVersion = Number(version) + VERSION_STEP;
  return nextVersion.toFixed(1);
};

/**
 * Get the next full version for a specific row including session information.
 *
 * @param {object} params - Parameters for calculating the next full version.
 * @param {Array} params.arr - The array of rows.
 * @param {string} params.rowId - The ID of the row for which to calculate the next full version.
 * @param {string} [params.session=productHeaders.DEFAULT_FORCE_UPDATE] - The session information to include in the version.
 * Defaults to the value of productHeaders.DEFAULT_FORCE_UPDATE if not provided.
 * @param {string} params.version - The version to use if provided.
 * @returns {string} - The next full version for the row.
 */
const getNextFullRowVersion = ({ arr = [], rowId = null, session = productHeaders.DEFAULT_FORCE_UPDATE, version } = {
}) => {
  const nextRowVersion = version || getNextRowVersion({
    arr, rowId,
  });
  return `${nextRowVersion}+${session}`;
};
/**
 * Checks if the provided error object contains an incorrect version message.
 *
 * @param {Object} options - Options object.
 * @param {Object} options.err - The error object to check.
 * @param {Array} options.err.errors - An array of error objects.
 * @returns {boolean} True if the error message indicates an incorrect version or a DynamoDB error, false otherwise.
 */
const isIncorrectVersion = ({ err } = {
}) => {
  // Define the incorrect version message as a constant
  const INCORRECT_VERSION_MESSAGE = 'Incorrect version';
  const FAILED_EXCEPTION_MESSAGE = 'errorType=DynamoDB:ConditionalCheckFailedException';
  // Extract the 'errors' property from the 'err' object or assign an empty array as the default
  const { errors = [] } = err || {
  };

  // Extract the first error object from the 'errors' array or assign an empty object as the default
  const [firstError = {
  }] = errors;

  // Extract the 'message' property from the first error object
  const { message } = firstError;
  if (!message) return;
  // Check if the 'message' property equals the incorrect version message or contains the DynamoDB error message
  return message === INCORRECT_VERSION_MESSAGE || message.includes(FAILED_EXCEPTION_MESSAGE);
};
/**
 * Checks if an update is needed for a cell with an incorrect version.
 *
 * @param {object} params - The parameters object.
 * @param {string} params.rowId - The ID of the row.
 * @param {string} params.columnName - The name of the column.
 * @param {object} params.cellsVersions - A mapping of row IDs to column versions.
 * @param {number} params.nextRowVersion - The version to compare against.
 * @returns {boolean} - True if an update is needed, false otherwise.
 */
const isNeedUpdateIncorrectVersion = ({
  rowId,
  columnName,
  cellsVersions,
  nextRowVersion,
}) => {
  const {
    [rowId]: {
      [columnName]: { version: lastCellVersion } = {
      },
    } = {
    },
  } = cellsVersions || {
  };
  if (!lastCellVersion || !nextRowVersion) return true;
  return lastCellVersion < nextRowVersion;
};
/**
 * Checks if an update to a specific cell's version is needed.
 *
 * @param {object} options - Options for checking the update.
 * @param {string} options.rowId - The row identifier.
 * @param {string} options.columnName - The column name.
 * @param {object} options.cellsVersions - The versions of cells.
 * @param {string} options.lastFailedCellVersion - The version of the last failed cell update.
 * @returns {boolean} True if an update to the cell's version is needed, false otherwise.
 */
const isNeedRetryUpdateVersion = ({
  rowId,
  columnName,
  cellsVersions,
  lastFailedCellVersion,
}) => {
  const {
    [rowId]: {
      [columnName]: { tempVersion, version } = {
      },
    } = {
    },
  } = cellsVersions || {
  };

  // Check if the version is not defined or tempVersion is equal to the last failed cell version
  return !version || tempVersion === lastFailedCellVersion;
};

export {
  parseGetRows,
  setFilteredCustomRows,
  getNextFullRowVersion,
  getNextRowVersion,
  isIncorrectVersion,
  isNeedUpdateIncorrectVersion,
  isNeedRetryUpdateVersion,
};