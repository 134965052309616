import { API } from 'aws-amplify';
import {
  ASYNC_SEARCH_RESULT, SEARCH_CONNECTION_3, SEARCH_CONNECTION_TO_VERIFIED_AREA,
} from '../fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
import {
  COLLECTIONS_CRITERIA,
  PRODUCTS_CRITERIA,
  WORKSPACE_PAGES_CRITERIA,
  SEARCH_WORKSPACE_PAGES,
  SEARCH_FOLLOWED_PAGES,
  SEARCH_COMMUNITY_COLLECTIONS_V3,
  SEARCH_COMMUNITY_PRODUCTS_V3,
  SEARCH_COLLECTIONS_V3, SEARCH_PRODUCTS_V3,
} from '@/constants/searching/searchingAsyncV2';
export default {
  // Pages & Community
  asyncCommunityCollectionsWsPages(variables) {
    const query = `
    query asyncCommunityCollectionsWsPages (
        $query: String
        $limit: Int
        $scrollID: String
        $workspaceId: String
        $filters: searchFilters
        $wpFilters: SearchWorkspacePagesFiltersInput
        $wpSort: SearchSortingOptions
        $collectionSort: SearchSortingOptions
        $requestId: String
      ) {
        ${COLLECTIONS_CRITERIA}: ${SEARCH_COMMUNITY_COLLECTIONS_V3}(
          query: $query
          limit: $limit
          scrollID: $scrollID
          workspaceId: $workspaceId
          filters: $filters
          requestId: $requestId
          sort: $collectionSort
        ) {
          ${ASYNC_SEARCH_RESULT}
        }
        ${WORKSPACE_PAGES_CRITERIA}: ${SEARCH_WORKSPACE_PAGES}(
          query: $query
          limit: $limit
          scrollID: $scrollID
          requestId: $requestId
          filters: $wpFilters
          sort: $wpSort
        ) {
          ${ASYNC_SEARCH_RESULT}
        }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  asyncCommunityCollectionsAndProductsWithPageV3(variables) {
    const query = `
    query asyncCommunityCollectionsAndProductsWithPageV3 (
        $query: String
        $limit: Int
        $scrollID: String
        $workspaceId: String
        $filters: searchFilters
        $wpFilters: SearchWorkspacePagesFiltersInput
        $wpSort: SearchSortingOptions
        $collectionSort: SearchSortingOptions
        $requestId: String
        $collectionsFilters: searchFilters
        $manufacturerQuery: String
      ) {
        ${COLLECTIONS_CRITERIA}: ${SEARCH_COMMUNITY_COLLECTIONS_V3}(
          query: $manufacturerQuery
          limit: $limit
          scrollID: $scrollID
          workspaceId: $workspaceId
          filters: $collectionsFilters
          requestId: $requestId
          sort: $collectionSort
        ) {
          ${ASYNC_SEARCH_RESULT}
        }
        ${PRODUCTS_CRITERIA}: ${SEARCH_COMMUNITY_PRODUCTS_V3}(
          query: $query
          limit: $limit
          scrollID: $scrollID
          workspaceId: $workspaceId
          filters: $filters
          requestId: $requestId
        ) {
          ${ASYNC_SEARCH_RESULT}
        }
        ${WORKSPACE_PAGES_CRITERIA}: ${SEARCH_WORKSPACE_PAGES}(
          query: $manufacturerQuery
          limit: $limit
          scrollID: $scrollID
          requestId: $requestId
          filters: $wpFilters
          sort: $wpSort
        ) {
          ${ASYNC_SEARCH_RESULT}
        }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  asyncWorkspaceOnlyCollectionsProductsAndPages(variables) {
    const query = `
    query asyncWorkspaceOnlyCollectionsProductsAndPages (
      $query: String
      $limit: Int
      $scrollID: String
      $workspaceId: String!
      $filters: searchFilters
      $wpFilters: SearchWorkspacePagesFiltersInput
      $wpSort: SearchSortingOptions
      $collectionsFilters: searchFilters
      $requestId: String
      $manufacturerQuery: String
      $libraryId: String
      $followedOnly: Boolean
    ) {
        ${COLLECTIONS_CRITERIA}: ${SEARCH_COLLECTIONS_V3}(
          query: $manufacturerQuery
          limit: $limit
          scrollID: $scrollID
          workspaceId: $workspaceId
          filters: $collectionsFilters
          libraryId: $libraryId
          requestId: $requestId
          followedOnly: $followedOnly
        ) {
          ${ASYNC_SEARCH_RESULT}
        }
        ${PRODUCTS_CRITERIA}: ${SEARCH_PRODUCTS_V3}(
          query: $query
          limit: $limit
          scrollID: $scrollID
          workspaceId: $workspaceId
          filters: $filters
          libraryId: $libraryId
          requestId: $requestId
          followedOnly: $followedOnly
        ) {
          ${ASYNC_SEARCH_RESULT}
        }
        ${WORKSPACE_PAGES_CRITERIA}: ${SEARCH_FOLLOWED_PAGES}(
          workspaceId: $workspaceId
          query: $manufacturerQuery
          limit: $limit
          scrollID: $scrollID
          requestId: $requestId
          filters: $wpFilters
          sort: $wpSort
        ) {
          ${ASYNC_SEARCH_RESULT}
        }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  asyncCollectionsAndProductsV3(variables) {
    const query = `
    query asyncCollectionsAndProductsV3 (
      $query: String
      $manufacturerQuery: String
      $limit: Int
      $scrollID: String
      $workspaceId: String
      $filters: searchFilters
      $collectionsFilters: searchFilters
      $libraryId: String
      $requestId: String
      $myCollections: Boolean
    ) {
        ${COLLECTIONS_CRITERIA}: ${SEARCH_COLLECTIONS_V3}(
          query: $manufacturerQuery
          limit: $limit
          scrollID: $scrollID
          workspaceId: $workspaceId
          filters: $collectionsFilters
          libraryId: $libraryId
          requestId: $requestId
          myCollections: $myCollections
        ) {
          ${ASYNC_SEARCH_RESULT}
        }
        ${PRODUCTS_CRITERIA}: ${SEARCH_PRODUCTS_V3}(
          query: $query
          limit: $limit
          scrollID: $scrollID
          workspaceId: $workspaceId
          filters: $filters
          libraryId: $libraryId
          requestId: $requestId
          myCollections: $myCollections
          ) {
          ${ASYNC_SEARCH_RESULT}
        }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeSearchResultV2(variables) {
    return new requestBuilder({
      requestName: 'subscribeSearchResultV2',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        username: 'String',
        requestId: 'String',
      },
      response: SEARCH_CONNECTION_3,
      variables,
    }).processRequest();
  },
  subscribeSearchResultToVerifiedArea(variables) {
    return new requestBuilder({
      requestName: 'subscribeSearchResultV2',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        username: 'String',
        requestId: 'String',
      },
      response: SEARCH_CONNECTION_TO_VERIFIED_AREA,
      variables,
    }).processRequest();
  },
};
