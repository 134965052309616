import { WORKSPACE_SETTINGS } from '@/services/graphql/fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
export default {
  changeSectionState(variables) {
    return new requestBuilder({
      requestName: 'changeSectionState',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        resourceType: 'ResourceType!',
        workspaceId: 'String!',
        libraryId: 'String',
        sectionId: 'String!',
        expanded: 'Boolean!',
      },
      response: WORKSPACE_SETTINGS,
      variables,
    }).processRequest();
  },
};
