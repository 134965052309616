import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';
export const getters = {
  isVersionMode(state, getters) {
    const { getCurrentVersion: current } = getters;
    return current !== DEFAULT_PROJECT_VERSION;
  },
  getCurrentVersion(state) {
    return state.currentVersion || DEFAULT_PROJECT_VERSION;
  },
  versionTitle(state, getters) {
    const { listOfVersions: list } = state;
    const { getCurrentVersion: current } = getters;
    return list.find(v => v?.id === current)?.title;
  },
  versionsLength(state) {
    return state.listOfVersions.length;
  },
};
