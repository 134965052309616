export const mutations = {
  setDetailDataOfProject(state, data) {
    state.detailProjectData = data;
  },
  setProjectColor(state, payload) {
    state.projectColor = payload;
  },
  setProjectIcon(state, payload) {
    state.projectIcon = payload;
  },
  setWorkspaceLogoToProject(state, payload) {
    state.workspaceLogoToProject = payload;
  },
  setAssociatedDocuments(state, payload) {
    state.associatedDocuments = payload;
  },
  setSidebarComponent(state, payload) {
    state.sidebarComponent = payload;
  },
};
