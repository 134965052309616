import requestBuilder from '@/services/graphql/requestBuilder';
import { ASYNC_SEARCH_RESULT } from '../fragments';
import {
  SEARCH_WORKSPACE_PAGES,
  SEARCH_FOLLOWED_PAGES,
} from '@/constants/searching/searchingAsyncV2';
import { GRAPHQL_TYPES } from '@/constants/cores';
const SearchWorkspacePagesMatch = `
  name
  logo
  workspaceId
  id
  searchText
  isVerified
`;
const SearchWorkspacePagesConnection = `
  data {
    ${SearchWorkspacePagesMatch}
  }
  username
  scrollID
  queryName
  query
  requestId
`;
export default {
  search(initVariables) {
    const { manufacturerQuery: query, wpFilters: filters } = initVariables ?? {
    };
    const variables = {
      ...initVariables,
      manufacturerQuery: undefined,
      ...(query && {
        query,
      }),
      filters,
    };
    return new requestBuilder({
      requestName: SEARCH_WORKSPACE_PAGES,
      requestVariables: {
        query: 'String',
        limit: 'Int',
        scrollID: 'String',
        filters: 'SearchWorkspacePagesFiltersInput',
        sort: 'SearchSortingOptions',
        requestId: 'String',
      },
      response: ASYNC_SEARCH_RESULT,
      variables,
    }).processRequest();
  },
  searchFollowed(initVariables) {
    const { manufacturerQuery: query, wpFilters: filters } = initVariables ?? {
    };
    const variables = {
      ...initVariables,
      manufacturerQuery: undefined,
      ...(query && {
        query,
      }),
      filters,
    };
    return new requestBuilder({
      requestName: SEARCH_FOLLOWED_PAGES,
      requestVariables: {
        workspaceId: 'String!',
        query: 'String',
        limit: 'Int',
        scrollID: 'String',
        filters: 'SearchWorkspacePagesFiltersInput',
        sort: 'SearchSortingOptions',
        requestId: 'String',
      },
      response: ASYNC_SEARCH_RESULT,
      variables,
    }).processRequest();
  },
  subscribeSearchWorkspacePagesResult(variables) {
    return new requestBuilder({
      requestName: 'subscribeSearchWorkspacePagesResult',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        username: 'String',
        requestId: 'String',
      },
      response: SearchWorkspacePagesConnection,
      variables,
    }).processRequest();
  },
};
