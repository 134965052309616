import { getDefaultState } from '@/store/MyPage/state';
export const mutations = {
  setPagesList(state, payload) {
    state.pageList = payload;
  },
  removePageFromPageList(state, id = '') {
    const indexOfPage = state.pageList.findIndex(page => {
      return page.id === id;
    });
    if (indexOfPage >= 0) {
      state.pageList.splice(indexOfPage, 1);
    }
  },
  addPageToPageList(state, page) {
    state.pageList.push(page);
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};
