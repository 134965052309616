export const getters = {
  structuredSearchingResult(state, getters, rootState, rootGetters) {
    const { searchingResult } = state;
    const isListing = rootGetters['ProjectDetailsTableSchedule/isListingView'];
    const structuredArr = searchingResult.reduce((result, { rowId = null, matches = [] }) => {
      if (!rowId || !matches.length) return result;
      if (isListing) return [...result, {
        rowId,
        isListing,
      }];
      const mappingArray = matches.map(item => ({
        rowId,
        isListing,
        ...item,
      }));
      return [...result, ...mappingArray];
    }, []);
    return structuredArr;
  },
  searchingResultLength(state, getters) {
    return getters.structuredSearchingResult?.length;
  },
  getCurrentResultItem(state, getters) {
    const { currentIndexResultItem: currentIndex } = state;
    const { searchingResultLength: leng } = getters;
    if (!leng) return false;
    const { structuredSearchingResult: result } = getters;
    return result?.find((item, index) => index === currentIndex - 1);
  },
  isCellSearchHighlighting: (state, getters) => ({ rowId: currentRowId, columnName } = {
  }) => {
    const { structuredSearchingResult: arr, searchingResultLength: leng } = getters;
    if (!leng) return false;
    return arr?.some(({ rowId, field, isListing }) => rowId === currentRowId && (columnName === field || isListing));
  },
  isSelectedCellSearchHighlighting: (state, getters) => ({ rowId: currentRowId, columnName } = {
  }) => {
    const { getCurrentResultItem: item } = getters;
    if (!item) return false;
    const { rowId, field, isListing } = item;
    return rowId === currentRowId && (field === columnName || isListing);
  },
};
