import { GET_TEXT_REASON } from '@/constants/userPermissions';
export default {
  props: {
    /**
     * @deprecated
     */
    text: {
      type: String,
      default: '',
    },
    colorTooltip: {
      type: String,
      default: '',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    btnClass: {
      type: String,
      default: '',
    },
    /**
     * @deprecated use access obj instead
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    access: {
      type: Object,
      default: null,
    },
    showCustomTooltipTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabled() {
      if (this.access?.allowed == undefined && !this.disabled) {
        return false;
      }
      return this.disabled || !this.access?.allowed;
    },
    reason() {
      return this.access?.reason;
    },
    reasonText() {
      return GET_TEXT_REASON(this.reason);
    },
  },
  methods: {
    mergeAttrs(baseAttrs, additionalAttrs) {
      return {
        ...baseAttrs, ...additionalAttrs,
      };
    },
  },
};
