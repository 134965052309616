// Get sizes from @/constants/thumbnails
import Vue from 'vue';
import {
  dbKey,
  delIDBImage,
} from '@/idb/images';

/**
 * These mutations set the bool flag for VueX and also store the dataUrl in indexedDB.
 * - We want caching, but we can't keep all images esp. schedule in memory.
 */
export const mutations = {
  /**
   * Payload:
   * {
   *    key: <string>
   *    size: [EXTRA_TALL | TALL | MEDIUM | ORIGINAL]
   *    value: <dataUrl> | Undefined/null
   * }
   */
  setImage(state, payload) {
    const { key, size, value } = payload;
    if (!key || !size) {
      console.warn('No key/size passed to setImage mutation.');
      return;
    }
    if (!state.imageCache[key]) {
      Vue.set(state.imageCache, key, {
      });
    }
    const data = state.imageCache[key];
    const cacheKey = dbKey(key, size);
    if (!value) {
      Vue.delete(data, size);
      delIDBImage(dbKey);
    } else {
      Vue.set(data, size, cacheKey);
    }
  },
  /**
   * Payload:
   * {
   *    key: <string>
   *    size: [EXTRA_TALL | TALL | MEDIUM | ORIGINAL]
   *    value: <Promise> | Undefined/null
   * }
   */
  setImageFetching(state, payload) {
    const { key, size, value } = payload;
    if (!key || !size) {
      console.warn('No key/size passed to setImageFetching mutation.');
      return;
    }
    if (!state.fetchPromises[key]) {
      Vue.set(state.fetchPromises, key, {
      });
    }
    const data = state.fetchPromises[key];
    if (!value) {
      Vue.delete(data, size);
    } else {
      Vue.set(data, size, value);
    }
  },
};