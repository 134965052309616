
export const getDefaultState = () => {
  return {
    viewInfo: [],
    listDeletedRows: [],
    checkboxSelectedRows: [],
    cellsVersions: {
    },
  };
};
export const state = getDefaultState();
