import SortRows from '@/utils/sorting/SortRows';
import { SORT_TOP } from '@/constants/scheduleViews';
import { isString } from 'lodash';
export default class SortArrayRows extends SortRows {
  constructor({ sortOrder = SORT_TOP, sortBy, items }) {
    super({
      sortBy,
      sortOrder,
      items,
    });
  }
  sort() {
    const { items, sortBy } = this.getSortingParams();
    return items.sort((a, b) => {
      let [first, next] = [a[sortBy] || [''], b[sortBy] || ['']];
      if (Array.isArray(first)) {
        first = first[0] || '';
      }
      if (Array.isArray(next)) {
        next = next[0] || '';
      }
      if (this.useLowerCaseSort) {
        if (isString(first)) {
          first = first.toLowerCase();
        }
        if (isString(next)) {
          next = next.toLowerCase();
        }
      }
      if (first == next) {
        return 0;
      }
      if (this.isSortBottom()) {
        return first < next ? 1 : -1;
      }
      return first > next ? 1 : -1;
    });
  }
}
