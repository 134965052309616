const METER = 'meter';
const FEET = 'feet';
const FEET_TITLE = 'ft²';
const METER_TITLE = 'm²';
const AREA_UNITS_MAPPING = [
  {
    value: FEET, title: FEET_TITLE,
  },
  {
    value: METER, title: METER_TITLE,
  },
];
export { AREA_UNITS_MAPPING, FEET_TITLE };
