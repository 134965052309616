import { mapMutations } from 'vuex';
export default {
  methods: {
    ...mapMutations(['openSnackBar', 'setNetworkError']),
  },
  mounted() {
    window.addEventListener('offline', () => {
      this.openSnackBar({
        color: 'red',
        text: 'Internet connection is slow or interrupted',
        timeout: -1,
      });
      this.setNetworkError(true);
    });
    window.addEventListener('online', () => {
      this.setNetworkError(false);
      this.openSnackBar({
        color: 'green',
        title: 'Network connectivity has been recovered, please <a href="">REFRESH</a>.',
        text: 'If a process was not completed, data may be lost. Please check your work.',
        timeout: -1,
      });
    });
  },
};
