import { API } from 'aws-amplify';
import {
  ROW_COMMENT,
  SLANT,
  COMMENT_CELL_POINTER,
  PROJECT_PERMISSION,
  WORKSPACE_PERMISSION,
  PERMISSION_NOTIFICATION,
  FILE_STRUCTURE,
  AGGREGATION,
  TOTAL_TAG,
  WORKSPACE_SETTINGS,
  COMMENT,
  THUMBNAIL,
  SLANT_V2,
  RESOURCE_PERMISSION,
  COLLECTION_NO_BRACKETS, WORKSPACE_PAGE,
} from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
const PAYMENT_EVENT_SUCCESS = `
  {
    userId
    action
  }
`;
const PAYMENT_EVENT = `
  {
    userId
    action
    type
    msg

  }
`;
const USER_INFO = `
  {
    name
    email
    id
    picture
    projectTeamRole
  }
`;
const COLLABORATORS = `
  usersData ${USER_INFO}
  projectId
  rowId
`;
export default {
  subscribeRecalculateAggregation(variables) {
    const query = `
    subscription subscribeRecalculateAggregation (
        $projectId: String!
      ) {
        response: subscribeRecalculateAggregation(
          projectId: $projectId
        ) ${AGGREGATION}
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribePaymentEventSuccess(variables) {
    const query = `
    subscription subscribePaymentEventSuccess (
        $userId: String!
      ) {
        response: subscribePaymentEventSuccess(
          userId: $userId
        ) ${PAYMENT_EVENT_SUCCESS}
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribePaymentEvent(variables) {
    const query = `
    subscription subscribePaymentEvent (
        $userId: String!
      ) {
        response: subscribePaymentEvent(
          userId: $userId
        ) ${PAYMENT_EVENT}
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribePutRow(variables) {
    const query = new requestBuilder({
      requestName: 'subscribePutRow',
      requestType: 'subscription',
      requestVariables: {
        tableId: 'String!',
        tableType: 'TableType!',
      },
      response: `fields {
        name
        value
      }`,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeDeleteRow(variables) {
    const query = new requestBuilder({
      requestName: 'subscribeDeleteRow',
      requestType: 'subscription',
      requestVariables: {
        tableId: 'String!',
        tableType: 'TableType!',
      },
      response: `fields {
        name
        value
      }`,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeRowComment(variables, criteria) {
    const isNeedRecipientId = ['subscribeCreateRowCommentPrivate', 'subscribePutCommentThumbnailPrivate']
      .includes(criteria);
    const checkPublicSub = criteria.includes('Public');
    if (checkPublicSub) {
      variables.privacy = 'public';
    }
    const query = `
    subscription subscribeRowComment (
      $projectId: String!
      $rowId: String!
      ${checkPublicSub ? '$privacy: Privacy!' : ''}
      ${isNeedRecipientId ? '$recipientId: String!' : ''}
      ) {
        response: ${criteria}(
          projectId: $projectId
          rowId: $rowId
          ${checkPublicSub ? 'privacy: $privacy' : ''}
          ${isNeedRecipientId ? 'recipientId: $recipientId' : ''}
          ) ${ROW_COMMENT}
        }
        `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeProjectComment(variables, criteria) {
    const checkPublicSub = criteria.includes('Public');
    const isNeedRecipientId = criteria === 'subscribeCreateProjectCommentPrivate';
    if (checkPublicSub) {
      variables.privacy = 'public';
    }
    const query = `
    subscription subscribeProjectComment (
      $projectId: String!
      $PK: String!
      ${checkPublicSub ? '$privacy: Privacy!' : ''}
      ${isNeedRecipientId ? '$recipientId: String!' : ''}
      ) {
        response: ${criteria}(
          projectId: $projectId
          PK: $PK
          ${checkPublicSub ? 'privacy: $privacy' : ''}
          ${isNeedRecipientId ? 'recipientId: $recipientId' : ''}
          ) ${ROW_COMMENT}
        }
        `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeCollaboratorChange(variables) {
    const query = `
    subscription subscribeCollaboratorChange (
        $projectId: String!
        $rowId: String!
      ) {
        response: subscribeCollaboratorChange(
          projectId: $projectId
          rowId: $rowId
        ) {
          ${COLLABORATORS}
        }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeRowFollowerChange(variables) {
    return new requestBuilder({
      requestName: 'subscribeRowFollowerChange',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        projectId: 'String!',
        rowId: 'String!',
      },
      response: COLLABORATORS,
      variables,
    }).processRequest();
  },
  subscribeUnlockProject(variables) {
    const query = `
    subscription subscribeUnlockProject (
        $projectId: String!,
      ) {
        response: subscribeUnlockProject(
          projectId: $projectId
        ) {
          id
          PK
          SK
          projectId
        }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeRecalculateTagTotalCost(variables) {
    const query = `
    subscription subscribeRecalculateTagTotalCost(
        $PK: String,
        $SK: String,
      ) {
        response: subscribeRecalculateTagTotalCost(
          PK: $PK,
          SK: $SK,
        ) {
          ${TOTAL_TAG}
        }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeCommentsAmount(variables) {
    const query = `
    subscription subscribeCommentsAmount(
        $projectId: String!
        $PK: String!
      ) {
        response: subscribeCommentsAmount(
          projectId: $projectId,
          PK: $PK
        ) ${SLANT}
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeSetSlant(variables) {
    const query = `
    subscription subscribeSetSlant(
        $projectId: String!
        $PK: String!
      ) {
        response: subscribeSetSlant(
          projectId: $projectId,
          PK: $PK
        ) ${SLANT}
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeCellCommentAmountChanging(variables) {
    const query = `
    subscription subscribeCellCommentAmountChanging(
        $tableId: String!
        $tableType: TableType!
        $username: String!
      ) {
        response: subscribeCellCommentAmountChanging(
          tableId: $tableId,
          tableType: $tableType
          username: $username
        ) ${COMMENT_CELL_POINTER}
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeTotalUnreadComments(variables) {
    const query = `
    subscription subscribeTotalUnreadComments (
        $PK: String!,
      ) {
        response: subscribeTotalUnreadComments(
          PK: $PK,
        ) {
        ${PROJECT_PERMISSION}
        }
    }`;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeCollectionPermissionChange(variables) {
    return new requestBuilder({
      requestName: 'subscribePermissionChange',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        username: 'String!',
      },
      response: `id`,
      variables,
    }).processRequest();
  },
  subscribePermissionProjectChange(variables) {
    return new requestBuilder({
      requestName: 'subscribePermissionProjectChange',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        username: 'String!',
      },
      response: PROJECT_PERMISSION,
      variables,
    }).processRequest();
  },
  subscribePermissionWorkspaceChange(variables) {
    return new requestBuilder({
      requestName: 'subscribePermissionWorkspaceChange',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        username: 'String!',
      },
      response: WORKSPACE_PERMISSION,
      variables,
    }).processRequest();
  },
  subscribeWorkspacePageChange(variables) {
    return new requestBuilder({
      requestName: 'onWorkspacePageChange',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        workspaceId: 'String',
        id: 'String',
      },
      response: WORKSPACE_PAGE,
      useRemovingOfVariables: true,
      variables,
    }).processRequest();
  },
  subscribeNotifyCreatePermission(variables) {
    return new requestBuilder({
      requestName: 'subscribeNotifyCreatePermission',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        resource: 'String!',
        resourceId: 'String!',
      },
      response: PERMISSION_NOTIFICATION,
      variables,
    }).processRequest();
  },
  subscribeThumbnailsUpdate(variables) {
    const query = new requestBuilder({
      requestName: 'subscribeThumbnailsUpdate',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        id: 'String!',
      },
      response: FILE_STRUCTURE,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeUserWorkspaceSettings(variables) {
    const query = new requestBuilder({
      requestName: 'subscribeUserWorkspaceSettings',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        userId: 'String!',
        resourceType: 'ResourceType!',
        libraryId: 'String',
        workspaceId: 'String!',
      },
      response: WORKSPACE_SETTINGS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeComments(variables) {
    return new requestBuilder({
      requestName: 'subscribeComment',
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
        commentKind: 'CommentKind!',
      },
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      response: COMMENT,
      variables,
    }).processRequest();
  },
  subscribeCommentAttachment(variables) {
    return new requestBuilder({
      requestName: 'subscribeCommentAttachment',
      requestVariables: {
        commentId: 'String!',
        attachmentId: 'String!',
      },
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      response: `
        commentId
        attachmentId
        filename
        id
        key
        declaredAt
        thumbnails {
          ${THUMBNAIL}
        }
      `,
      variables,
    }).processRequest();
  },
  subscribeSlantDocument(variables) {
    return new requestBuilder({
      requestName: 'subscribeSlantDocument',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
      },
      response: SLANT_V2,
      variables,
    }).processRequest();
  },
  subscribeSlantDocumentsList(variables) {
    return new requestBuilder({
      requestName: 'subscribeSlantDocumentsList',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
      },
      response: `
        workspaceId
        resourceType
        resourceId
        slants {
          ${SLANT_V2}
        }
      `,
      variables,
    }).processRequest();
  },
  subscribeTotalUnreadCounter(variables) {
    return new requestBuilder({
      requestName: 'subscribeTotalUnreadCounter',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        libraryId: 'String',
      },
      response: RESOURCE_PERMISSION,
      variables,
    }).processRequest();
  },
  subscribeCollectionsAccess(variables) {
    return new requestBuilder({
      requestName: 'subscribeCollectionsAccess',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        workspaceId: 'String!',
      },
      response: COLLECTION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  onSortingDocumentChange(variables) {
    return new requestBuilder({
      requestName: 'onSortingDocumentChange',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        PK: 'String!',
        sourceSK: 'String',
      },
      response: `PK`,
      variables,
    }).processRequest();
  },
};
