export const getDefaultState = () => {
  return {
    userData: null,
    ownLogo: null,
    userAvatar: null,
    userAvatarKey: null,
    credentials: {
    },
    isSignUp: false,
  };
};
export const state = getDefaultState();
