import { getUnreadCommentsBySlants } from '@/utils/comments';
export const getters = {
  slant: (state) => (id) => {
    const { slants } = state;
    return slants.find(slant => slant.rowId === id);
  },
  slantV2: (state) => (id, currentResource) => {
    const slants = state[currentResource.slantsList];
    return slants.find(slant => slant.rowId === id);
  },
  getCommentsUnread: (state, getters) => id => {
    return getters.slant(id)?.commentsUnread;
  },
  totalUnreadCommentsLength(state) {
    const { slants } = state;
    return getUnreadCommentsBySlants(slants);
  },
  totalProjectsUnreadCommentsLength(state) {
    const { projectSlants } = state;
    return getUnreadCommentsBySlants(projectSlants || []);
  },
  totalCollectionsUnreadCommentsLength(state) {
    const { collectionSlants } = state;
    return getUnreadCommentsBySlants(collectionSlants || []);
  },
};
