import { IS_STORE_ROOT } from '@/constants';

import LibrariesApi from '@/services/graphql/libraries';
import router from '@/router';
export const actions = {
  async getLibrariesList({ commit, rootState, dispatch, rootGetters }, payload) {
    commit('setIsFetchingLibraries', true);
    const { nextToken = null, currentLibraries = [] } = payload || {
    };
    const mutationName = rootGetters['FeatureFlags/useSkeleton'] ? 'setIsLoadingRowData' : 'spinner';
    const skipSpinner = rootGetters['FeatureFlags/useLazyLoading'] && mutationName === 'spinner';
    try {
      if (!skipSpinner) commit(mutationName, true, IS_STORE_ROOT);
      const { data } = await LibrariesApi.listLibraries({
        workspaceId: rootState.Workspace.activeWorkspaceId,
        nextToken,
      });
      const { data: libraries = [], nextToken: nextTokenServer = '' } = data.response || {
      };
      const arrLibraries = [...currentLibraries, ...libraries];
      commit('setLibrariesList', arrLibraries);
      if (nextTokenServer) dispatch('getLibrariesList', {
        nextToken: nextTokenServer,
        currentLibraries: arrLibraries,
      });
    } catch (err) {
      console.log('err get libraries list', err);
    } finally {
      if (!skipSpinner) commit(mutationName, false, IS_STORE_ROOT);
      commit('setIsFetchingLibraries', false);
    }
  },
  updateLibraryName({ commit, state }, item) {
    const { librariesList } = state;
    let newLibrariesList = librariesList.map(el =>
      el.id === item.id ? {
        ...el,
        name: item.name,
        description: item.description,
        logo: item.logo,
      } : el
    );
    commit('setLibrariesList', newLibrariesList);
  },
  setNewLibrary({ state, commit }, payload) {
    commit('setLibrariesList', [
      ...state.librariesList,
      {
        ...payload,
        kind: 'custom',
      },
    ]);
  },
  async manageSharedUser({ commit, state, dispatch, rootState, rootGetters }, { variables, criteria }) {
    const mappingOfShareUser = {
      createPermission: 'shareLibrary',
      updatePermission: 'updateLibraryPermission',
      deletePermission: 'deleteLibraryPermission',
      inviteUsers: 'inviteUserToLibrary',
    };
    try {
      commit('spinner', true, {
        root: true,
      });
      const { librariesList } = state;
      const { activeHeader } = rootState;
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await LibrariesApi[mappingOfShareUser[criteria]]({
        ...variables,
        workspaceId,
      });
      const library = data.response;
      dispatch('Workspace/manageInvitedUsersForShareWorkspace', {
        variables,
        members: library.members,
        criteria,
      }, {
        root: true,
      });
      if (criteria == 'deletePermission' && variables.targetUserId == rootGetters.userId) {
        commit('setLibrariesList', librariesList.filter(item => library.id !== item.id));
      } else {
        commit('setLibrariesList', [
          ...librariesList.map(item => (
            library.id === item.id ? {
              ...item,
              members: library.members,
            } : item
          )),
        ]);
      }
      commit('updateActiveElement', {
        ...activeHeader,
        members: library.members,
      }, IS_STORE_ROOT);
      const route = router.currentRoute;
      if (route.name == 'collections' && criteria == 'deletePermission' && variables.targetUserId == rootGetters.userId) {
        router.push({
          name: 'libraries',
        });
      }
    } catch (err) {
      dispatch('handleError', err, {
        root: true,
      });
    } finally {
      commit('spinner', false, {
        root: true,
      });
    }
  },
  newLibraryList({ state, commit }, payload) {
    const newList = state.librariesList.filter(el => el.id !== payload);
    commit('setLibrariesList', newList);
  },
};
