import getTypeByHeaderName from '@/utils/getTypeByHeaderName';
import { DEFAULT_VIEW_ID } from '@/constants';
/**
 * Sets the collection schema based on the selected collection view and schema mapping.
 * @param {Object} options - The options object.
 * @param {Object} options.selectedCollectionView - The selected collection view.
 * @param {Object} options.collectionsSchemaMapping - The schema mapping for collections.
 * @param {Object} options.collectionsSchema - The collections schema.
 * @returns {Object} - The updated collections schema.
 */
const setCollectionSchema = ({
  selectedCollectionView,
  collectionsSchemaMapping,
  collectionsSchema,
} = {
}) => {
  const { schema, allowedFields } = collectionsSchema || {
  };
  const { columnOrder } = selectedCollectionView || {
  };
  if (!schema || !columnOrder) return {
    ...collectionsSchema,
    schema: [],
  };
  const mappedSchema = columnOrder.reduce((result, option) => {
    const fieldInSchema = collectionsSchemaMapping[option];
    const allowField = (!allowedFields || allowedFields.includes(fieldInSchema?.id))
      && fieldInSchema?.name !== 'Product Reference';
    if (allowField && fieldInSchema) {
      const { hint, name, id } = fieldInSchema || {
      };
      return [...result, {
        align: 'left',
        column: {
          type: getTypeByHeaderName({
            headerObj: fieldInSchema,
            selectedView: selectedCollectionView,
          }),
          hint,
        },
        text: name,
        value: name,
        width: 90,
        id,
      }];
    }
    return result;
  }, []);
  return {
    ...collectionsSchema,
    schema: mappedSchema,
  };
};
/**
 * Maps the collection schema to an object with field names as keys.
 * @param {Object[]} schema - The schema array to be mapped.
 * @returns {Object} - The mapped schema object with field names as keys.
 */
const setCollectionSchemaMapping = schema => {
  return schema.reduce((acc = {
  }, s) => ({
    ...acc,
    [s?.name]: s,
  }), {
  });
};
/**
 * Sets the selected collection view based on the views array.
 * @param {Object[]} views - The array of collection views.
 * @returns {Object|null} - The selected collection view or null if views is not an array.
 */
const setSelectedCollectionView = views => {
  if (!Array.isArray(views)) return null;
  return views.find(({ id }) => id === DEFAULT_VIEW_ID) || views[0];
};
/**
 * Sets mapped dropdowns of a view.
 *
 * @param {Array} dropdowns - An array of dropdown objects.
 * @returns {Object} - An object with dropdown names as keys and dropdown objects as values.
 */
const setMappedDropdownsOfView = (dropdowns) => {
  if (!dropdowns || !dropdowns.length) return {
  };
  return dropdowns.reduce((acc, { name, ...rest } = {
  }) => {
    if (!name) {
      return acc;
    }
    return {
      ...acc,
      [name]: {
        name, ...rest,
      },
    };
  }, {
  });
};
export {
  setCollectionSchema,
  setCollectionSchemaMapping,
  setSelectedCollectionView,
  setMappedDropdownsOfView,
};