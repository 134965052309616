let Sentry;

async function getSentry() {
  if (!Sentry) Sentry = await import('@sentry/vue');
  return Sentry;
}

function captureException(errorText) {
  const sentry = getSentry();
  sentry.captureException(errorText);
}

export {
  getSentry,
  captureException,
};