const USD = 'usd';
const CAD = 'cad';
const DEFAULT = USD;
const ACCEPTED_CURRENCIES = [ USD, CAD ];
const DEFAULT_NEXT = 'payment';
const ROUTER_NAVIGATION_CANCELLED = 'Please provide router';
const WRONG_CURRENCY = 'Wrong Currency provided';
const MODEL_SETTINGS = {
  state: 'Payment.currency',
  mutation: 'Payment/setCurrency',
};
export default {
  USD,
  CAD,
  DEFAULT,
  ACCEPTED_CURRENCIES,
  DEFAULT_NEXT,
  ROUTER_NAVIGATION_CANCELLED,
  WRONG_CURRENCY,
  MODEL_SETTINGS,
};
