/**
 * @param variables
 * @param next
 * @param callbackToExecuteAfter - you can accept value of subscription in your callback
 * @param unsubscribe
 */
const baseSubscription = ({
  variables, subscriptionFunction, next = () => {
  }, unsubscribe = true,
}) => {
  const subscription = subscriptionFunction(variables).subscribe({
    next: (({ value }) => {
      next(value);
      if (unsubscribe) {
        subscription.unsubscribe();
      }
    }),
  });
  return subscription; // You can do anything with your subscription if you want
};
export { baseSubscription };
