import { API } from 'aws-amplify';
import {
  AGGREGATION, TOTAL_TAG,
} from './fragments';
export default {
  getProjectAggregation(variables) {
    const query = `
      query getProjectAggregation (
        $projectId: String!
        $workspaceId: String
      ) {
        response: getProjectAggregation(
          projectId: $projectId
          workspaceId: $workspaceId
        ) ${AGGREGATION}
      }
    `;
    return API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    });
  },
  updateProjectAggregation(variables) {
    const query = `
      mutation updateProjectAggregation (
        $id: String!
        $location: String
        $powerDensity: Float
        $baseBudget: Int!
        $area: Float
        $areaUnit: AreaUnits
        $phase: String
        $number: String
        $workspaceId: String
        $address: AddressInput
      ) {
        response: updateProjectAggregation(
          id: $id
          location: $location
          powerDensity: $powerDensity
          baseBudget: $baseBudget
          area: $area
          areaUnit: $areaUnit
          phase: $phase
          number: $number
          workspaceId: $workspaceId
          address: $address
        ) {
            id
            baseBudget
            location
            address {
              formatted_address
            }
            powerDensity
            phase
            number
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  updateProjectAggregationNotes(variables) {
    const query = `
      mutation updateProjectAggregationNotes (
        $projectId: String!
        $aggregationId: String!
        $notes: String!
        $workspaceId: String
      ) {
        response: updateProjectAggregationNotes(
          projectId: $projectId
          aggregationId: $aggregationId
          notes: $notes
          workspaceId: $workspaceId
        ) {
            notes
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getTotalOfTag(variables) {
    const query = `
      query getTotalOfTag (
        $tableId: String!,
        $tableType: TableType!,
        $tagName: String,
      ) {
        response: getTotalCostOfTag(
          tableId: $tableId,
          tableType: $tableType,
          tagName: $tagName,
        ) {
          ${TOTAL_TAG}
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  recalculateProjectAggregation(variables) {
    const query = `
      mutation recalculateProjectAggregation(
        $projectId: String!
        $scheduleId: String!
      ) {
        response: recalculateProjectAggregation(
          projectId: $projectId
          scheduleId: $scheduleId
        ) ${AGGREGATION}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
};
