import { API } from 'aws-amplify';
import {
  ROW_DATA, DROPDOWN_VALUES, LIST_COLLECTIONS_FOLLOWERS_PRODUCTS,
} from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
const COLLECTION_UNIVERSAL_ROW = `
  ${ROW_DATA}
  collectionId
  productId
  commentsAmount
`;
export default {
  createProduct(variables) {
    return new requestBuilder({
      requestName: 'createProduct',
      requestType: 'mutation',
      requestVariables: {
        collectionId: 'String',
        product: '[FieldInput]!',
        fromProject: 'Boolean',
        workspaceId: 'String',
      },
      response: ROW_DATA,
      variables,
    }).processRequest();
  },
  updateProduct(variables) {
    const query = `
    mutation updateProduct(
      $collectionId: String,
      $product: [FieldInput]!
      $productId: String!
      $workspaceId: String
    ) {
      updateProduct(
        product:$product
        collectionId:$collectionId
        productId:$productId
        workspaceId: $workspaceId
      ) {
          ${ROW_DATA}
        }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  /**
   * 'returnCopyToCollectionId' is needed if you copy a product to a different collection, and
   *  you WANT the product to be returned.
   *
   *  Example:
   *  - Copy a product from Collection A to Collection B.
   *  - You need to specify Collection B's id in 'returnCopyToCollectionId', so it can
   *    fetch the copied product (with B's schema) from Collection B and return it.
   */
  copyProduct(variables) {
    return new requestBuilder({
      requestName: 'copyProduct',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionIds: '[String]!',
        copyFromCollectionId: 'String!',
        copyProductId: 'String!',
        workspaceId: 'String',
        returnCopyToCollectionId: 'String',
      },
      response: ROW_DATA,
      variables,
    }).processRequest();
  },
  editFollowedProduct(variables) {
    const query = `
    mutation editFollowedProduct(
      $collectionId: String!,
      $productId: String!
      $workspaceId: String
    ) {
      editFollowedProduct(
        collectionId: $collectionId
        productId: $productId
        workspaceId: $workspaceId
      ) {
        ${ROW_DATA}
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  deleteProduct(variables) {
    const query = `
    mutation deleteProduct(
      $collectionId: String!
      $productIds: [String]!
      $workspaceId: String
    ) {
      deleteProduct(
        collectionId: $collectionId
        productIds: $productIds
        workspaceId: $workspaceId
      ) {
        ${ROW_DATA}
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  revertFollowedProduct(variables) {
    const query = `
    mutation revertFollowedProduct(
      $collectionId: String!,
      $productId: String!
      $workspaceId: String
    ) {
      revertFollowedProduct(
        collectionId: $collectionId
        productId: $productId
        workspaceId: $workspaceId
      ) {
        ${ROW_DATA}
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  followProduct(variables) {
    const query = `
      mutation followProduct (
        $collectionIds: [String]!
        $followedCollectionId: String!
        $followedProductIds: [String]!
        $workspaceId: String
        $accessToken: String
      ) {
        response: followProduct(
          collectionIds: $collectionIds
          followedCollectionId: $followedCollectionId
          followedProductIds: $followedProductIds
          workspaceId: $workspaceId
          accessToken: $accessToken
        )
          {
            PK
            SK
            id
          }
      }`;
    return API.graphql({
      query,
      variables,
    });
  },
  getCollectionDropdownValues(variables) {
    const query = `
      query getCollectionDropdownValues (
        $colId: String!
        $collectionId: String
      ) {
        response: getCollectionDropdownValues(
          colId: $colId
          collectionId: $collectionId
          ) ${DROPDOWN_VALUES}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  listCollectionsFollowersForProduct(variables) {
    const query = new requestBuilder({
      requestName: 'listCollectionsFollowersForProduct',
      requestType: GRAPHQL_TYPES.QUERY,
      response: LIST_COLLECTIONS_FOLLOWERS_PRODUCTS,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String!',
        productId: 'String!',
        limit: 'Int',
        nextToken: 'String',
      },
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeOnProductUpdate(variables) {
    return new requestBuilder({
      requestName: 'subscribeOnProductUpdate',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        collectionId: 'String!',
        ...(variables.productId && {
          productId: 'String',
        }),
      },
      response: COLLECTION_UNIVERSAL_ROW,
      variables,
    }).processRequest();
  },
  getCollectionProduct({
    collectionId,
    productId,
    workspaceId,
  }) {
    return new requestBuilder({
      requestName: 'getCollectionProduct',
      requestVariables: {
        tableId: 'String!',
        rowId: 'String!',
        workspaceId: 'String!',
      },
      response: ROW_DATA,
      variables: {
        tableId: collectionId,
        rowId: productId,
        workspaceId,
      },
    }).processRequest();
  },
};
