
export const getDefaultState = () => {
  return {
    compareVersion: '',
    compareReference: '',
    compareColumns: [],
    comparingColumns: [],
    compareListWithVersions: [],
    modifiedRow: null,
  };
};
export const state = getDefaultState();
